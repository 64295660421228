@charset "UTF-8";
@font-face {
  font-family: 'vigilant-icons';
  src: url("styles/icon-styles/font/vigilant-icons.eot?90151618");
  src: url("styles/icon-styles/font/vigilant-icons.eot?90151618#iefix") format("embedded-opentype"), url("styles/icon-styles/font/vigilant-icons.woff2?90151618") format("woff2"), url("styles/icon-styles/font/vigilant-icons.woff?90151618") format("woff"), url("styles/icon-styles/font/vigilant-icons.ttf?90151618") format("truetype"), url("styles/icon-styles/font/vigilant-icons.svg?90151618#vigilant-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'vigilant-icons';
    src: url('../font/vigilant-icons.svg?84502734#vigilant-icons') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "vigilant-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-note_attachment:before {
  content: '\e800'; }

/* '' */
.icon-lightbulb-rays:before {
  content: '\e801'; }

/* '' */
.icon-add-person:before {
  content: '\e802'; }

/* '' */
.icon-caret-up:before {
  content: '\e803'; }

/* '' */
.icon-caret-down:before {
  content: '\e804'; }

/* '' */
.icon-info:before {
  content: '\e805'; }

/* '' */
.icon-info-one:before {
  content: '\e806'; }

/* '' */
.icon-bolt:before {
  content: '\e808'; }

/* '' */
.icon-checked3:before {
  content: '\e80b'; }

/* '' */
.icon-unchecked3:before {
  content: '\e80c'; }

/* '' */
.icon-intermediate3:before {
  content: '\e80d'; }

/* '' */
.icon-pencil:before {
  content: '\e80e'; }

/* '' */
.icon-trash:before {
  content: '\e80f'; }

/* '' */
.icon-none:before {
  content: '\e810'; }

/* '' */
.icon-plus:before {
  content: '\e810'; }

/* '' */
.icon-add-company:before {
  content: '\e811'; }

/* '' */
.icon-filter:before {
  content: '\e812'; }

/* '' */
.icon-add-attachment:before {
  content: '\e809'; }

/* '' */
.icon-note_add:before {
  content: '\e820';
  line-height: 1.5em; }

/* '' */
.icon-note_text:before {
  content: '\e821';
  line-height: 1.5em; }

/* '' */
.icon-business-regs:before {
  content: '\e819'; }

/* '' */
.icon-expenditures-alt:before {
  content: '\e81a'; }

/* '' */
.icon-political-contributions-alt:before {
  content: '\e81b'; }

/* '' */
.icon-lobbying:before {
  content: '\e81d'; }

/* '' */
.icon-political-contributions:before {
  content: '\e81e'; }

/* '' */
.icon-expenditures:before {
  content: '\e81f'; }

/* '' */
.icon-arrow_up:before {
  content: '\e819';
  margin: -2px; }

/* '' */
.icon-arrow_down:before {
  content: '\e81a';
  margin: -2px; }

/* '' */
.icon-az:before {
  content: '\e81b';
  margin: 0px -4px; }

/* '' */
.icon-send:before {
  content: '\e816'; }

/* '' */
.icon-none:before {
  content: '\e810'; }

/* '' */
.modal-container {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background: rgba(0, 0, 0, 0.27);
  padding: 50px; }
  .modal-container.center {
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .modal-container.small .modal-box {
    width: 60%;
    max-width: 700px; }
  .modal-container .error-text, .modal-container .formPage form .errorlist, .formPage form .modal-container .errorlist {
    font-size: 12px; }

#delete-modal {
  width: 60%;
  max-width: 700px; }

.modal-box {
  width: 88%;
  overflow-x: hidden;
  background-color: #FAFBFB;
  font-size: 13px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  position: relative; }
  .modal-box.center {
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center; }
  .modal-box h2 {
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 24px;
    background-color: #F3F4F7;
    padding: 24px 28px 16px; }
  .modal-box .message {
    padding: 0px 28px;
    font-size: 16px;
    line-height: 1.5; }
  .modal-box .button-wrapper, .modal-box .modal-button-wrapper {
    text-align: right;
    padding: 32px 28px; }
    .modal-box .button-wrapper .orange-button, .modal-box .modal-button-wrapper .orange-button {
      display: inline-block; }
    .modal-box .button-wrapper .text-button:not(.add-field-button), .modal-box .modal-button-wrapper .text-button:not(.add-field-button) {
      color: #878787;
      margin-right: 24px;
      font-size: 13px;
      border-bottom: 1px solid transparent; }
      .modal-box .button-wrapper .text-button:not(.add-field-button):hover:not(:disabled), .modal-box .modal-button-wrapper .text-button:not(.add-field-button):hover:not(:disabled) {
        color: #505050;
        border-color: #505050; }
  .modal-box .modal-close {
    position: absolute;
    color: #BDBDBD;
    top: 16px;
    right: 16px;
    z-index: 10;
    border-bottom: none; }
    .modal-box .modal-close .material-icons {
      font-size: 20px; }
    .modal-box .modal-close:hover {
      color: #505050; }
    .modal-box .modal-close:focus {
      border-bottom: none;
      color: #505050; }

.full-screen-modal {
  padding: 0px;
  z-index: 110; }
  .full-screen-modal .modal-box {
    height: 100%; }

.login-prompt.modal-container {
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: -webkit-calc(100vh + 40px);
  height: -moz-calc(100vh + 40px);
  height: calc(100vh + 40px);
  position: fixed; }
  .login-prompt.modal-container .modal-box {
    width: 600px;
    height: 250px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    font-size: 16px; }
  .login-prompt.modal-container .modal-header {
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 24px;
    background-color: #F3F4F7;
    padding: 24px 28px 16px;
    color: #676767; }
  .login-prompt.modal-container .modal-message {
    padding: 24px 28px 16px; }
  .login-prompt.modal-container .button-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding: 32px 38px;
    padding-bottom: 0px; }
  .login-prompt.modal-container .orange-button {
    margin: 0px; }

@media (max-width: 1025px) {
  .modal-box {
    font-size: 14px; }
    .modal-box .button-wrapper .text-button {
      font-size: 12px; }
    .modal-box .orange-button {
      font-size: 12px !important; } }

#allSourcesCheckbox {
  display: block;
  color: #f96c5f;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  font-size: 13px;
  margin-top: 30px;
  margin-bottom: 8px;
  line-height: 1; }
  #allSourcesCheckbox .checkbox input[type=checkbox] + label:before {
    color: #f96c5f; }
  #allSourcesCheckbox span {
    margin-right: 10px; }

.checkbox {
  display: inline; }
  .checkbox input[type=checkbox] {
    opacity: 0 !important;
    position: absolute; }
  .checkbox input[type=checkbox]:focus + label:before {
    color: #505050; }
  .checkbox input[type=checkbox] + label {
    cursor: pointer;
    position: relative;
    left: 25px;
    margin-right: 25px;
    margin-bottom: 4px;
    margin-top: 4px;
    display: inline-block;
    line-height: 20px; }
  .checkbox input[type=checkbox] + label:before {
    content: '\e80c';
    color: #676767;
    display: inline-block;
    position: absolute;
    top: 1px;
    left: -23px;
    font-family: "vigilant-icons";
    -webkit-text-stroke-width: 1px;
    font-weight: normal;
    font-size: 13px; }
  .checkbox input[type=checkbox]:checked + label:before {
    content: '\e80b'; }
  .checkbox input[type=checkbox]:indeterminate + label:before {
    content: '\e80d'; }

.date-wrapper {
  text-align: center; }
  .date-wrapper .filter-dropdown {
    margin-top: 35px; }

.new-button-container {
  position: fixed;
  width: 280px;
  background: #F9FAFC;
  text-align: center;
  font-size: 12px; }
  .new-button-container .tooltip {
    top: -webkit-calc(-100% - 20px);
    top: -moz-calc(-100% - 20px);
    top: calc(-100% - 20px);
    left: 0px;
    width: 280px;
    min-width: auto;
    -webkit-transform: none;
       -moz-transform: none;
        -ms-transform: none;
         -o-transform: none;
            transform: none;
    text-align: left;
    z-index: 10; }
    .new-button-container .tooltip a {
      color: #f96c5f; }
      .new-button-container .tooltip a:hover {
        color: #f85142; }
    .new-button-container .tooltip:hover {
      display: block; }
    .new-button-container .tooltip ul {
      margin-top: 0px; }

.new-button-wrapper:hover + .tooltip {
  display: block; }

.new-button-wrapper {
  padding: 14px 0px;
  border-top: 1px solid #F0F1F1;
  margin: 0px 16px; }
  .new-button-wrapper .text-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 0px auto;
    padding: 12px 48px 12px 48px;
    font-size: 0.8rem; }
    .new-button-wrapper .text-button#company-add {
      margin-bottom: 1px; }
    .new-button-wrapper .text-button:hover:disabled {
      color: #BDBDBD; }
      .new-button-wrapper .text-button:hover:disabled .icon-add-person, .new-button-wrapper .text-button:hover:disabled .icon-add-company {
        color: #fdc6c1; }
    .new-button-wrapper .text-button:disabled {
      color: #BDBDBD;
      cursor: not-allowed; }
      .new-button-wrapper .text-button:disabled .icon-add-person, .new-button-wrapper .text-button:disabled .icon-add-company {
        color: #fdc6c1; }
    .new-button-wrapper .text-button:hover {
      color: #202020; }
      .new-button-wrapper .text-button:hover .icon-add-person, .new-button-wrapper .text-button:hover .icon-add-company {
        color: #f85142; }
  .new-button-wrapper .icon-add-person, .new-button-wrapper .icon-add-company {
    margin-right: 8px;
    color: #f96c5f;
    font-size: 20px; }
    .new-button-wrapper .icon-add-person:before, .new-button-wrapper .icon-add-company:before {
      margin-left: 0px; }
  .new-button-wrapper .icon-add-company {
    font-size: 14px;
    margin-right: 19px; }

.text-button.expander {
  color: #D1D1D1;
  padding: 6px 0px 6px 6px; }
  .text-button.expander:focus, .text-button.expander:hover {
    color: #878787; }

.sidebar-empty {
  text-align: center;
  margin: 32px;
  color: #D1D1D1;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%); }
  .sidebar-empty i {
    font-size: 40px;
    color: #F0F1F1; }
  .sidebar-empty h2 {
    font-weight: 600;
    font-size: 18px; }
  .sidebar-empty p {
    font-size: 15px;
    margin-bottom: 0px; }

.sidebar {
  width: 280px;
  height: 100%;
  position: fixed;
  top: -webkit-calc(78px + 5px);
  top: -moz-calc(78px + 5px);
  top: calc(78px + 5px);
  -webkit-border-top-right-radius: 2px;
     -moz-border-radius-topright: 2px;
          border-top-right-radius: 2px;
  background-color: #F9FAFC;
  margin-right: 8px;
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2); }
  .sidebar.fixed {
    position: fixed;
    left: 0;
    top: 0;
    height: -webkit-calc(100%);
    height: -moz-calc(100%);
    height: calc(100%);
    z-index: 100; }
  .sidebar .load-wrapper {
    position: absolute;
    top: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%; }
    .sidebar .load-wrapper .loader {
      z-index: auto; }
  .sidebar .tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 7px 15px 0px 15px;
    font-size: 14px;
    color: #BDBDBD; }
    .sidebar .tabs a {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      border-bottom: 1px solid #F0F1F1;
      width: 50%;
      color: #BDBDBD; }
      .sidebar .tabs a:first-of-type {
        padding: 9px 15px 3px 0px; }
        .sidebar .tabs a:first-of-type:not(.selected-tab) {
          border-right: 1px solid #F0F1F1; }
      .sidebar .tabs a:last-of-type {
        padding: 9px 0px 3px 15px; }
        .sidebar .tabs a:last-of-type:not(.selected-tab) {
          border-left: 1px solid #F0F1F1; }
      .sidebar .tabs a:hover:not(.selected-tab) span, .sidebar .tabs a:focus:not(.selected-tab) span {
        color: #878787;
        border-bottom: 3px solid #fcb0a9; }
      .sidebar .tabs a span {
        border-bottom: 3px solid transparent;
        padding: 0px 5px 10px 5px;
        display: inline-block; }
    .sidebar .tabs .selected-tab {
      color: #505050;
      border-left: 1px solid transparent;
      border-bottom: 1px solid transparent; }
      .sidebar .tabs .selected-tab .text-button:hover {
        color: #505050;
        border-bottom: 3px solid #f96c5f; }
      .sidebar .tabs .selected-tab span {
        border-bottom: 3px solid #f96c5f; }

.sidebar-search-container {
  padding: 16px 16px 16px 16px;
  margin: 0px 16px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: solid 1px #F0F1F1; }
  .sidebar-search-container .material-icons {
    font-size: 22px;
    vertical-align: middle; }
  .sidebar-search-container span {
    position: relative;
    display: inline-block;
    max-width: 100%; }
    .sidebar-search-container span:first-of-type {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1;
         -moz-box-flex: 1;
          -ms-flex: 1 1;
              flex: 1 1; }

.sidebar-search-container input {
  background-color: transparent;
  border: none;
  outline: none !important;
  padding: 10px 2px 10px 28px;
  font-size: 14px;
  width: 100%; }

.sidebar-search-container input:-ms-input-placeholder {
  color: #D1D1D1;
  font-weight: 600;
  letter-spacing: 0.5px;
  max-width: 100%; }

.sidebar-search-container input::-webkit-input-placeholder {
  color: #D1D1D1;
  font-weight: 600;
  letter-spacing: 0.5px; }

.sidebar-search-container input::-moz-placeholder {
  color: #D1D1D1;
  font-weight: 600;
  letter-spacing: 0.5px; }

.sidebar-search-container input::-ms-input-placeholder {
  color: #D1D1D1;
  font-weight: 600;
  letter-spacing: 0.5px; }

.sidebar-search-container input::placeholder {
  color: #D1D1D1;
  font-weight: 600;
  letter-spacing: 0.5px; }

.sidebar-search-container input:focus + .icon-input-overlay {
  color: #878787; }

input:focus {
  color: #505050; }

.sidebar-search-container .icon-input-overlay {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0px;
  color: #D1D1D1; }

.sidebar-section {
  border-top: 1px solid #F0F1F1; }

.sidebar-section-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #676767;
  letter-spacing: 0.5px; }

.sidebar-section-icon {
  padding-right: 8px;
  font-size: 20px;
  color: #BDBDBD;
  min-width: 20px;
  text-align: center; }

.alpha-selector-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 12px 16px 12px 16px;
  margin: 0px 16px;
  border-bottom: solid 1px #F0F1F1;
  cursor: pointer;
  height: 48px; }
  .alpha-selector-toggle.expanded {
    border-bottom: 1px solid transparent; }

.alpha-selector-letter {
  display: block;
  font-weight: 600;
  color: #676767;
  letter-spacing: 0.5px;
  width: 20px;
  text-align: center; }

.alpha-selector-icon {
  color: #D1D1D1; }
  .alpha-selector-icon .material-icons {
    vertical-align: middle; }

.employee-tab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: capitalize;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px 32px;
  cursor: pointer;
  position: relative;
  height: 100%; }
  .employee-tab:first-of-type {
    border: none; }
  .employee-tab.active {
    margin: 0px;
    border: none;
    background-color: rgba(182, 182, 184, 0.19);
    border-left: 3px solid #f96c5f;
    padding: 16px 32px 16px 29px; }
    .employee-tab.active + div {
      border: none; }
    .employee-tab.active .employee-name-container .employee-name-main {
      font-weight: 700; }
    .employee-tab.active .employee-name-container .employee-name-alt {
      font-weight: 600; }
  .employee-tab .employee-name-container {
    width: 100%; }
    .employee-tab .employee-name-container .employee-name-main {
      font-size: 13px;
      margin-bottom: 2px;
      display: block;
      font-weight: 600; }
    .employee-tab .employee-name-container .employee-name-alt {
      font-size: 0.7em;
      color: #878787;
      display: block; }

.employee-tab:not(.active):hover .employee-name-container .employee-name-main {
  color: #1d1d1d;
  -webkit-transition: color 0.5s;
  -o-transition: color 0.5s;
  -moz-transition: color 0.5s;
  transition: color 0.5s; }

.employee-tab:not(.active):hover .employee-name-container .employee-name-alt {
  color: #545454;
  -webkit-transition: color 0.5s;
  -o-transition: color 0.5s;
  -moz-transition: color 0.5s;
  transition: color 0.5s; }

.employee-tab-search-item {
  margin-left: 24px;
  margin-top: 12px; }

.employee-tab-search-type {
  color: #A5A5A5;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 8px;
  letter-spacing: 0.2px; }

.employee-tab-search-name {
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  width: 100%;
  color: #676767; }

.emp-row {
  background-color: white; }

.emp-row.first {
  -webkit-box-shadow: inset 0 4px 3px -3px rgba(0, 0, 0, 0.15), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2);
     -moz-box-shadow: inset 0 4px 3px -3px rgba(0, 0, 0, 0.15), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 4px 3px -3px rgba(0, 0, 0, 0.15), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2); }

.emp-row.last {
  -webkit-box-shadow: inset 0 -4px 3px -3px rgba(0, 0, 0, 0.15), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2);
     -moz-box-shadow: inset 0 -4px 3px -3px rgba(0, 0, 0, 0.15), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 -4px 3px -3px rgba(0, 0, 0, 0.15), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2); }
  .emp-row.last .employee-tab {
    border-bottom: none; }

.emp-row.first.last {
  -webkit-box-shadow: inset 0 4px 3px -3px rgba(0, 0, 0, 0.15), inset 0 -4px 3px -3px rgba(0, 0, 0, 0.15), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2);
     -moz-box-shadow: inset 0 4px 3px -3px rgba(0, 0, 0, 0.15), inset 0 -4px 3px -3px rgba(0, 0, 0, 0.15), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 4px 3px -3px rgba(0, 0, 0, 0.15), inset 0 -4px 3px -3px rgba(0, 0, 0, 0.15), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0 0px 0px rgba(0, 0, 0, 0.2); }

.archived {
  text-transform: lowercase;
  opacity: 0.6;
  font-weight: normal; }

@media (max-width: 1025px) {
  .employee-tab {
    padding: 14px 28px; }
    .employee-tab.active {
      padding: 14px 28px 14px 25px; }
  .employee-tab .employee-name-container .employee-name-main {
    font-size: 11px; }
  .employee-tab-search-item {
    margin-left: 16px;
    margin-top: 8px; }
  .employee-tab-search-type {
    font-size: 8px; }
  .employee-tab-search-name {
    font-size: 10px; } }

.results-details-row {
  color: #878787;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  font-size: 15px; }
  .results-details-row span {
    display: inline-block; }
  .results-details-row .filters-span {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
    margin-top: 16px;
    display: inline-block; }
  .results-details-row .material-icons {
    color: #D1D1D1;
    vertical-align: middle;
    margin-right: 4px;
    font-size: 18px; }
  .results-details-row .errors-wrapper {
    position: relative;
    margin-left: 30px;
    margin-top: 16px; }
  .results-details-row .errors {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8em;
    letter-spacing: 0.2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #676767;
    line-height: 26px;
    min-height: 36px; }
  .results-details-row .errors:hover + .tooltip {
    display: block; }

.results-details-label {
  font-weight: 600;
  font-size: 0.8em;
  text-transform: uppercase;
  margin-right: 8px;
  letter-spacing: 0.2px;
  color: #878787; }

.results-details-filter {
  background-color: #ECEDF0;
  padding: 4px 8px;
  font-size: 0.9em;
  margin-right: 12px;
  margin-top: 8px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  color: #676767; }

.search-toggle-item {
  border-top: 1px solid #e8eded;
  margin: 0px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }
  .search-toggle-item .accordion-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .search-toggle-item:first-of-type {
    border-color: transparent; }
  .search-toggle-item:last-of-type {
    border-bottom: 1px solid #e8eded; }
  .search-toggle-item.open .results-header {
    padding-bottom: 0px;
    margin-bottom: 0px; }
  .search-toggle-item.open:only-of-type {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    border-color: transparent; }
    .search-toggle-item.open:only-of-type .accordion-content {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
         -moz-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; }
  .search-toggle-item .results-container {
    margin: 0px; }
  .search-toggle-item .results-header {
    padding: 28px 24px;
    margin: 8px 0px; }
  .search-toggle-item .search-header-sub-row {
    margin-bottom: 36px; }

.results-header {
  padding: 0px 24px;
  margin: 16px 0px 0px 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .results-header .material-icons {
    color: #D1D1D1;
    vertical-align: middle;
    margin-right: 4px;
    font-size: 18px; }
  .results-header .toggle-icon {
    color: #f96c5f;
    font-size: 24px;
    margin-right: 12px; }

.search-header-main-row {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  position: relative; }

.search-header-sub-row {
  padding: 0px 24px;
  margin-bottom: 36px; }

.results-name-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  .results-name-row .loading-bar {
    position: absolute;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 4px;
    max-width: 250px; }
    .results-name-row .loading-bar .loading-bar-outer {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1;
         -moz-box-flex: 1;
          -ms-flex: 1 1;
              flex: 1 1;
      height: 5px;
      background-color: #D1D1D1;
      -webkit-border-radius: 5px;
         -moz-border-radius: 5px;
              border-radius: 5px;
      margin-right: 5px; }
    .results-name-row .loading-bar .loading-bar-progress {
      background-color: #f96c5f;
      height: 5px;
      -webkit-border-radius: 5px;
         -moz-border-radius: 5px;
              border-radius: 5px;
      width: 0px;
      -webkit-transition: width .6s ease-in-out;
      -o-transition: width .6s ease-in-out;
      -moz-transition: width .6s ease-in-out;
      transition: width .6s ease-in-out; }
    .results-name-row .loading-bar span {
      font-size: 12px;
      font-weight: 600;
      color: #f96c5f; }
  .results-name-row h4 {
    margin-top: 0;
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
    .results-name-row h4 .header-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .results-name-row h4 .header-wrapper .name-wrapper {
        height: 42px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
    .results-name-row h4 strong {
      text-transform: capitalize; }
    .results-name-row h4 .count-wrapper {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      -webkit-border-radius: 30px;
         -moz-border-radius: 30px;
              border-radius: 30px;
      background-color: #ECEDF0;
      padding: 4px 8px;
      color: #878787;
      margin: 0 20px; }
  .results-name-row .text-button {
    font-size: 0.8rem;
    color: #676767;
    padding: 12px 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .results-name-row .text-button.disabled {
      cursor: not-allowed;
      opacity: 0.7;
      pointer-events: none; }
  .results-name-row .text-button:hover:not(:disabled) {
    color: #202020; }
    .results-name-row .text-button:hover:not(:disabled) .material-icons {
      color: #878787; }
  .results-name-row i.icon-pencil {
    margin-right: 4px;
    font-size: 14px;
    font-weight: 700; }

.person-type {
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #878787;
  position: absolute;
  top: -12px; }

.results-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: none;
     -moz-box-flex: 0;
      -ms-flex: none;
          flex: none; }

.subcategory-container .tag-container {
  font-weight: normal; }
  .subcategory-container .tag-container .tag-item > span {
    margin-right: 0; }

.subcategory-container .count-wrapper {
  margin-right: 20px; }

.main-name {
  text-transform: capitalize; }

a {
  text-decoration: none;
  color: #505050; }

a:hover {
  color: #505050; }

.result-container-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.result-section-container {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .result-section-container .noResults {
    height: auto;
    margin-bottom: 36px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 100%; }

.results-container {
  margin: 20px 20px 0px 20px;
  padding-bottom: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto; }

.subcategory-container {
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px; }
  .subcategory-container .ReactVirtualized__Grid, .subcategory-container .ReactVirtualized__List, .subcategory-container .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important; }
  .subcategory-container h2 {
    text-transform: capitalize; }
  .subcategory-container.open {
    background-color: white;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
       -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
    margin: 16px 0px;
    padding: 0px 0px 4px 24px; }
    .subcategory-container.open .count-wrapper {
      background-color: #F7F7F8; }
    .subcategory-container.open h2 {
      color: #f96c5f; }
    .subcategory-container.open .subcategory-row {
      border-top: none;
      padding: 32px 24px 16px 0px; }
      .subcategory-container.open .subcategory-row:hover, .subcategory-container.open .subcategory-row:focus {
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
        background-color: none; }
  .subcategory-container:last-child.open {
    margin-bottom: 48px; }
  .subcategory-container:nth-child(2) .subcategory-row {
    border-top: none; }

.subcategory-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 32px 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-top: 1px solid #F0F1F1;
  color: inherit;
  width: 100%;
  background: none; }
  .subcategory-row .count-wrapper {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    -webkit-border-radius: 30px;
       -moz-border-radius: 30px;
            border-radius: 30px;
    background-color: #F0F1F1;
    padding: 2px 8px;
    color: #878787;
    margin-left: 20px; }
  .subcategory-row:hover {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border-top: 1px solid #fcfcfd; }
  .subcategory-row i {
    vertical-align: middle;
    font-size: 24px;
    color: #BDBDBD; }
  .subcategory-row h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .subcategory-row .count {
    font-weight: normal;
    margin-left: 4px; }
  .subcategory-row .subcategory-details span:not(:last-child) {
    margin-right: 64px; }

.source-container {
  border-bottom: 1px solid #F0F1F1; }

.source-container:last-of-type {
  border-bottom: none; }

.source-row {
  padding: 24px 24px 24px 0px;
  cursor: pointer;
  font-size: 14px; }
  .source-row .count-wrapper {
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    -webkit-border-radius: 30px;
       -moz-border-radius: 30px;
            border-radius: 30px;
    background-color: #F7F7F8;
    padding: 2px 8px;
    color: #878787;
    margin-left: 20px; }
  .source-row.fixed-header {
    border-bottom: none;
    padding: 24px 24px 24px 0px; }
  .source-row .flex-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .source-row .filters-span {
    color: #878787;
    margin-top: 8px; }
  .source-row .tool-tip-wrapper {
    position: relative; }
    .source-row .tool-tip-wrapper i {
      font-size: 18px;
      margin-left: 10px; }
  .source-row i {
    vertical-align: middle;
    font-size: 24px;
    color: #BDBDBD; }
  .source-row .error-tool-tip {
    position: absolute;
    right: -90px;
    bottom: 30px;
    width: 200px;
    text-align: center;
    display: none;
    background-color: #505050;
    padding: 13px;
    z-index: 10;
    color: white;
    font-weight: normal;
    font-size: 13px;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    -webkit-filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
            filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3)); }
    .source-row .error-tool-tip:after {
      content: " ";
      position: absolute;
      top: -webkit-calc(100% - 1px);
      top: -moz-calc(100% - 1px);
      top: calc(100% - 1px);
      left: 50%;
      margin-left: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: #505050 transparent transparent transparent; }
  .source-row i:hover + .error-tool-tip {
    display: inline; }
  .source-row .error-tool-tip:hover {
    display: inline; }
  .source-row h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .source-row .source-details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 600; }
    .source-row .source-details *:not(:last-child) {
      margin-right: 64px; }
    .source-row .source-details a {
      display: inline-block;
      padding-bottom: 1px; }
  .source-row .text-button.selected {
    color: #505050;
    border-bottom: 3px solid #feddda; }
  .source-row .text-button:hover, .source-row a:hover {
    border-bottom: 3px solid #fcb0a9; }

.table-container {
  position: relative; }

.source-table {
  width: 100%;
  font-size: 12px;
  font-family: 'Poppins';
  border-spacing: 0;
  border-left: 2px solid #f96c5f;
  margin-bottom: 24px; }
  .source-table .dashboard-record-wrapper:last-of-type .dashboard-record, .source-table .expanded .dashboard-record {
    border-bottom: none; }
  .source-table .dashboard-record {
    font-size: 12px;
    padding: 0px;
    margin: 0px 24px 0px 10px;
    position: relative; }
    .source-table .dashboard-record .flex-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex; }
    .source-table .dashboard-record .tooltip {
      font-size: 12px; }
    .source-table .dashboard-record .record-checkbox {
      position: relative;
      padding: 10px 6px;
      overflow: visible;
      white-space: normal; }
      .source-table .dashboard-record .record-checkbox.schema-attr {
        overflow: visible;
        white-space: normal; }
      .source-table .dashboard-record .record-checkbox .tooltip {
        display: none;
        left: -webkit-calc(100% + 4px);
        left: -moz-calc(100% + 4px);
        left: calc(100% + 4px);
        -webkit-transform: translateY(-50%);
           -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
             -o-transform: translateY(-50%);
                transform: translateY(-50%);
        top: 50%;
        padding: 8px 12px;
        min-width: 200px;
        z-index: 11; }
        .source-table .dashboard-record .record-checkbox .tooltip ul {
          margin: 0px; }
    .source-table .dashboard-record .checkbox input[type=checkbox]:disabled + label:before {
      color: #F0F1F1; }
    .source-table .dashboard-record .checkbox input[type=checkbox]:disabled:hover + label:before {
      color: #F0F1F1; }
    .source-table .dashboard-record .checkbox input[type=checkbox] + label:before {
      color: #BDBDBD;
      font-size: 11px;
      position: inherit;
      left: auto;
      top: auto; }
    .source-table .dashboard-record .checkbox input[type=checkbox]:hover + label:before {
      color: #878787; }
    .source-table .dashboard-record .checkbox input[type=checkbox]:focus + label:before {
      color: #878787; }
    .source-table .dashboard-record .checkbox input[type=checkbox]:checked + label:before {
      color: #676767; }
    .source-table .dashboard-record .checkbox input[type=checkbox]:checked + label {
      color: #676767; }
    .source-table .dashboard-record .record-checkbox:hover .tooltip {
      display: block; }
    .source-table .dashboard-record .tooltip:hover {
      display: block; }
    .source-table .dashboard-record .status-wrapper {
      position: relative; }
      .source-table .dashboard-record .status-wrapper .tooltip {
        padding: 8px 12px; }
    .source-table .dashboard-record.last .dropdown-container {
      max-height: 168px;
      overflow: auto; }
    .source-table .dashboard-record .dropdown {
      width: 100%; }
      .source-table .dashboard-record .dropdown button:disabled {
        color: #BDBDBD;
        background: #eff0f2; }
        .source-table .dashboard-record .dropdown button:disabled .material-icons {
          opacity: 0; }
    .source-table .dashboard-record .dropdown + .tooltip {
      min-width: 200px;
      -webkit-transform: none;
         -moz-transform: none;
          -ms-transform: none;
           -o-transform: none;
              transform: none;
      top: auto;
      bottom: -webkit-calc(100% + 18px);
      bottom: -moz-calc(100% + 18px);
      bottom: calc(100% + 18px);
      right: -37px; }
      .source-table .dashboard-record .dropdown + .tooltip ul {
        margin: 0px; }
    .source-table .dashboard-record .dropdown-container {
      word-break: break-all;
      -webkit-hyphens: auto;
         -moz-hyphens: auto;
          -ms-hyphens: auto;
              hyphens: auto;
      max-height: 200px; }
      .source-table .dashboard-record .dropdown-container .option {
        word-break: break-word;
        font-size: 12px;
        padding: 10px 8px; }
        .source-table .dashboard-record .dropdown-container .option:not(.selected) {
          padding-left: 25px; }
        .source-table .dashboard-record .dropdown-container .option i {
          font-size: 12px; }
    .source-table .dashboard-record .status-wrapper:hover .tooltip {
      display: block; }
    .source-table .dashboard-record .dashboard-cell {
      padding: 17px 6px; }
      .source-table .dashboard-record .dashboard-cell button {
        font-size: 11px; }
      .source-table .dashboard-record .dashboard-cell.fixedWidth {
        min-width: 122px; }
        .source-table .dashboard-record .dashboard-cell.fixedWidth .dropdownButton .dropdown-text, .source-table .dashboard-record .dashboard-cell.fixedWidth .match-button.icon-text-button .dropdown-text {
          max-width: 90px; }
        .source-table .dashboard-record .dashboard-cell.fixedWidth:not(.schema-attr) {
          font-size: 11px; }
        .source-table .dashboard-record .dashboard-cell.fixedWidth i {
          font-size: 16px; }
          .source-table .dashboard-record .dashboard-cell.fixedWidth i.note-button {
            font-size: 24px; }
      .source-table .dashboard-record .dashboard-cell a {
        color: #393939;
        border-bottom: 3px solid #feddda;
        text-decoration: none; }
        .source-table .dashboard-record .dashboard-cell a:hover {
          text-decoration: none;
          border-bottom-color: #fcb0a9;
          -webkit-transition: 0.3s;
          -o-transition: 0.3s;
          -moz-transition: 0.3s;
          transition: 0.3s; }
      .source-table .dashboard-record .dashboard-cell .record-cell i {
        font-size: 14px;
        color: #BDBDBD;
        margin-left: 4px;
        vertical-align: text-bottom;
        cursor: pointer; }

.source-row.on {
  display: block;
  visibility: visible; }

.fixed-header {
  visibility: hidden;
  position: fixed;
  top: -webkit-calc(78px);
  top: -moz-calc(78px);
  top: calc(78px);
  z-index: 1;
  background-color: white; }
  .fixed-header .dashboard-record {
    border-bottom: none; }
  .fixed-header.on:not(.source-row) {
    display: block;
    visibility: visible;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
       -moz-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end; }

.row-transitor.active {
  overflow-y: hidden;
  -webkit-transition: height 0.3s, top 0.3s;
  -o-transition: height 0.3s, top 0.3s;
  -moz-transition: height 0.3s, top 0.3s;
  transition: height 0.3s, top 0.3s;
  pointer-events: none; }

.progress-container {
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
  background-color: #D1D1D1;
  width: 100px;
  height: 10px;
  overflow: hidden; }
  .progress-container .progress-bar {
    background-color: #f96c5f;
    -webkit-transition: width 0.3s;
    -o-transition: width 0.3s;
    -moz-transition: width 0.3s;
    transition: width 0.3s;
    height: 100%;
    -webkit-border-radius: inherit;
       -moz-border-radius: inherit;
            border-radius: inherit; }

.file-status {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

@media (max-width: 1345px) {
  .source-table .dashboard-cell[data-sort='date'], .source-table .dashboard-cell[data-cell='date'] {
    max-width: 78px; }
  .source-table .dashboard-cell[data-sort='amended'], .source-table .dashboard-cell[data-cell='amended'] {
    max-width: 72px; }
  .source-table .dashboard-cell[data-sort='amount'], .source-table .dashboard-cell[data-cell='amount'] {
    max-width: 105px; } }

@media (max-width: 1025px) {
  .results-name-row h4 .count-wrapper {
    font-size: 12px; } }

.schema-attr {
  text-align: left;
  font-size: 12px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  color: #878787;
  text-transform: uppercase;
  font-weight: normal; }
  .schema-attr.dashboard-cell {
    display: inline-block; }
    .schema-attr.dashboard-cell.fixedWidth.notes {
      position: initial; }
  .schema-attr .wrapper-hide {
    visibility: hidden;
    opacity: 0; }
  .schema-attr .wrapper-show {
    visibility: visible;
    opacity: 1; }
  .schema-attr.sorted {
    border-bottom: #f96c5f solid 2px !important; }
  .schema-attr .sort-icon {
    position: absolute;
    right: -9px;
    font-size: 18px;
    color: #f96c5f; }
  .schema-attr .tooltip {
    opacity: 1;
    display: block;
    min-width: auto;
    padding: 4px 6px;
    color: #878787;
    right: auto;
    -webkit-transform: translateY(50%);
       -moz-transform: translateY(50%);
        -ms-transform: translateY(50%);
         -o-transform: translateY(50%);
            transform: translateY(50%);
    top: 0px; }
    .schema-attr .tooltip ul {
      display: none; }
    .schema-attr .tooltip.top {
      top: -35px; }
    .schema-attr .tooltip.bottom {
      top: 40px; }
      .schema-attr .tooltip.bottom svg {
        margin-left: -12px; }
  .schema-attr.record-checkbox {
    text-transform: none; }
  .schema-attr .hidden-span {
    visibility: hidden;
    position: absolute;
    z-index: -10;
    top: 0px; }
  .schema-attr .hoverable {
    position: relative;
    width: 100%; }
    .schema-attr .hoverable span {
      position: relative;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      width: 100%; }

.dashboard-record-container .schema, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .schema {
  -webkit-border-top-right-radius: 2px;
     -moz-border-radius-topright: 2px;
          border-top-right-radius: 2px;
  -webkit-border-top-left-radius: 2px;
     -moz-border-radius-topleft: 2px;
          border-top-left-radius: 2px; }
  .dashboard-record-container .schema .dashboard-record, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .schema .dashboard-record {
    padding: 8px 0px; }

.dashboard-record-container .schema-attr, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .schema-attr {
  font-size: 10px;
  color: #BDBDBD; }
  .dashboard-record-container .schema-attr .hidden-span, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .schema-attr .hidden-span {
    padding: 0 10px; }

.source-table .schema-attr {
  border-bottom: #e5e5e5 solid 2px;
  height: 55px;
  cursor: pointer; }
  .source-table .schema-attr.disabled {
    cursor: auto; }
  .source-table .schema-attr .hidden-span {
    padding: 17px 6px; }

.schema-container {
  width: 100%;
  z-index: 1; }

.schema-header {
  height: 56px;
  width: 100%; }
  .schema-header .dashboard-record {
    border-bottom: none; }

.candidate-tooltip {
  position: fixed;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: solid 1px #D1D1D1;
  -webkit-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.3);
     -moz-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(-webkit-calc(-100% - 10px));
     -moz-transform: translateY(-moz-calc(-100% - 10px));
      -ms-transform: translateY(calc(-100% - 10px));
       -o-transform: translateY(calc(-100% - 10px));
          transform: translateY(calc(-100% - 10px));
  max-width: 250px;
  z-index: 20; }
  .candidate-tooltip .candidate-header {
    padding: 12px 16px 8px;
    background-color: #EDF0F5;
    font-weight: 600;
    font-size: 12px;
    color: #777E8C; }
  .candidate-tooltip .candidate-content {
    padding: 12px 16px 4px;
    background-color: white;
    word-break: break-word; }
  .candidate-tooltip .candidate-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-bottom: 8px; }
    .candidate-tooltip .candidate-row.spacer .candidate-row:first-child {
      margin-right: 18px; }
    .candidate-tooltip .candidate-row .candidate-row-label {
      margin-right: 8px;
      text-transform: uppercase;
      letter-spacing: 0.2px;
      font-size: 10px;
      font-weight: 600;
      word-break: keep-all;
      color: #BDBDBD; }
    .candidate-tooltip .candidate-row .candidate-row-value {
      font-size: 12px;
      color: #505050;
      margin-top: -2px; }
    .candidate-tooltip .candidate-row:last-child {
      margin-bottom: 0; }
  .candidate-tooltip .candidate-triangle svg {
    position: absolute;
    top: -20px;
    left: -webkit-calc(50% - 10px);
    left: -moz-calc(50% - 10px);
    left: calc(50% - 10px); }
  .candidate-tooltip .candidate-triangle.top .uses-svg {
    -webkit-transform: rotateX(180deg);
       -moz-transform: rotateX(180deg);
            transform: rotateX(180deg); }
  .candidate-tooltip .candidate-triangle .uses-svg svg {
    width: 20px; }

.custom-field-item-wrapper {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 36px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  color: #676767; }

.custom-field-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  margin-right: 12px;
  line-height: 26px; }
  .custom-field-item span {
    display: inline; }
  .custom-field-item input {
    padding: 12px 16px;
    color: #676767;
    -webkit-border-radius: 2px 2px 0 0;
       -moz-border-radius: 2px 2px 0 0;
            border-radius: 2px 2px 0 0;
    border: none;
    border-bottom: 1px solid #D1D1D1;
    font-size: 1rem;
    background-color: white;
    padding: 8px;
    font-size: inherit; }
    .custom-field-item input:active:not(:disabled):not([readonly]), .custom-field-item input:focus:not(:disabled):not([readonly]) {
      color: #505050;
      border-color: #505050;
      outline: none; }
    .custom-field-item input::-webkit-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .custom-field-item input::-moz-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .custom-field-item input::-ms-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .custom-field-item input::placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .custom-field-item input.error {
      border-color: #f96c5f; }
    .custom-field-item input:disabled, .custom-field-item input[readonly] {
      opacity: 0.8;
      font-style: italic;
      cursor: not-allowed;
      background: #f2f2f2; }
  .custom-field-item .error-text, .custom-field-item .formPage form .errorlist, .formPage form .custom-field-item .errorlist {
    display: none; }
  .custom-field-item .table-row {
    display: inline;
    font-size: 0.9em;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  .custom-field-item .table-cell {
    display: inline; }
    .custom-field-item .table-cell.text-center {
      min-width: 32px;
      display: inline-block; }
  .custom-field-item .orange-button {
    font-size: 10px;
    padding: 4px 8px;
    display: inline-block;
    margin: 0px 8px; }
  .custom-field-item .material-icons {
    font-size: 16px; }
  .custom-field-item .material-icons:hover {
    color: #878787; }
  .custom-field-item:hover:not(.disabled) .edit-button {
    display: inline-block; }
  .custom-field-item .edit-button {
    padding: 2px 4px;
    display: none; }
    .custom-field-item .edit-button:hover {
      display: inline-block; }

.results-details-content {
  position: relative; }

.truncate {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  max-width: 350px;
  position: relative; }
  .truncate:hover + .tooltip {
    display: block;
    -webkit-transform: translate(-50%, -100%);
       -moz-transform: translate(-50%, -100%);
        -ms-transform: translate(-50%, -100%);
         -o-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    left: 50%;
    top: -50%; }
    .truncate:hover + .tooltip .message {
      display: none; }

.note-button .badge {
  top: -10px;
  left: -24px;
  position: relative;
  padding: 1px 5px 1px;
  width: auto !important;
  -webkit-box-flex: 0 !important;
  -webkit-flex: none !important;
     -moz-box-flex: 0 !important;
      -ms-flex: none !important;
          flex: none !important; }

.note-button span {
  display: inline-block;
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1; }

.note-button.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  width: 100%; }

.note-button.text-button {
  color: #878787; }
  .note-button.text-button.has-note {
    color: #505050; }

.note-button:hover.text-button, .note-button:focus.text-button {
  color: #505050; }
  .note-button:hover.text-button.has-note, .note-button:focus.text-button.has-note {
    color: #202020; }

.note-button:hover i, .note-button:focus i {
  color: #505050; }
  .note-button:hover i.icon-note_text, .note-button:focus i.icon-note_text {
    color: #f85142; }

.note-button i {
  font-size: 24px;
  margin: 0px auto;
  font-weight: 700;
  color: #878787;
  text-align: center; }
  .note-button i.icon-note_text, .note-button i.attach_file, .note-button i.icon-note_attachment {
    color: #f96c5f; }
  .note-button i.attach_file {
    font-weight: 100; }

.dashboard-record .dashboard-cell.fixedWidth.notes, .dashboard-record .dashboard-cell.fixedWidth.send {
  position: relative;
  min-width: 52px !important; }

.note-wrapper.tooltip {
  display: block;
  width: 500px;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
       -o-transform: none;
          transform: none;
  z-index: 2; }
  .note-wrapper.tooltip textarea {
    min-height: 85px;
    font-size: 14px; }
  .note-wrapper.tooltip .helptext {
    font-size: 11px;
    color: #878787; }
  .note-wrapper.tooltip .error-text, .note-wrapper.tooltip .formPage form .errorlist, .formPage form .note-wrapper.tooltip .errorlist {
    font-size: 11px;
    margin: 0px; }
  .note-wrapper.tooltip .arrow.bottom {
    left: auto; }
  .note-wrapper.tooltip svg.bottom {
    left: auto; }

.note-wrapper textarea {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: 1px solid #ababab;
  font-size: 1rem;
  border-color: #D1D1D1;
  width: 100%;
  resize: none; }
  .note-wrapper textarea:active, .note-wrapper textarea:focus {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .note-wrapper textarea::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .note-wrapper textarea::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .note-wrapper textarea::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .note-wrapper textarea::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .note-wrapper textarea.error {
    border-color: #f96c5f; }
  .note-wrapper textarea:active, .note-wrapper textarea:focus {
    border-color: #878787; }

.comment-button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 12px; }
  .comment-button-wrapper .orange-button {
    margin: 0px;
    display: inline-block;
    padding: 8px 16px !important; }

.buttons-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 12px; }
  .buttons-wrapper .orange-button {
    margin: 0px;
    display: inline-block;
    padding: 8px 16px !important; }
  .buttons-wrapper .text-button {
    color: #BDBDBD;
    margin-right: 12px; }
    .buttons-wrapper .text-button:hover {
      color: #878787; }

.note-files {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1; }
  .note-files input[type="file"] {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px); }
  .note-files .file-controls {
    position: relative; }
  .note-files .upload-handler {
    font-weight: 600;
    font-size: 13px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    padding: 5px;
    padding-bottom: 3px;
    padding-left: 0;
    margin-top: -1px;
    color: #f96c5f;
    border-bottom: solid 2px transparent;
    -webkit-transition: border-bottom-color 0.3s;
    -o-transition: border-bottom-color 0.3s;
    -moz-transition: border-bottom-color 0.3s;
    transition: border-bottom-color 0.3s; }
    .note-files .upload-handler.disable {
      color: #fb9990;
      cursor: not-allowed; }
      .note-files .upload-handler.disable:hover {
        border-bottom-color: transparent; }
    .note-files .upload-handler:hover {
      padding-bottom: 3px;
      border-bottom-color: #f96c5f; }
  .note-files .upload-handler i {
    font-size: 13px;
    font-weight: bold; }
  .note-files .upload-handler i {
    margin-right: 5px; }

@media (max-width: 1025px) {
  .note-button .badge {
    padding: 1px 4px 1px;
    font-size: 9px; }
  .note-wrapper.tooltip textarea {
    font-size: 12px; }
  .note-wrapper .input-title, .note-wrapper .archive-delay-form label, .archive-delay-form .note-wrapper label {
    font-size: 12px; }
  .note-wrapper .buttons-wrapper .upload-handler {
    font-size: 11px; }
  .note-wrapper .buttons-wrapper .text-button {
    font-size: 11px; }
  .note-wrapper .buttons-wrapper .orange-button {
    font-size: 11px !important; } }

@media (max-width: 1345px) {
  .note-button span {
    display: inline-block;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
       -moz-box-flex: 1;
        -ms-flex: auto;
            flex: auto; } }

.actions-wrapper {
  max-height: 140px;
  overflow: auto;
  margin-top: 24px;
  font-size: 13px; }

.note-action-item {
  font-size: 13px;
  padding: 12px 12px;
  margin-bottom: 16px;
  background-color: #F7F7F8;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px; }
  .note-action-item.share {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .note-action-item i {
    margin-right: 8px;
    color: #D1D1D1;
    font-size: 18px; }
  .note-action-item .icon-send {
    font-size: 15px !important; }
    .note-action-item .icon-send:hover {
      color: #D1D1D1; }

.comment-header {
  color: #878787;
  padding-bottom: 8px;
  font-size: 11px; }

.commenter {
  font-weight: 600;
  margin-right: 12px; }

@media (max-width: 1025px) {
  .note-action-item {
    font-size: 12px; }
  .comment-header {
    font-size: 10px; } }

.dashboard-wrapper + .modal-container.send-record .record-matching-container.source-table {
  border: 1px solid #e8eded; }
  .dashboard-wrapper + .modal-container.send-record .record-matching-container.source-table .schema-attr {
    border-bottom: #e5e5e5 solid 2px;
    height: 55px;
    padding: 17px 6px; }

.action-modal.send-record .ReactVirtualized__Grid.ReactVirtualized__List {
  height: auto !important; }

.action-modal.send-record .modal-close {
  display: block; }

.action-modal.send-record textarea {
  min-height: 250px; }

.action-modal.send-record .error-message {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 2px 0px;
  font-size: 13px;
  color: red; }

.send-button .icon-send {
  font-size: 20px !important;
  color: #878787; }

.send-button:hover .icon-send {
  color: #505050; }

.send-button:disabled {
  cursor: not-allowed; }
  .send-button:disabled .icon-send {
    color: #D1D1D1; }

@media (max-width: 1025px) {
  .icon-send {
    font-size: 18px !important; } }

.loader {
  margin: auto;
  border: 12px solid #F9FAFC;
  /* Light grey */
  border-top: 12px solid #f96c5f;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  width: 90px;
  height: 90px;
  -webkit-animation: spin 1s linear infinite;
     -moz-animation: spin 1s linear infinite;
       -o-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  z-index: 1;
  position: absolute; }

.flex-load-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1; }

.result-loader-container, .tab-loader-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }

.result-loader-container {
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  min-height: 200px; }

.tab-loader-container .loader {
  position: relative; }

.small-loader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 10px;
  width: 20px; }
  .small-loader .loader {
    width: 20px;
    height: 20px;
    border: 3px solid #F9FAFC;
    border-top: 3px solid #f96c5f; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
         transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg);
         transform: rotate(360deg); } }

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
       transform: rotate(0deg); }
  100% {
    -o-transform: rotate(360deg);
       transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg); } }

.noResults {
  color: #BDBDBD;
  font-size: 20px;
  height: -webkit-calc(100vh - 200px);
  height: -moz-calc(100vh - 200px);
  height: calc(100vh - 200px);
  margin: 0px auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 600px;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .noResults div {
    text-align: center;
    width: 100%; }
  .noResults a {
    color: #f96c5f;
    text-decoration: underline; }
  .noResults .noResultsHeading {
    font-weight: 600;
    margin-bottom: 16px;
    font-size: 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    color: #A5A5A5; }
    .noResults .noResultsHeading i {
      color: #A5A5A5;
      padding-right: 10px;
      vertical-align: middle;
      font-size: 32px; }

.notFound {
  text-align: center;
  height: -webkit-calc(100vh - 69px);
  height: -moz-calc(100vh - 69px);
  height: calc(100vh - 69px);
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .notFound i.build {
    font-size: 50px;
    color: #949494; }
  .notFound .message {
    margin-top: 25px;
    margin-bottom: 28px; }
    .notFound .message .number {
      font-size: 40px;
      font-weight: bold; }
    .notFound .message .divider {
      position: relative;
      top: 3px;
      margin-left: 10px;
      margin-right: 10px;
      font-size: 45px;
      border-left: 5px solid #f96c5f; }
    .notFound .message .description {
      position: relative;
      top: -5px;
      font-size: 22px; }
  .notFound .back-button {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: inherit; }
    .notFound .back-button:hover {
      color: #f96c5f; }
      .notFound .back-button:hover i {
        color: #f96c5f; }

header {
  width: 100%;
  background: #FFFFFF;
  -webkit-box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 6px 0px 0px 0px;
  position: fixed;
  top: 0;
  z-index: 3; }
  header a {
    display: inline-block; }
  header .logo-wrapper {
    display: inline-block;
    margin-right: 30px;
    padding-top: 5px; }
  header .logo {
    margin-left: 24px;
    display: inline-block;
    position: relative;
    width: 120px;
    height: 32px; }
  header #illumis-logo {
    position: absolute;
    top: -4px;
    width: 100%;
    height: 60px; }
  header .text-button {
    color: #878787;
    font-size: 12px;
    padding: 22px 8px 28px 8px;
    border-bottom: 3px solid rgba(0, 0, 0, 0);
    margin: 0px 30px 0px 8px; }
  header .dropdown-menu {
    background-color: white;
    font-size: 13px;
    text-align: left;
    position: absolute;
    top: -webkit-calc(100%);
    top: -moz-calc(100%);
    top: calc(100%);
    right: 0px;
    width: 100%;
    z-index: 1;
    -webkit-border-bottom-left-radius: 2px;
       -moz-border-radius-bottomleft: 2px;
            border-bottom-left-radius: 2px;
    -webkit-border-bottom-right-radius: 2px;
       -moz-border-radius-bottomright: 2px;
            border-bottom-right-radius: 2px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
       -moz-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border: none;
    max-height: 500px;
    right: 0px; }
    header .dropdown-menu .dropdown-item {
      color: #676767;
      display: block;
      padding: 14px 16px;
      font-size: 15px;
      text-align: left;
      border: none;
      width: 100%;
      background-color: white; }
      header .dropdown-menu .dropdown-item:focus, header .dropdown-menu .dropdown-item:hover:not(:disabled) {
        background-color: #F9FAFC; }
      header .dropdown-menu .dropdown-item:disabled {
        opacity: 0.4;
        cursor: not-allowed; }
  header .dropdown-menu-right {
    z-index: 3; }
  header .text-button:not(.active):hover {
    color: #505050;
    border-bottom: 3px solid #fcb0a9; }
    header .text-button:not(.active):hover i {
      color: #878787; }
  header .show {
    display: block; }
  header .hide {
    display: none; }
  header nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    header nav .nav-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
  header .navbar-nav {
    margin-right: 30px;
    position: relative;
    display: inline-block;
    min-width: 190px; }
  header a.dropdown-toggle {
    padding: 0px 16px 21px; }
  header .dropdown-toggle {
    margin-left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    header .dropdown-toggle.text-button {
      margin: 0px; }
    header .dropdown-toggle.material-icons {
      vertical-align: middle;
      color: #D1D1D1;
      font-size: 18px;
      margin: -3px 0px -3px 8px; }

.nav-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

@media (max-width: 1800px) {
  span.dropdown-toggle {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    display: inline-block; } }

@media (max-width: 1400px) {
  span.dropdown-toggle {
    max-width: 135px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    display: inline-block; } }

@media (max-width: 1345px) {
  header .text-button {
    margin: 0px 10px 0px 0px; }
  header .navbar-nav {
    margin-right: 10px; }
  header .logo-wrapper {
    margin-right: 18px; }
  header .dropdown-toggle.text-button {
    margin: 0px; } }

@media (max-width: 1195px) {
  header .dropdown-toggle.text-button {
    margin: 0px; }
  header .logo {
    margin-left: 24px; } }

@media (max-width: 1080px) {
  header .navbar-nav {
    min-width: 150px; }
  span.dropdown-toggle {
    max-width: 90px; } }

@media (max-width: 1025px) {
  header .dropdown {
    margin-right: 8px; }
  header .text-button {
    font-size: 10px; }
    header .text-button.dropdown-toggle {
      margin: 0px !important; }
  header .text-button {
    margin: 0px 8px 0px 0px; }
  header .dropdown-menu .dropdown-item {
    font-size: 12px; }
  header #illumis-logo {
    height: 48px;
    top: 2px; }
  header .logo-wrapper {
    margin-right: 8px; }
    header .logo-wrapper .logo {
      font-size: 14px; } }

@media (max-width: 900px) {
  header .logo {
    margin-left: 8px; }
  header .text-button {
    margin: 0px 4px 0px 2px; }
  header .text-button {
    padding: 14px 4px 21px 4px; }
    header .text-button.dropdown-toggle {
      margin: 0px; }
  header .logo-wrapper {
    margin-right: 0px; }
  header .navbar-nav {
    min-width: 100px;
    margin-right: 8px; }
  header a.dropdown-toggle {
    padding: 0px 4px 21px; } }

.app-switcher {
  position: relative; }
  .app-switcher .dropdown-container {
    width: 278px;
    left: auto;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 8px;
       -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 8px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 8px;
    padding-bottom: 18px; }
  .app-switcher .filter-title {
    padding: 15px 18px 3px; }
  .app-switcher .filter-section {
    margin-bottom: 12px; }
  .app-switcher .option {
    padding: 8.5px 18px !important; }
    .app-switcher .option:hover:not(:disabled) {
      cursor: pointer;
      background-color: rgba(53, 57, 59, 0.07) !important; }
      .app-switcher .option:hover:not(:disabled) .platform-description {
        color: #343A40; }
  .app-switcher .app-switcher-button {
    padding-bottom: 19px;
    padding-top: 16px;
    margin-right: 29px;
    line-height: 1; }
    .app-switcher .app-switcher-button:hover {
      border-color: transparent !important; }

.platform-name-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.platform-name {
  color: #044862;
  font-size: 14px;
  font-weight: 600;
  margin-left: 12px; }

.platform-description {
  color: #878787;
  font-size: 11px;
  padding-top: 8px; }

@media (max-width: 1025px) {
  .app-switcher .dropdown-container {
    width: 150px;
    left: auto; }
  .platform-name {
    font-size: 12px; }
  .platform-description {
    font-size: 10px; } }

@media (max-width: 900px) {
  .app-switcher .app-switcher-button {
    padding-bottom: 10px; } }

.show {
  display: block; }

.hide {
  display: none; }

.text-center {
  text-align: center; }

.siteContent .loginPanel {
  text-align: center;
  max-width: 500px;
  padding: 50px 20px !important; }
  .siteContent .loginPanel .resetLink {
    margin-top: 40px;
    text-align: center; }
    .siteContent .loginPanel .resetLink a {
      font-size: 16px; }
      .siteContent .loginPanel .resetLink a:hover, .siteContent .loginPanel .resetLink a:focus {
        color: #505050;
        border-bottom: 1px solid #505050; }
  .siteContent .loginPanel .backup-info {
    margin-bottom: 0px; }
  .siteContent .loginPanel .crispyForm {
    margin-top: 42px; }
  .siteContent .loginPanel .backup-btn-wrapper {
    margin-top: 0px;
    margin-bottom: 50px; }
    .siteContent .loginPanel .backup-btn-wrapper .btn {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.5px;
      color: #878787;
      text-decoration: none;
      border: none;
      background: none;
      color: #f96c5f;
      border-bottom: 1px solid;
      padding: 11px 0px 4px 0px; }
      .siteContent .loginPanel .backup-btn-wrapper .btn:hover {
        color: #f85142;
        text-decoration: none; }
  .siteContent .loginPanel th, .siteContent .loginPanel td {
    display: block;
    text-align: left; }
    .siteContent .loginPanel th label, .siteContent .loginPanel td label {
      margin-bottom: 0px; }
  .siteContent .loginPanel tr {
    display: inline-grid;
    width: 100%;
    margin-bottom: 15px; }
  .siteContent .loginPanel table {
    width: 100%; }
    .siteContent .loginPanel table input {
      margin: 10px 0px !important; }

.siteContent .enable {
  float: none; }
  .siteContent .enable p {
    margin-bottom: 48px; }

.siteContent #disable {
  float: none;
  margin-top: 15px; }
  .siteContent #disable table {
    margin-left: 0px; }
  .siteContent #disable .crispyForm {
    margin-left: 0px;
    margin-right: 0px; }

.siteContent .panel:not(.loginPanel) .resetLink, .siteContent .loginPanel:not(.loginPanel) .resetLink {
  margin-top: 40px;
  text-align: center; }
  .siteContent .panel:not(.loginPanel) .resetLink a, .siteContent .loginPanel:not(.loginPanel) .resetLink a {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.5em;
    color: white;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    display: block;
    padding: 12px 24px;
    background-color: #f96c5f;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border: none !important;
    cursor: pointer;
    display: inline-block; }
    .siteContent .panel:not(.loginPanel) .resetLink a:hover, .siteContent .loginPanel:not(.loginPanel) .resetLink a:hover {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s;
      color: white; }
    .siteContent .panel:not(.loginPanel) .resetLink a:hover:enabled, .siteContent .loginPanel:not(.loginPanel) .resetLink a:hover:enabled {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
    .siteContent .panel:not(.loginPanel) .resetLink a:hover:disabled, .siteContent .loginPanel:not(.loginPanel) .resetLink a:hover:disabled {
      background-color: #fb9990; }
    .siteContent .panel:not(.loginPanel) .resetLink a:disabled, .siteContent .loginPanel:not(.loginPanel) .resetLink a:disabled {
      background-color: #fb9990;
      cursor: not-allowed; }
    .siteContent .panel:not(.loginPanel) .resetLink a:focus, .siteContent .loginPanel:not(.loginPanel) .resetLink a:focus {
      -webkit-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
      -moz-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
           box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5); }

.siteContent .panel input:not([type="submit"]), .siteContent .loginPanel input:not([type="submit"]) {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: 1px solid #ababab;
  font-size: 1rem;
  font-family: 'Poppins';
  min-width: 100px; }
  .siteContent .panel input:not([type="submit"]):active, .siteContent .loginPanel input:not([type="submit"]):active, .siteContent .panel input:not([type="submit"]):focus, .siteContent .loginPanel input:not([type="submit"]):focus {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .siteContent .panel input:not([type="submit"])::-webkit-input-placeholder, .siteContent .loginPanel input:not([type="submit"])::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .siteContent .panel input:not([type="submit"])::-moz-placeholder, .siteContent .loginPanel input:not([type="submit"])::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .siteContent .panel input:not([type="submit"])::-ms-input-placeholder, .siteContent .loginPanel input:not([type="submit"])::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .siteContent .panel input:not([type="submit"])::placeholder, .siteContent .loginPanel input:not([type="submit"])::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .siteContent .panel input:not([type="submit"]).error, .siteContent .loginPanel input:not([type="submit"]).error {
    border-color: #f96c5f; }

.siteContent .panel input:disabled, .siteContent .loginPanel input:disabled {
  opacity: 0.8;
  cursor: not-allowed; }

.siteContent .panel hr, .siteContent .loginPanel hr {
  border-color: #F0F1F1;
  background-color: #F0F1F1;
  margin: 60px 0px;
  border: none;
  height: 1px; }

.siteContent .panel h1, .siteContent .loginPanel h1 {
  font-weight: 600;
  font-family: 'Poppins';
  margin-bottom: 48px;
  margin-top: 0px; }

.siteContent .panel label, .siteContent .loginPanel label {
  font-family: 'Poppins';
  color: #878787;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: 2px;
  margin-bottom: 12px;
  display: block; }

.siteContent .panel .pull-right, .siteContent .loginPanel .pull-right {
  float: left !important; }

.siteContent .panel .btn, .siteContent .loginPanel .btn {
  padding: 12px 24px;
  font-family: 'Poppins'; }

.siteContent .panel .date, .siteContent .loginPanel .date {
  margin-left: 5px;
  font-weight: 600; }

.siteContent .panel .btn-primary, .siteContent .loginPanel .btn-primary {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  color: white;
  text-transform: uppercase;
  text-align: center;
  width: auto;
  display: block;
  padding: 12px 24px;
  background-color: #f96c5f;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: none !important;
  cursor: pointer;
  display: inline-block;
  float: none; }
  .siteContent .panel .btn-primary:hover, .siteContent .loginPanel .btn-primary:hover {
    background-color: #f85142;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    color: white; }
  .siteContent .panel .btn-primary:hover:enabled, .siteContent .loginPanel .btn-primary:hover:enabled {
    background-color: #f85142;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
  .siteContent .panel .btn-primary:hover:disabled, .siteContent .loginPanel .btn-primary:hover:disabled {
    background-color: #fb9990; }
  .siteContent .panel .btn-primary:disabled, .siteContent .loginPanel .btn-primary:disabled {
    background-color: #fb9990;
    cursor: not-allowed; }
  .siteContent .panel .btn-primary:focus, .siteContent .loginPanel .btn-primary:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
    -moz-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
         box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5); }
  .siteContent .panel .btn-primary.disabled, .siteContent .loginPanel .btn-primary.disabled {
    cursor: not-allowed;
    background-color: #fb9990; }
    .siteContent .panel .btn-primary.disabled:hover, .siteContent .loginPanel .btn-primary.disabled:hover {
      background-color: #fb9990; }

.siteContent .panel .btn-link, .siteContent .loginPanel .btn-link {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #878787;
  padding: 12px 0px 4px 0px;
  text-decoration: none; }
  .siteContent .panel .btn-link:hover, .siteContent .loginPanel .btn-link:hover {
    color: #505050;
    border-bottom: 1px solid #505050;
    text-decoration: none; }

.siteContent .panel .btn-secondary, .siteContent .loginPanel .btn-secondary {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #878787;
  text-decoration: none; }
  .siteContent .panel .btn-secondary:hover, .siteContent .loginPanel .btn-secondary:hover {
    color: #505050;
    text-decoration: none; }

.siteContent .panel .crispyForm, .siteContent .loginPanel .crispyForm {
  margin-left: 20px;
  margin-right: 20px;
  text-align: left; }
  .siteContent .panel .crispyForm input:not([type="submit"]), .siteContent .loginPanel .crispyForm input:not([type="submit"]) {
    padding: 12px 16px;
    color: #676767;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border: 1px solid #ababab;
    font-size: 1rem;
    font-family: 'Poppins';
    min-width: 100px;
    width: 100%; }
    .siteContent .panel .crispyForm input:not([type="submit"]):active, .siteContent .loginPanel .crispyForm input:not([type="submit"]):active, .siteContent .panel .crispyForm input:not([type="submit"]):focus, .siteContent .loginPanel .crispyForm input:not([type="submit"]):focus {
      color: #505050;
      border-color: #505050;
      outline: none; }
    .siteContent .panel .crispyForm input:not([type="submit"])::-webkit-input-placeholder, .siteContent .loginPanel .crispyForm input:not([type="submit"])::-webkit-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .siteContent .panel .crispyForm input:not([type="submit"])::-moz-placeholder, .siteContent .loginPanel .crispyForm input:not([type="submit"])::-moz-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .siteContent .panel .crispyForm input:not([type="submit"])::-ms-input-placeholder, .siteContent .loginPanel .crispyForm input:not([type="submit"])::-ms-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .siteContent .panel .crispyForm input:not([type="submit"])::placeholder, .siteContent .loginPanel .crispyForm input:not([type="submit"])::placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .siteContent .panel .crispyForm input:not([type="submit"]).error, .siteContent .loginPanel .crispyForm input:not([type="submit"]).error {
      border-color: #f96c5f; }
  .siteContent .panel .crispyForm table, .siteContent .loginPanel .crispyForm table {
    margin: 0px auto; }
  .siteContent .panel .crispyForm .back-btn, .siteContent .loginPanel .crispyForm .back-btn {
    margin-right: 20px; }

.siteContent .panel .logo, .siteContent .loginPanel .logo {
  margin-bottom: 0px; }

.siteContent .panel img, .siteContent .loginPanel img {
  max-width: 220px; }

.siteContent .panel .row, .siteContent .loginPanel .row {
  padding-top: 100px; }

.siteContent .panel .instructions, .siteContent .loginPanel .instructions {
  display: block;
  padding-top: 20px; }

.siteContent .panel .col-md-5, .siteContent .loginPanel .col-md-5 {
  text-align: center;
  margin: auto;
  max-width: 500px;
  background-color: white;
  padding: 30px 30px 60px 30px;
  float: none; }

.siteContent .panel .btn-disabled, .siteContent .loginPanel .btn-disabled {
  display: none; }

.siteContent .panel a, .siteContent .loginPanel a {
  color: #878787;
  font-size: 13px; }

.formPage form input:not([type="submit"]):not([type="checkbox"]) {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: 1px solid #ababab;
  font-size: 1rem;
  font-family: 'Poppins';
  width: 100%; }
  .formPage form input:not([type="submit"]):not([type="checkbox"]):active, .formPage form input:not([type="submit"]):not([type="checkbox"]):focus {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .formPage form input:not([type="submit"]):not([type="checkbox"])::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .formPage form input:not([type="submit"]):not([type="checkbox"])::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .formPage form input:not([type="submit"]):not([type="checkbox"])::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .formPage form input:not([type="submit"]):not([type="checkbox"])::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .formPage form input:not([type="submit"]):not([type="checkbox"]).error {
    border-color: #f96c5f; }

.formPage form .buttons {
  text-align: center;
  margin-top: 48px; }

.formPage form .btn-secondary {
  padding-left: 0px !important; }

.formPage form label {
  margin-top: 35px; }

.formPage form .helptext {
  color: #878787;
  font-size: 15px;
  margin-left: 2px;
  padding-top: 3px;
  display: block; }
  .formPage form .helptext ul {
    padding-left: 20px; }

.formPage form ul {
  margin: 0px;
  padding: 0px; }

.formPage form .errorlist {
  position: relative !important;
  color: #f96c5f;
  margin: 0px;
  padding: 0px;
  list-style-type: none; }

.formPage form input[type=checkbox] {
  display: inline-block;
  width: 40px;
  margin-left: 20px; }

.formPage form label[for="id_is_active"], .formPage form label[for="id_is_manager"] {
  display: inline-block; }

.error-bounds {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  text-align: center;
  color: #878787;
  height: 100vh;
  font-size: initial; }
  .error-bounds h2 {
    font-weight: 600; }
  .error-bounds a {
    color: #f96c5f; }
    .error-bounds a:hover {
      text-decoration: underline; }

.tooltip {
  background-color: white;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  right: -webkit-calc(100% + 17px);
  right: -moz-calc(100% + 17px);
  right: calc(100% + 17px);
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
     -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
  padding: 16px 24px 16px 24px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  display: none;
  min-width: 408px;
  color: #676767;
  line-height: 18px;
  z-index: 11; }
  .tooltip svg {
    position: absolute;
    width: 20px;
    height: 20px; }
    .tooltip svg.left {
      top: 50%;
      left: 100%;
      -webkit-transform: translateY(-50%) rotate(90deg);
         -moz-transform: translateY(-50%) rotate(90deg);
          -ms-transform: translateY(-50%) rotate(90deg);
           -o-transform: translateY(-50%) rotate(90deg);
              transform: translateY(-50%) rotate(90deg);
      margin-left: -6px; }
    .tooltip svg.top {
      top: 100%;
      left: 50%;
      -webkit-transform: translateY(-50%) rotate(180deg);
         -moz-transform: translateY(-50%) rotate(180deg);
          -ms-transform: translateY(-50%) rotate(180deg);
           -o-transform: translateY(-50%) rotate(180deg);
              transform: translateY(-50%) rotate(180deg);
      margin-top: 4px;
      margin-left: -8px; }
    .tooltip svg.bottom {
      top: 0px;
      left: 50%;
      -webkit-transform: translateY(-50%);
         -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
           -o-transform: translateY(-50%);
              transform: translateY(-50%);
      margin-top: -4px; }
    .tooltip svg.right {
      -webkit-transform: translateY(-50%) rotate(270deg);
         -moz-transform: translateY(-50%) rotate(270deg);
          -ms-transform: translateY(-50%) rotate(270deg);
           -o-transform: translateY(-50%) rotate(270deg);
              transform: translateY(-50%) rotate(270deg);
      right: 100%;
      top: 50%;
      margin-right: -6px; }
  .tooltip .heading {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    margin-bottom: 16px; }
  .tooltip .message {
    font-size: 13px; }
  .tooltip ul {
    padding-left: 24px;
    margin-bottom: 0px; }
    .tooltip ul li {
      margin-bottom: 12px; }
      .tooltip ul li span:first-child {
        font-weight: 600;
        margin-right: 8px; }
    .tooltip ul li:last-child {
      margin-bottom: 0px; }

.arrow {
  position: absolute;
  border-width: 8px;
  border-style: solid; }
  .arrow.left {
    top: 50%;
    left: 100%;
    margin-top: -8px;
    border-color: transparent transparent transparent white;
    -webkit-filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.1)); }
  .arrow.top {
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border-color: white transparent transparent transparent;
    -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1)); }
  .arrow.bottom {
    bottom: 100%;
    left: 50%;
    margin-left: -8px;
    border-color: transparent transparent  white transparent;
    -webkit-filter: drop-shadow(0px -2px 1px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px -2px 1px rgba(0, 0, 0, 0.1)); }
  .arrow.right {
    right: 100%;
    top: 50%;
    margin-top: -8px;
    border-color: transparent white transparent transparent;
    -webkit-filter: drop-shadow(-2px 0px 1px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(-2px 0px 1px rgba(0, 0, 0, 0.1)); }

@media (max-width: 1025px) {
  .tooltip .heading {
    font-size: 12px;
    margin-bottom: 8px; }
  .tooltip .message {
    font-size: 11px; } }

#agreement .panel, #agreement .siteContent .loginPanel, .siteContent #agreement .loginPanel, #questionnaire .panel, #questionnaire .siteContent .loginPanel, .siteContent #questionnaire .loginPanel {
  text-align: left;
  padding: 60px 68px;
  max-width: 900px;
  margin-bottom: 30px; }

#agreement .intro, #questionnaire .intro {
  margin-bottom: 2rem; }

#agreement .agreement-section, #questionnaire .agreement-section {
  margin-bottom: 2rem; }
  #agreement .agreement-section p:first-of-type, #questionnaire .agreement-section p:first-of-type {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.8rem; }

#agreement .center, #questionnaire .center {
  text-align: center; }

#agreement input[type="submit"], #questionnaire input[type="submit"] {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  color: white;
  text-transform: uppercase;
  text-align: center;
  width: auto;
  display: block;
  padding: 12px 24px;
  background-color: #f96c5f;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: none !important;
  cursor: pointer;
  margin: 0px auto; }
  #agreement input[type="submit"]:hover, #questionnaire input[type="submit"]:hover {
    background-color: #f85142;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    color: white; }
  #agreement input[type="submit"]:hover:enabled, #questionnaire input[type="submit"]:hover:enabled {
    background-color: #f85142;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
  #agreement input[type="submit"]:hover:disabled, #questionnaire input[type="submit"]:hover:disabled {
    background-color: #fb9990; }
  #agreement input[type="submit"]:disabled, #questionnaire input[type="submit"]:disabled {
    background-color: #fb9990;
    cursor: not-allowed; }
  #agreement input[type="submit"]:focus, #questionnaire input[type="submit"]:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
    -moz-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
         box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5); }
  #agreement input[type="submit"]:hover:enabled, #questionnaire input[type="submit"]:hover:enabled {
    background-color: #f85142;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
  #agreement input[type="submit"]:hover:disabled, #questionnaire input[type="submit"]:hover:disabled {
    background-color: #f96c5f; }
  #agreement input[type="submit"]:disabled, #questionnaire input[type="submit"]:disabled {
    cursor: not-allowed; }

#agreement .checkbox input[type=checkbox], #questionnaire .checkbox input[type=checkbox] {
  display: inline-block;
  margin: 0px 10px 0px 0px !important;
  vertical-align: middle; }

#agreement input[type=checkbox] + label, #questionnaire input[type=checkbox] + label {
  cursor: pointer;
  position: relative; }

#agreement input[type=checkbox] + label:before, #questionnaire input[type=checkbox] + label:before {
  font-family: "vigilant-icons";
  content: '\e80c';
  color: #464a4c;
  -webkit-text-stroke-width: 1px;
  font-size: 15px;
  font-weight: normal;
  margin-right: 10px;
  display: inline-block; }

#agreement input[type=checkbox]:checked + label:before, #questionnaire input[type=checkbox]:checked + label:before {
  content: '\e80b'; }

#agreement label {
  display: inline; }

#agreement .form-group {
  display: inline-block; }

#agreement a {
  float: right;
  color: #f96c5f;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid; }
  #agreement a:hover, #agreement a:focus {
    text-decoration: none;
    color: #f85142;
    border-color: #f85142; }

#agreement .center {
  margin-top: 30px; }

#agreement input[type=checkbox] {
  display: inline-block;
  margin: 0px 0px 0px 10px !important; }

#questionnaire label {
  margin-bottom: 15px; }

#questionnaire .help-block {
  color: #c95353;
  margin-top: 2px;
  font-size: 12px; }

#questionnaire textarea {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: 1px solid #ababab;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 60px; }
  #questionnaire textarea:active, #questionnaire textarea:focus {
    color: #505050;
    border-color: #505050;
    outline: none; }
  #questionnaire textarea::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #questionnaire textarea::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #questionnaire textarea::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #questionnaire textarea::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #questionnaire textarea.error {
    border-color: #f96c5f; }

#questionnaire form div {
  margin-bottom: 3rem; }

#jurisdiction-dropdown .dropdown-container {
  left: 0px;
  padding: 0px !important; }

#jurisdiction-dropdown .option {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px;
  color: #676767;
  display: block;
  padding: 14px 16px;
  font-size: 15px;
  text-align: left;
  border: none;
  width: 100%;
  background-color: white; }
  #jurisdiction-dropdown .option:focus, #jurisdiction-dropdown .option:hover:not(:disabled) {
    background-color: #F9FAFC; }
  #jurisdiction-dropdown .option:disabled {
    opacity: 0.4;
    cursor: not-allowed; }
  #jurisdiction-dropdown .option:not(.selected) {
    padding-left: 36px; }
  #jurisdiction-dropdown .option:disabled {
    cursor: not-allowed;
    color: #BDBDBD; }
    #jurisdiction-dropdown .option:disabled:hover {
      background-color: white; }
  #jurisdiction-dropdown .option .material-icons {
    vertical-align: middle;
    color: #f96c5f;
    font-size: 16px;
    margin-right: 4px; }

#jurisdiction-dropdown .dropdownButton span, #jurisdiction-dropdown .match-button.icon-text-button span {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  display: inline-block; }

#jurisdiction-dropdown .options-wrapper {
  max-height: 350px;
  overflow: auto; }

#jurisdiction-dropdown .sidebar-search-container {
  padding: 16px 6px 0px 6px;
  margin: 0px 10px; }
  #jurisdiction-dropdown .sidebar-search-container span {
    width: 100%;
    max-width: none; }
  #jurisdiction-dropdown .sidebar-search-container input {
    width: 100%; }

#jurisdiction-dropdown .jurisdiction-header {
  text-transform: uppercase;
  color: #D1D1D1;
  font-weight: 600;
  letter-spacing: 0.2px;
  padding: 14px 16px 0px 16px;
  font-size: 13px; }

#jurisdiction-dropdown .empty {
  text-align: center;
  padding: 32px;
  color: #D1D1D1;
  font-size: 15px; }

.close {
  background: none;
  border: none;
  outline: none !important;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  text-align: left;
  padding: 0;
  -webkit-transition: 0s;
  -o-transition: 0s;
  -moz-transition: 0s;
  transition: 0s;
  text-decoration: none; }
  .close .material-icons {
    margin: 0px 4px 0px 0px;
    vertical-align: middle;
    color: #D1D1D1;
    font-size: 18px; }
  .close:hover i {
    color: #505050; }

@media (max-width: 1080px) {
  #jurisdiction-dropdown .dropdown-container {
    width: 295px; }
  #jurisdiction-dropdown .jurisdiction-header {
    font-size: 11px; } }

.dropdown {
  position: relative;
  display: inline-block; }

.multiselect .drop-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 6px 16px !important;
  min-height: 48px; }

.multiselect .chip {
  background-color: #e3e5e9;
  margin: 4px 12px 4px 0px; }

.multiselect .dropdownButton, .multiselect .match-button.icon-text-button {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  min-width: 100px; }

.multiselect .dropdown-container {
  max-height: 250px; }
  .multiselect .dropdown-container .option {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .multiselect .dropdown-container .option i {
      font-size: 18px;
      color: #f96c5f;
      margin-right: 4px; }
    .multiselect .dropdown-container .option:not(.selected) {
      padding-left: 38px; }

.dropdownButton, .match-button.icon-text-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%; }
  .dropdownButton span, .match-button.icon-text-button span {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .dropdownButton.text-button, .text-button.match-button.icon-text-button {
    font-size: 13px; }
  .dropdownButton i, .match-button.icon-text-button i {
    color: #BDBDBD; }
  .dropdownButton .clear, .match-button.icon-text-button .clear {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1;
       -moz-box-flex: 0;
        -ms-flex: 0 1;
            flex: 0 1;
    margin-right: 8px; }
    .dropdownButton .clear i, .match-button.icon-text-button .clear i {
      font-size: 20px; }

.dropdown-label {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis; }
  .dropdown-label span {
    display: inline;
    min-width: 0px; }

.drop-wrapper {
  padding: 10px 16px;
  background-color: #ECEDF0;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px; }

.dropdown-container {
  background-color: white;
  font-size: 13px;
  text-align: left;
  position: absolute;
  top: -webkit-calc(100%);
  top: -moz-calc(100%);
  top: calc(100%);
  right: 0px;
  width: 100%;
  z-index: 1;
  -webkit-border-bottom-left-radius: 2px;
     -moz-border-radius-bottomleft: 2px;
          border-bottom-left-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
     -moz-border-radius-bottomright: 2px;
          border-bottom-right-radius: 2px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
     -moz-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
          box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  max-height: 100vh;
  left: 0px;
  margin-top: 0px; }
  .dropdown-container .option {
    color: #676767;
    display: block;
    padding: 14px 16px;
    font-size: 15px;
    text-align: left;
    border: none;
    width: 100%;
    background-color: white; }
    .dropdown-container .option:focus, .dropdown-container .option:hover:not(:disabled) {
      background-color: #F9FAFC; }
    .dropdown-container .option:disabled {
      opacity: 0.4;
      cursor: not-allowed; }
  .dropdown-container .filter-title {
    text-transform: uppercase;
    color: #A5A5A5;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 11px; }

.bordered-dropdown {
  background-color: white;
  border: 1px solid #ababab; }

.filter-dropdown {
  margin: 24px 0px;
  min-width: 230px; }
  .filter-dropdown .lighter-text {
    color: #A5A5A5;
    margin-right: 4px; }
  .filter-dropdown .badge {
    font-size: 10px; }
  .filter-dropdown .icon-filter {
    margin-right: 8px; }
  .filter-dropdown .dropdown-container {
    max-height: none;
    overflow: hidden;
    font-size: 15px;
    min-width: 188px; }
    .filter-dropdown .dropdown-container .options-wrapper {
      max-height: 300px;
      overflow: auto;
      padding: 14px 16px; }
  .filter-dropdown .checkbox {
    display: block;
    position: relative;
    padding: 6px 0px; }
    .filter-dropdown .checkbox input[type=checkbox] + label {
      margin-right: 16px; }
  .filter-dropdown .filter-section.disabled {
    opacity: 0.4;
    pointer-events: none; }
  .filter-dropdown .filter-section:not(:last-of-type) {
    border-bottom: 1px solid #F0F1F1;
    margin-bottom: 14px; }
  .filter-dropdown .global-date-text {
    font-size: 14px;
    padding: 14px 0px; }
  .filter-dropdown .fixed-section {
    padding: 14px 16px;
    border-top: 1px solid #F0F1F1; }
  .filter-dropdown .error-text, .filter-dropdown .formPage form .errorlist, .formPage form .filter-dropdown .errorlist {
    font-size: 12px;
    margin-bottom: 12px; }
  .filter-dropdown .buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .filter-dropdown .icon-text-button {
    font-size: 13px;
    padding-left: 0px; }
  .filter-dropdown .orange-button {
    padding: 8px 16px !important;
    margin: 0px; }

.custom-date-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 16px; }
  .custom-date-wrapper input {
    padding: 12px 16px;
    color: #676767;
    -webkit-border-radius: 2px 2px 0 0;
       -moz-border-radius: 2px 2px 0 0;
            border-radius: 2px 2px 0 0;
    border: none;
    border-bottom: 1px solid #D1D1D1;
    font-size: 1rem;
    background-color: #F9FAFC;
    padding: 10px 6px;
    font-size: 13px;
    max-width: 90px; }
    .custom-date-wrapper input:active:not(:disabled):not([readonly]), .custom-date-wrapper input:focus:not(:disabled):not([readonly]) {
      color: #505050;
      border-color: #505050;
      outline: none; }
    .custom-date-wrapper input::-webkit-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .custom-date-wrapper input::-moz-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .custom-date-wrapper input::-ms-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .custom-date-wrapper input::placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .custom-date-wrapper input.error {
      border-color: #f96c5f; }
    .custom-date-wrapper input:disabled, .custom-date-wrapper input[readonly] {
      opacity: 0.8;
      font-style: italic;
      cursor: not-allowed;
      background: #f2f2f2; }

@media (max-width: 1025px) {
  .dropdown-container .option {
    font-size: 13px;
    padding: 10px 16px; }
  .drop-wrapper {
    padding: 8px 12px; }
  .filter-dropdown {
    min-width: 190px; }
    .filter-dropdown .badge {
      padding: 1px 5px 2px;
      font-size: 9px; }
    .filter-dropdown .dropdownButton .material-icons, .filter-dropdown .match-button.icon-text-button .material-icons {
      font-size: 20px; }
    .filter-dropdown .text-button.dropdownButton, .filter-dropdown .text-button.match-button.icon-text-button {
      font-size: 11px; }
    .filter-dropdown .dropdown-container {
      min-width: 188px;
      font-size: 13px; }
    .filter-dropdown .orange-button, .filter-dropdown .icon-text-button {
      font-size: 12px !important; } }

@media (min-height: 500px) {
  .filter-dropdown .dropdown-container .options-wrapper {
    max-height: 350px; } }

@media (min-height: 600px) {
  .filter-dropdown .dropdown-container .options-wrapper {
    max-height: 425px; } }

.orange-button {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  color: white;
  text-transform: uppercase;
  text-align: center;
  width: auto;
  display: block;
  padding: 12px 24px;
  background-color: #f96c5f;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: none !important;
  cursor: pointer;
  margin: 0px auto; }
  .orange-button:hover {
    background-color: #f85142;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    color: white; }
  .orange-button:hover:enabled {
    background-color: #f85142;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
  .orange-button:hover:disabled {
    background-color: #fb9990; }
  .orange-button:disabled {
    background-color: #fb9990;
    cursor: not-allowed; }
  .orange-button:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
    -moz-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
         box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5); }

.orange-button.loading-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .orange-button.loading-button .loader {
    position: relative;
    height: 20px;
    width: 20px;
    border: 4px solid #fb998f;
    border-top: 4px solid #ffffff;
    margin-right: 4px; }

.add-field-button.text-button {
  color: #f96c5f;
  display: block;
  margin-top: 15px;
  border-bottom: none;
  padding-bottom: 3px;
  border-bottom: 1px solid transparent;
  display: inline-block;
  font-size: 13px; }
  .add-field-button.text-button:hover, .add-field-button.text-button:focus {
    border-bottom: 1px solid #f96c5f; }
  .add-field-button.text-button:disabled {
    color: #fb9990;
    cursor: not-allowed;
    border-bottom-color: transparent; }
  .add-field-button.text-button:hover:not(:disabled) {
    color: #f85142;
    border-bottom: 1px solid #f85142; }
  .add-field-button.text-button:disabled {
    color: #fb9990;
    cursor: not-allowed;
    border-bottom-color: transparent; }

.input-title, .archive-delay-form label {
  color: #878787;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: 2px;
  margin-bottom: 12px; }

.single-input {
  margin-bottom: 20px; }
  .single-input:last-of-type {
    margin-bottom: 0px; }

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

.col-left, .col-right {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  position: relative; }

.col-left {
  padding-right: 3%; }

.col-right {
  padding-left: 3%; }

.search-name-row {
  padding-bottom: 24px; }
  .search-name-row .checkbox input[type=checkbox] + label:before {
    font-size: 18px; }
  .search-name-row .error-text, .search-name-row .formPage form .errorlist, .formPage form .search-name-row .errorlist {
    position: absolute; }

.name-row {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 16px; }

.form-input {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px 2px 0 0;
     -moz-border-radius: 2px 2px 0 0;
          border-radius: 2px 2px 0 0;
  border: none;
  border-bottom: 1px solid #D1D1D1;
  font-size: 1rem;
  background-color: white;
  width: 100%; }
  .form-input:active:not(:disabled):not([readonly]), .form-input:focus:not(:disabled):not([readonly]) {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .form-input::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-input::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-input::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-input::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-input.error {
    border-color: #f96c5f; }
  .form-input:disabled, .form-input[readonly] {
    opacity: 0.8;
    font-style: italic;
    cursor: not-allowed;
    background: #f2f2f2; }
  .form-input:hover:disabled + .tooltip {
    display: block;
    font-size: 13px;
    top: -50%;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%); }
    .form-input:hover:disabled + .tooltip .message {
      display: none; }

.form-heading {
  font-size: 1.5rem;
  font-weight: 600; }
  .form-heading .results-name-row {
    margin: 0px -16px;
    padding: 0px; }

.form-wrapper {
  padding: 32px 36px; }
  .form-wrapper .helptext {
    color: #878787;
    padding-top: 6px;
    margin-left: 2px;
    font-size: 13px; }
  .form-wrapper .error-text, .form-wrapper .formPage form .errorlist, .formPage form .form-wrapper .errorlist {
    font-size: 12px;
    margin-top: 0px; }
  .form-wrapper .header-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .form-wrapper .header-wrapper > span {
      margin-right: 20px;
      text-transform: capitalize; }
  .form-wrapper p {
    font-size: 14px; }
  .form-wrapper .form-info {
    font-size: 14px;
    padding-top: 12px;
    line-height: 22px;
    color: #676767;
    font-weight: normal; }

.search-by-row label {
  color: #878787;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: 2px;
  margin-bottom: 12px; }

.search-by-row .checkbox {
  margin-right: 48px; }

.tooltip-wrapper {
  padding-left: 6px;
  position: relative; }
  .tooltip-wrapper .tooltip {
    text-transform: none;
    padding: 10px 16px 0px 16px;
    min-width: 250px;
    bottom: 190%;
    right: -webkit-calc(100% - 141px);
    right: -moz-calc(100% - 141px);
    right: calc(100% - 141px);
    top: auto;
    -webkit-transform: none;
       -moz-transform: none;
        -ms-transform: none;
         -o-transform: none;
            transform: none; }
    .tooltip-wrapper .tooltip .heading {
      font-size: 13px;
      font-weight: normal;
      line-height: 1.23;
      letter-spacing: normal;
      margin-bottom: 0px; }
  .tooltip-wrapper .material-icons {
    font-size: 16px;
    color: #BDBDBD;
    vertical-align: middle; }
  .tooltip-wrapper .material-icons:hover + .tooltip {
    display: block; }

.search-form .focusable {
  margin-top: 20px; }

.search-form .input-title, .search-form .archive-delay-form label, .archive-delay-form .search-form label {
  color: #878787;
  margin-top: 28px;
  height: 18px; }
  .search-form .input-title.flex, .search-form .archive-delay-form label.flex, .archive-delay-form .search-form label.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }

.search-form .orange-button {
  margin-bottom: 16px; }
  .search-form .orange-button i {
    font-size: 20px;
    margin-right: 4px; }
    .search-form .orange-button i.icon-add-company {
      font-size: 16px;
      margin-right: 16px; }

.search-form .add-field-button {
  font-size: 13px;
  margin-top: 0px; }
  .search-form .add-field-button:disabled:hover + .tooltip {
    top: -50%;
    display: block; }

.search-form .button-wrapper {
  display: inline-block; }
  .search-form .button-wrapper .secondary-button {
    font-size: 13px;
    text-align: left;
    padding: 0px; }
    .search-form .button-wrapper .secondary-button:hover:disabled + .tooltip {
      display: block; }
  .search-form .button-wrapper .tooltip {
    text-transform: none;
    font-weight: 400;
    padding: 10px 16px;
    letter-spacing: normal;
    min-width: 250px;
    top: -webkit-calc(-50% - 8px);
    top: -moz-calc(-50% - 8px);
    top: calc(-50% - 8px);
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
       -moz-transform: translate(-50%, -100%);
        -ms-transform: translate(-50%, -100%);
         -o-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    font-size: 13px; }
    .search-form .button-wrapper .tooltip ul {
      display: none; }

.search-form .input-wrapper {
  position: relative; }
  .search-form .input-wrapper .text-button {
    display: inline;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
         -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    right: -28px;
    color: #BDBDBD;
    margin: 0px;
    padding: 0px;
    border: none;
    padding-bottom: 3px; }
    .search-form .input-wrapper .text-button .material-icons {
      vertical-align: middle; }
    .search-form .input-wrapper .text-button:hover, .search-form .input-wrapper .text-button:focus {
      border: none;
      color: #505050; }
  .search-form .input-wrapper .material-icons {
    font-size: 16px; }

.search-form .checkbox input[type=checkbox]:disabled + label:before {
  color: #D1D1D1;
  cursor: not-allowed; }

.tab-panel input {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px 2px 0 0;
     -moz-border-radius: 2px 2px 0 0;
          border-radius: 2px 2px 0 0;
  border: none;
  border-bottom: 1px solid #D1D1D1;
  font-size: 1rem;
  background-color: #F7F7F8; }
  .tab-panel input:active:not(:disabled):not([readonly]), .tab-panel input:focus:not(:disabled):not([readonly]) {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .tab-panel input::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .tab-panel input::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .tab-panel input::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .tab-panel input::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .tab-panel input.error {
    border-color: #f96c5f; }
  .tab-panel input:disabled, .tab-panel input[readonly] {
    opacity: 0.8;
    font-style: italic;
    cursor: not-allowed;
    background: #f2f2f2; }

@media (max-width: 1025px) {
  .name-row {
    -webkit-box-align: normal;
    -webkit-align-items: normal;
       -moz-box-align: normal;
        -ms-flex-align: normal;
            align-items: normal; }
  .search-form .header-wrapper > button:first-child .material-icons {
    font-size: 18px; }
  .search-form .orange-button {
    font-size: 12px !important;
    margin: 16px auto;
    padding: 10px 20px !important; }
    .search-form .orange-button i {
      font-size: 18px; }
      .search-form .orange-button i.icon-add-company {
        font-size: 14px; }
  .search-form input {
    padding: 10px 14px;
    font-size: 14px; }
  .search-form .text-button {
    font-size: 11px; }
  .search-form .error-text, .search-form .formPage form .errorlist, .formPage form .search-form .errorlist {
    font-size: 11px; }
  .search-form .input-title, .search-form .archive-delay-form label, .archive-delay-form .search-form label {
    font-size: 11px;
    margin-top: 20px; }
  .search-form .button-wrapper .secondary-button {
    font-size: 11px; }
  .search-form .button-wrapper .add-field-button {
    font-size: 12px; }
  .search-form .button-wrapper .tooltip {
    font-size: 12px; }
  .search-form .form-info {
    font-size: 13px; }
  .search-form p {
    font-size: 12px; }
  .search-form .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .search-form .col-right {
    padding-left: 0%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
       -moz-box-flex: 1;
        -ms-flex: auto;
            flex: auto; }
  .search-form .col-left {
    padding-right: 0%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
       -moz-box-flex: 1;
        -ms-flex: auto;
            flex: auto; }
  .tooltip-wrapper .heading {
    font-size: 12px; } }

.source-selection {
  font-size: 13px; }
  .source-selection #allSourcesCheckbox {
    margin-top: 0px;
    margin-bottom: 16px; }
  .source-selection .form-control {
    display: none;
    font-family: 'Poppins';
    width: 100%;
    height: 40px;
    padding: .5rem .75rem;
    font-size: 1em;
    line-height: 1.25;
    color: #464a4c;
    background-color: white;
    background-image: none;
    -moz-background-clip: padding-box;
      -o-background-clip: padding-box;
         background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-moz-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out,-moz-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
  .source-selection .vertical-line {
    width: 1px;
    background-color: #F0F1F1; }
  .source-selection input {
    width: auto !important; }
  .source-selection .sourceSelectionPanel {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .source-selection .sourceColumnContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
    .source-selection .sourceColumnContainer .checkbox input[type=checkbox] + label {
      display: inline; }
    .source-selection .sourceColumnContainer .checkbox input[type=checkbox] + label:before {
      top: 0px; }
  .source-selection .sourceColumn {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
    padding-right: 16px;
    padding-left: 16px; }
  .source-selection .subcategorySection, .source-selection .source {
    margin: 12px 0px 0px 0px; }
  .source-selection .source-wrapper {
    padding: 0px 12px; }
  .source-selection .sourceList {
    padding-left: 24px; }
  .source-selection .categorySelection {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
    margin-left: -32px;
    border-right: 1px solid #ECEDF0; }
    .source-selection .categorySelection .category {
      background: none;
      border: none;
      outline: none !important;
      color: inherit;
      font-size: inherit;
      cursor: pointer;
      text-align: left;
      padding: 0;
      -webkit-transition: 0s;
      -o-transition: 0s;
      -moz-transition: 0s;
      transition: 0s;
      text-decoration: none;
      padding: 16px 32px;
      width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 600; }
      .source-selection .categorySelection .category.active {
        background-color: #ECEDF0;
        border-right: 2px solid #f96c5f;
        color: #505050; }
      .source-selection .categorySelection .category span {
        display: inline-block; }
      .source-selection .categorySelection .category .label {
        -webkit-box-flex: 1;
        -webkit-flex: 1 1;
           -moz-box-flex: 1;
            -ms-flex: 1 1;
                flex: 1 1; }
    .source-selection .categorySelection .count {
      font-size: 10px;
      color: #f96c5f; }
  .source-selection .sources {
    -webkit-box-flex: 3;
    -webkit-flex: 3 1;
       -moz-box-flex: 3;
        -ms-flex: 3 1;
            flex: 3 1; }
    .source-selection .sources .category {
      color: #f96c5f;
      font-weight: 600;
      padding: 12px 16px; }
      .source-selection .sources .category .checkbox input[type=checkbox] + label:before {
        color: #f96c5f; }
  .source-selection .text-button {
    margin: 0px !important;
    color: #878787;
    width: 18px;
    min-height: 20px;
    padding-bottom: 0px;
    padding-left: 2px; }
  .source-selection .text-button:focus {
    color: #505050; }
  .source-selection i.toggle {
    margin-left: 5px;
    cursor: pointer;
    font-size: 6px;
    vertical-align: middle;
    -webkit-text-stroke-width: 0.7px;
    color: inherit; }

@media (max-width: 950px) {
  .source-selection {
    font-size: 12px; }
    .source-selection .source-wrapper {
      padding: 0px; }
    .source-selection .sourceColumnContainer {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
    .source-selection .sourceColumn {
      -webkit-box-flex: 1;
      -webkit-flex: auto;
         -moz-box-flex: 1;
          -ms-flex: auto;
              flex: auto; }
    .source-selection .categorySelection {
      display: none; }
    .source-selection .form-control {
      display: block; } }

.tab-panel {
  background: white;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
     -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
     -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  width: 100%;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  margin-top: 36px; }

.tab-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: normal;
  -webkit-align-items: normal;
     -moz-box-align: normal;
      -ms-flex-align: normal;
          align-items: normal;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
     -moz-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; }

.tab {
  background: none;
  border: none;
  outline: none !important;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  text-align: left;
  padding: 0;
  -webkit-transition: 0s;
  -o-transition: 0s;
  -moz-transition: 0s;
  transition: 0s;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  color: #878787;
  font-size: 13px;
  letter-spacing: 0.5px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  text-align: center;
  border-bottom: 1px solid #F0F1F1;
  border-left: 1px solid #F0F1F1;
  padding: 11px;
  margin: 0px; }
  .tab span {
    padding: 6px 4px 4px 4px;
    display: inline-block;
    border-bottom: 2px solid transparent; }
  .tab:first-of-type {
    border-left: none; }
  .tab.active {
    border-bottom: 1px solid transparent; }
    .tab.active span {
      border-bottom: 2px solid #f96c5f;
      color: #505050; }
  .tab:hover {
    color: #505050; }

.tab-content {
  padding: 28px 32px 48px 32px;
  display: none; }
  .tab-content.active {
    display: block; }

@media (max-width: 1025px) {
  .tab {
    font-size: 11px; }
    .tab .badge {
      font-size: 10px;
      padding: 1px 4px;
      margin-left: 4px; } }

.delete-confirmation {
  font-size: 18px;
  padding: 24px; }
  .delete-confirmation strong {
    text-transform: capitalize; }
  .delete-confirmation .text-button {
    font-size: 13px;
    margin-right: 24px;
    color: #878787;
    display: inline-block; }
  .delete-confirmation .text-button:hover {
    color: #505050;
    border-color: #505050; }
  .delete-confirmation .orange-button {
    display: inline-block;
    margin: 0px; }
  .delete-confirmation .buttons {
    text-align: right;
    margin-top: 48px; }
  .delete-confirmation .confirmation-note {
    margin-top: 12px; }

.search-states {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px 2px 0 0;
     -moz-border-radius: 2px 2px 0 0;
          border-radius: 2px 2px 0 0;
  border: none;
  border-bottom: 1px solid #D1D1D1;
  font-size: 1rem;
  background-color: white;
  width: 100%;
  padding: 4px 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .search-states:active:not(:disabled):not([readonly]), .search-states:focus:not(:disabled):not([readonly]) {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .search-states::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .search-states::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .search-states::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .search-states::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .search-states.error {
    border-color: #f96c5f; }
  .search-states:disabled, .search-states[readonly] {
    opacity: 0.8;
    font-style: italic;
    cursor: not-allowed;
    background: #f2f2f2; }
  .search-states > div {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  .search-states input {
    font-size: 1rem;
    width: auto;
    padding: 8px 14px;
    border: none;
    display: inline-block; }
  .search-states .dropdown-container {
    margin-top: 1px;
    height: 162px; }
  .search-states .toggle {
    color: #BDBDBD;
    padding: 2px 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .search-states .toggle:hover {
      cursor: pointer; }
  .search-states.disabled input {
    background: transparent; }
  .search-states.disabled .selected-states {
    font-style: italic;
    opacity: 0.8;
    pointer-events: none; }

.selected-states {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #dee0e5;
  border: none;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  padding: 6px 8px;
  margin: 2px 2px;
  color: #202020; }
  .selected-states:hover {
    cursor: pointer; }
  .selected-states .close {
    padding-left: 8px; }

.no-states {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  font-size: 15px;
  padding: 20px;
  color: #878787; }

.tab-panel .search-states {
  background-color: #F7F7F8; }
  .tab-panel .search-states.disabled {
    background-color: #f5f5f5; }

.filter-item .search-states {
  background-color: #ECEDF0;
  border: none;
  height: -webkit-calc(100% - 3px);
  height: -moz-calc(100% - 3px);
  height: calc(100% - 3px);
  min-height: 44px; }
  .filter-item .search-states .selected-states {
    font-size: 12px;
    background-color: #dbdde3;
    padding: 4px 8px; }
  .filter-item .search-states #state-input {
    background-color: #ECEDF0;
    padding: 0px 14px;
    margin: 4px 0px;
    max-width: 130px; }
    .filter-item .search-states #state-input::-webkit-input-placeholder {
      color: #878787;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.5px;
      opacity: 1; }
    .filter-item .search-states #state-input::-moz-placeholder {
      color: #878787;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.5px;
      opacity: 1; }
    .filter-item .search-states #state-input::-ms-input-placeholder {
      color: #878787;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.5px;
      opacity: 1; }
    .filter-item .search-states #state-input::placeholder {
      color: #878787;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.5px;
      opacity: 1; }

@media (max-width: 1025px) {
  .search-states li button {
    font-size: 11px; }
  .search-states .selected-states {
    font-size: 12px; }
  .search-header .search-states {
    min-height: 34px; }
  .search-header #state-input::-webkit-input-placeholder {
    font-size: 11px; }
  .search-header #state-input::-moz-placeholder {
    font-size: 11px; }
  .search-header #state-input::-ms-input-placeholder {
    font-size: 11px; }
  .search-header #state-input::placeholder {
    font-size: 11px; } }

.last-name-wrapper input {
  max-width: -webkit-calc(100% - 69px);
  max-width: -moz-calc(100% - 69px);
  max-width: calc(100% - 69px); }

.strict-checkbox {
  position: absolute;
  background: white;
  height: 49px;
  bottom: 12.5px;
  right: 0px;
  border-bottom: 1px solid #D1D1D1;
  -webkit-border-radius: 0px 2px 0px 0px;
     -moz-border-radius: 0px 2px 0px 0px;
          border-radius: 0px 2px 0px 0px; }
  .strict-checkbox input[type=checkbox] + label:before {
    left: 0px;
    top: 38px;
    padding: 8px 0px 8px 26px;
    border-left: 1px solid #D1D1D1;
    color: #BDBDBD; }
  .strict-checkbox input[type=checkbox]:focus + label:before, .strict-checkbox input[type=checkbox]:checked + label:before {
    color: #878787; }
  .strict-checkbox input[type=checkbox] {
    top: 15px;
    left: 23px;
    max-width: none; }
  .strict-checkbox .checkbox input[type=checkbox] + label {
    top: -36px;
    left: 0px;
    margin-right: 0px; }

@media (max-width: 1025px) {
  .last-name-wrapper input {
    max-width: -webkit-calc(100% - 62px);
    max-width: -moz-calc(100% - 62px);
    max-width: calc(100% - 62px); }
  .strict-checkbox {
    height: 42px;
    bottom: 0px; }
    .strict-checkbox input[type=checkbox] + label:before {
      padding: 4px 0px 4px 24px; } }

.toggle-button {
  position: relative;
  background-color: #878787 !important;
  width: 42px;
  height: 22px;
  -webkit-border-radius: 32px;
     -moz-border-radius: 32px;
          border-radius: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0px 4px !important; }
  .toggle-button:disabled {
    opacity: 0.3;
    cursor: not-allowed; }
  .toggle-button.on {
    background-color: #f96c5f !important;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .toggle-button.on .circle {
      left: auto;
      right: 4px; }
  .toggle-button .circle {
    background-color: white;
    display: inline-block;
    height: 12px;
    width: 12px;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    -webkit-transition: left 0.25s;
    -o-transition: left 0.25s;
    -moz-transition: left 0.25s;
    transition: left 0.25s;
    position: absolute;
    left: 4px;
    top: -1px;
    -webkit-transform: translateY(50%);
       -moz-transform: translateY(50%);
        -ms-transform: translateY(50%);
         -o-transform: translateY(50%);
            transform: translateY(50%); }
  .toggle-button span {
    color: white;
    font-size: 9px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block; }

.labeled-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .labeled-toggle .input-title, .labeled-toggle .archive-delay-form label, .archive-delay-form .labeled-toggle label {
    color: #878787;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 2px;
    margin-bottom: 12px;
    margin-right: 6px;
    margin-bottom: 0px; }
  .labeled-toggle .toggle-button:disabled:hover + .tooltip {
    display: block;
    bottom: auto; }

@media (max-width: 1025px) {
  .toggle-button {
    width: 38px;
    height: 18px; }
    .toggle-button .circle {
      height: 10px;
      width: 10px; }
    .toggle-button span {
      font-size: 8px; } }

.radio-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .radio-button span {
    margin-left: 8px; }
  .radio-button input[type=radio] {
    opacity: 0; }
  .radio-button label {
    width: 100%;
    padding: 10px 0px; }
  .radio-button input[type=radio]:disabled + label {
    opacity: 0.4;
    cursor: not-allowed; }
  .radio-button input[type=radio]:focus + label:before {
    background-color: rgba(249, 108, 95, 0.3);
    color: #505050; }
  .radio-button input[type=radio] + label {
    cursor: pointer;
    position: relative;
    display: inline-block; }
    .radio-button input[type=radio] + label span::-moz-selection {
      background-color: transparent; }
    .radio-button input[type=radio] + label span::selection {
      background-color: transparent; }
  .radio-button input[type=radio] + label:before {
    font-family: 'Material Icons';
    content: '\E836';
    color: #A5A5A5;
    padding: 5px;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    display: inline-block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    left: -24px;
    font-size: 18px;
    vertical-align: middle; }
  .radio-button input[type=radio]:checked + label:before {
    font-family: 'Material Icons';
    content: '\E837';
    color: #f96c5f;
    vertical-align: middle; }
  .radio-button input[type=radio]:checked + label {
    color: #505050; }

.expandable-section {
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px; }
  .expandable-section.open {
    border: 1px solid #D1D1D1; }
    .expandable-section.open .expandable-header {
      -webkit-border-radius: 1px 1px 0px 0px;
         -moz-border-radius: 1px 1px 0px 0px;
              border-radius: 1px 1px 0px 0px; }
  .expandable-section i {
    color: #878787; }

.expandable-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 24px;
  width: 100%;
  height: 48px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  background-color: #EDF0F5;
  cursor: pointer; }
  .expandable-header .header-right {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis; }
    .expandable-header .header-right .material-icons {
      font-size: 12px; }
  .expandable-header span {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: normal;
    color: #676767; }

.loading-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 24px;
  color: #A5A5A5;
  font-size: 18px; }

@-webkit-keyframes blink {
  50% {
    opacity: 0.1; } }

@-moz-keyframes blink {
  50% {
    opacity: 0.1; } }

@-o-keyframes blink {
  50% {
    opacity: 0.1; } }

@keyframes blink {
  50% {
    opacity: 0.1; } }

.loader__dot {
  -webkit-animation: 1s blink infinite;
     -moz-animation: 1s blink infinite;
       -o-animation: 1s blink infinite;
          animation: 1s blink infinite;
  padding: 0px 0.5px;
  font-weight: 600; }

.loader__dot:nth-child(2) {
  -webkit-animation-delay: 250ms;
     -moz-animation-delay: 250ms;
       -o-animation-delay: 250ms;
          animation-delay: 250ms; }

.loader__dot:nth-child(3) {
  -webkit-animation-delay: 500ms;
     -moz-animation-delay: 500ms;
       -o-animation-delay: 500ms;
          animation-delay: 500ms; }

.drawer {
  background: white;
  width: 40%;
  height: 100%;
  min-width: 600px;
  top: 0px;
  right: 0px;
  -webkit-box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  z-index: 3;
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

/* slider animation */
.slide-enter {
  -webkit-transform: translateX(100%);
     -moz-transform: translateX(100%);
      -ms-transform: translateX(100%);
       -o-transform: translateX(100%);
          transform: translateX(100%); }

.slide-enter-active {
  -webkit-transform: translateX(0%);
     -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
       -o-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-transition: -webkit-transform 300ms ease-in;
  transition: -webkit-transform 300ms ease-in;
  -o-transition: -o-transform 300ms ease-in;
  -moz-transition: transform 300ms ease-in, -moz-transform 300ms ease-in;
  transition: transform 300ms ease-in;
  transition: transform 300ms ease-in, -webkit-transform 300ms ease-in, -moz-transform 300ms ease-in, -o-transform 300ms ease-in; }

.slide-exit {
  -webkit-transform: translateX(0%);
     -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
       -o-transform: translateX(0%);
          transform: translateX(0%); }

.slide-exit-active {
  -webkit-transform: translateX(100%);
     -moz-transform: translateX(100%);
      -ms-transform: translateX(100%);
       -o-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: -webkit-transform 300ms ease-out;
  transition: -webkit-transform 300ms ease-out;
  -o-transition: -o-transform 300ms ease-out;
  -moz-transition: transform 300ms ease-out, -moz-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out, -moz-transform 300ms ease-out, -o-transform 300ms ease-out; }

.chip {
  background: #ECEDF0;
  padding: 4px 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: none;
  margin-right: 12px;
  margin-bottom: 12px;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  color: #505050;
  font-size: 15px; }
  .chip .material-icons {
    color: #A5A5A5;
    font-size: 18px;
    margin-left: 8px; }
  .chip:hover {
    cursor: pointer;
    background: #e3e5e9;
    color: #505050;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
    .chip:hover .material-icons {
      color: #676767; }

.chip-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.searchable__option {
  color: #676767;
  display: block;
  padding: 14px 16px;
  font-size: 15px;
  text-align: left;
  border: none;
  width: 100%;
  background-color: white; }
  .searchable__option:focus, .searchable__option:hover:not(:disabled) {
    background-color: #F9FAFC; }
  .searchable__option:disabled {
    opacity: 0.4;
    cursor: not-allowed; }
  .searchable__option.searchable__option--is-selected, .searchable__option.searchable__option--is-focused {
    background-color: #F9FAFC; }

.searchable__control {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px 2px 0 0;
     -moz-border-radius: 2px 2px 0 0;
          border-radius: 2px 2px 0 0;
  border: none;
  border-bottom: 1px solid #D1D1D1;
  font-size: 1rem;
  background-color: #F7F7F8; }
  .searchable__control:active:not(:disabled):not([readonly]), .searchable__control:focus:not(:disabled):not([readonly]) {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .searchable__control::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .searchable__control::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .searchable__control::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .searchable__control::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .searchable__control.error {
    border-color: #f96c5f; }
  .searchable__control:disabled, .searchable__control[readonly] {
    opacity: 0.8;
    font-style: italic;
    cursor: not-allowed;
    background: #f2f2f2; }
  .searchable__control.searchable__control--is-focused {
    border-color: #505050; }
  .searchable__control.searchable__control--is-disabled {
    background: #f2f2f2; }

.searchable__menu {
  background-color: white;
  font-size: 13px;
  text-align: left;
  position: absolute;
  top: -webkit-calc(100%);
  top: -moz-calc(100%);
  top: calc(100%);
  right: 0px;
  width: 100%;
  z-index: 1;
  -webkit-border-bottom-left-radius: 2px;
     -moz-border-radius-bottomleft: 2px;
          border-bottom-left-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
     -moz-border-radius-bottomright: 2px;
          border-bottom-right-radius: 2px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
     -moz-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
          box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px; }

.searchable__dropdown-indicator {
  cursor: pointer; }

.searchable--is-disabled {
  font-style: italic;
  opacity: 0.8; }

.form-modal .modal-box {
  background-color: white; }

.form-modal .help-text {
  margin-bottom: 36px;
  color: #676767; }

.form-modal .button-wrapper {
  padding: 0px 0px 32px; }

.slider {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-border-radius: 32px;
     -moz-border-radius: 32px;
          border-radius: 32px;
  border: none;
  background-color: #878787;
  height: 32px;
  position: relative;
  padding: 0px;
  cursor: pointer;
  -webkit-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  transition: background-color 0.25s;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin: 0px 12px;
  letter-spacing: 0.5px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase; }
  .slider:last-of-type {
    margin-right: 0px; }
  .slider .slider-tab {
    -webkit-border-radius: 32px;
       -moz-border-radius: 32px;
            border-radius: 32px;
    background-color: white;
    height: 32px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: margin 0.25s;
    -o-transition: margin 0.25s;
    -moz-transition: margin 0.25s;
    transition: margin 0.25s;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    color: #676767;
    margin-right: 20px;
    padding: 12px 16px; }
  .slider.active {
    background-color: #f96c5f;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .slider.active .slider-tab {
      margin-left: 20px;
      margin-right: 0px; }
  .slider:disabled {
    cursor: not-allowed;
    opacity: 0.6; }

.employee-nav {
  border-bottom: 1px solid #e8eded;
  margin: 0px 24px;
  padding: 4px 0px; }

.employee-nav-link {
  text-transform: uppercase;
  color: #878787;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.5px;
  padding: 4px; }
  .employee-nav-link.active {
    border-bottom: 2px solid #f96c5f;
    color: #202020; }

.employee-details-header {
  margin-top: 34px;
  margin-bottom: 36px;
  margin-left: 16px;
  padding: 0px 12px; }
  .employee-details-header .name-wrapper {
    margin-right: 24px; }

.employee-field-header {
  color: #A5A5A5;
  font-weight: 600;
  font-size: 0.8em;
  text-transform: uppercase;
  margin-right: 8px;
  letter-spacing: 0.2px; }

.employee-field-content {
  font-size: 0.9em;
  margin-right: 24px;
  line-height: 26px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 36px;
  color: #676767; }

.tag-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0px; }
  .tag-container .tag-item {
    font-size: 12px;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    background-color: #ecedf0;
    margin: 5px;
    padding: 4px 8px;
    text-transform: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: normal; }
    .tag-container .tag-item span {
      margin-right: 6px; }
    .tag-container .tag-item i {
      cursor: pointer;
      margin-right: 0;
      font-size: 12px;
      color: #4a4a4a; }

.tag-select-container {
  position: relative; }
  .tag-select-container .icon-text-button:disabled:hover + .tooltip {
    display: block; }
  .tag-select-container .tooltip {
    font-size: 12px;
    width: 100%;
    min-width: auto !important;
    padding: 12px;
    -webkit-transform: translate(-50%, -100%);
       -moz-transform: translate(-50%, -100%);
        -ms-transform: translate(-50%, -100%);
         -o-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    top: 0px !important;
    bottom: auto;
    right: auto;
    left: 50%; }
    .tag-select-container .tooltip .message {
      display: none; }

.tag-select-dropdown {
  position: absolute;
  left: -60px;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 166px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  background: #ffffff;
  padding: 10px 16px;
  font-size: 13px;
  z-index: 1; }
  .tag-select-dropdown .svg-container {
    position: absolute;
    margin-top: -10px;
    left: 50%; }
  .tag-select-dropdown svg.bottom {
    position: absolute;
    left: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-top: -4px;
    margin-left: -10px; }
  .tag-select-dropdown ul {
    font-weight: normal;
    max-height: 150px;
    overflow-y: auto;
    margin: 0;
    padding-bottom: 10px; }
    .tag-select-dropdown ul > .checkbox {
      display: block; }
  .tag-select-dropdown .bottom-container {
    text-align: center;
    margin: 0px 0px 10px 0px; }
    .tag-select-dropdown .bottom-container hr {
      margin-top: 0px;
      margin-bottom: 15px;
      border-color: #F0F1F1;
      background-color: #F0F1F1;
      border: none;
      height: 1px; }
    .tag-select-dropdown .bottom-container a {
      cursor: pointer;
      color: #f96c5f;
      font-weight: 600;
      padding: 2px 5px;
      border-bottom: 1px solid; }

.no-tags {
  text-align: center;
  color: #878787;
  padding: 8px; }
  .no-tags div {
    margin-top: 8px; }
  .no-tags a {
    text-align: center;
    color: #f96c5f;
    text-decoration: underline; }
    .no-tags a:hover {
      color: #f85142; }

.multi-field-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 32px;
  padding-top: 24px;
  border-top: 1px solid #D1D1D1; }
  .multi-field-header .multi-field-label {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #676767; }
  .multi-field-header .close {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 20px;
    margin-right: 8px; }

.employee-form .expandable-section {
  background: white;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  margin-top: 48px; }
  .employee-form .expandable-section.open {
    border: none;
    -webkit-box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.05);
       -moz-box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.05); }

.employee-form .expandable-header {
  background: transparent;
  height: auto;
  padding: 32px; }

.employee-form .expandable-content {
  padding: 0px 32px 36px; }
  .employee-form .expandable-content input {
    padding: 12px 16px;
    color: #676767;
    -webkit-border-radius: 2px 2px 0 0;
       -moz-border-radius: 2px 2px 0 0;
            border-radius: 2px 2px 0 0;
    border: none;
    border-bottom: 1px solid #D1D1D1;
    font-size: 1rem;
    background-color: #F7F7F8; }
    .employee-form .expandable-content input:active:not(:disabled):not([readonly]), .employee-form .expandable-content input:focus:not(:disabled):not([readonly]) {
      color: #505050;
      border-color: #505050;
      outline: none; }
    .employee-form .expandable-content input::-webkit-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .employee-form .expandable-content input::-moz-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .employee-form .expandable-content input::-ms-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .employee-form .expandable-content input::placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .employee-form .expandable-content input.error {
      border-color: #f96c5f; }
    .employee-form .expandable-content input:disabled, .employee-form .expandable-content input[readonly] {
      opacity: 0.8;
      font-style: italic;
      cursor: not-allowed;
      background: #f2f2f2; }

.employee-form .button-wrapper {
  display: inline-block; }

.employee-form .dropdownButton span:not(.clear), .employee-form .match-button.icon-text-button span:not(.clear) {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1; }

.employee-form .employee-name-row {
  padding-bottom: 24px; }
  .employee-form .employee-name-row .checkbox input[type=checkbox] + label:before {
    font-size: 18px; }

.employee-form hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none; }

.employee-form .row {
  margin: 0px -24px; }

.employee-form .col {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  margin: 0px 24px; }

.employee-form .col-no-flex {
  margin: 0px 24px; }

.employee-form .input-wrapper {
  margin-top: 24px; }

.employee-form .add-field-button {
  margin-top: 12px; }

.employee-form .orange-button {
  margin-top: 60px;
  margin-left: 0px; }

.employee-form .dropdown-container {
  max-height: 250px; }

.employee-form #select-wrapper {
  position: relative; }

.employee-form .drop-wrapper {
  border: none;
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px 2px 0 0;
     -moz-border-radius: 2px 2px 0 0;
          border-radius: 2px 2px 0 0;
  border: none;
  border-bottom: 1px solid #D1D1D1;
  font-size: 1rem;
  background-color: #F7F7F8;
  padding: 11px 16px; }
  .employee-form .drop-wrapper:active:not(:disabled):not([readonly]), .employee-form .drop-wrapper:focus:not(:disabled):not([readonly]) {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .employee-form .drop-wrapper::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .employee-form .drop-wrapper::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .employee-form .drop-wrapper::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .employee-form .drop-wrapper::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .employee-form .drop-wrapper.error {
    border-color: #f96c5f; }
  .employee-form .drop-wrapper:disabled, .employee-form .drop-wrapper[readonly] {
    opacity: 0.8;
    font-style: italic;
    cursor: not-allowed;
    background: #f2f2f2; }
  .employee-form .drop-wrapper .dropdownButton, .employee-form .drop-wrapper .match-button.icon-text-button {
    letter-spacing: normal;
    font-size: 1rem; }

@media (max-width: 1025px) {
  .employee-form .col {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
  .employee-form .orange-button {
    margin-top: 16px;
    margin-left: auto; } }

.archived-banner {
  color: #505050;
  font-size: 14px;
  padding: 12px 16px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  background-color: #ECEDF0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 33px 28px 0px 28px; }
  .archived-banner .icon-text-button {
    padding: 0px;
    margin-left: 24px; }
  .archived-banner span {
    display: inline-block; }
  .archived-banner i {
    color: #BDBDBD;
    margin-right: 12px; }

.tooltip-target {
  border-bottom: 1px dotted; }

.settings .sidebar-page-body {
  padding: 48px 80px; }

.settings h1 {
  font-weight: 600;
  font-size: 2em;
  margin-bottom: 48px;
  margin-top: 0px; }

.settings h3 {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 8px;
  margin-bottom: 0px; }

.settings .subheader {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 12px;
  color: #676767; }

.settings .help-text {
  font-size: 14px;
  color: #878787;
  margin-bottom: 12px;
  line-height: 1.5; }
  .settings .help-text a:not(.orange-link) {
    color: #676767; }

.settings .no-padding {
  margin-top: 24px; }
  .settings .no-padding .table-row {
    margin-top: 12px; }
  .settings .no-padding .table-cell:first-of-type {
    padding-left: 0px; }

.settings .additional-labels {
  margin-top: 48px; }

.settings .table-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.settings .table-container {
  width: 100%;
  margin-top: 20px;
  max-width: 800px; }
  .settings .table-container.adding {
    margin-top: 0px; }

.settings .table-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #676767; }
  .settings .table-row.header {
    border-bottom: 1px solid #F0F1F1;
    font-weight: normal;
    text-transform: uppercase;
    padding-bottom: 8px;
    color: #878787;
    margin-bottom: 8px;
    letter-spacing: 0.25px;
    font-size: 14px; }
    .settings .table-row.header:not(.text-center) {
      text-align: left; }
    .settings .table-row.header .tooltip-wrapper .tooltip {
      -webkit-transform: translateX(-50%);
         -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
           -o-transform: translateX(-50%);
              transform: translateX(-50%);
      top: auto; }
  .settings .table-row.disabled {
    color: #A5A5A5; }

.settings .flex-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.settings .table-cell {
  padding: 2px 0px;
  position: relative;
  display: inline-block; }
  .settings .table-cell.text-center, .settings .table-cell.exclude-status {
    min-width: 130px; }
  .settings .table-cell:first-of-type {
    width: 100%;
    padding-left: 16px;
    padding-right: 24px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
    .settings .table-cell:first-of-type.editing {
      padding-left: 0px; }
  .settings .table-cell:last-of-type {
    padding-right: 16px;
    width: 130px; }
  .settings .table-cell input {
    padding: 12px 16px;
    color: #676767;
    -webkit-border-radius: 2px 2px 0 0;
       -moz-border-radius: 2px 2px 0 0;
            border-radius: 2px 2px 0 0;
    border: none;
    border-bottom: 1px solid #D1D1D1;
    font-size: 1rem;
    background-color: white;
    width: 100%;
    max-width: 500px;
    margin: 8px 0px; }
    .settings .table-cell input:active:not(:disabled):not([readonly]), .settings .table-cell input:focus:not(:disabled):not([readonly]) {
      color: #505050;
      border-color: #505050;
      outline: none; }
    .settings .table-cell input::-webkit-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .settings .table-cell input::-moz-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .settings .table-cell input::-ms-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .settings .table-cell input::placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .settings .table-cell input.error {
      border-color: #f96c5f; }
    .settings .table-cell input:disabled, .settings .table-cell input[readonly] {
      opacity: 0.8;
      font-style: italic;
      cursor: not-allowed;
      background: #f2f2f2; }
  .settings .table-cell .extra-buttons {
    position: absolute;
    top: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    min-width: 100px;
    left: 110px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .settings .table-cell .extra-buttons button {
      padding: 2px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .settings .table-cell .extra-buttons button.delete {
        margin-right: 5px; }
      .settings .table-cell .extra-buttons button .material-icons {
        font-size: 18px;
        vertical-align: middle;
        color: #BDBDBD; }
      .settings .table-cell .extra-buttons button:hover .material-icons {
        color: #878787; }
  .settings .table-cell .orange-button {
    font-size: 10px !important;
    padding: 8px 12px !important;
    display: inline-block;
    margin: 0px; }

.settings .error-text, .settings .formPage form .errorlist, .formPage form .settings .errorlist {
  font-size: 12px;
  position: absolute;
  top: -webkit-calc(100% - 12px);
  top: -moz-calc(100% - 12px);
  top: calc(100% - 12px); }

.settings button.delete {
  padding: 8px 16px;
  color: #fa9086; }
  .settings button.delete:hover, .settings button.delete:focus {
    color: #f85142; }
  .settings button.delete:focus {
    border-color: transparent; }

.settings .edit-button {
  padding: 8px 16px;
  margin: 0px auto; }
  .settings .edit-button .material-icons {
    color: #D1D1D1;
    vertical-align: middle;
    font-size: 18px;
    margin-right: 0px; }
  .settings .edit-button:hover:not(:disabled) .material-icons {
    color: #878787; }
  .settings .edit-button:disabled:hover + .tooltip {
    display: block;
    top: -webkit-calc(-100% + 30px);
    top: -moz-calc(-100% + 30px);
    top: calc(-100% + 30px); }

.settings .button-wrapper {
  position: relative; }

.settings .add-field-button {
  color: #f96c5f;
  border-bottom: 1px solid transparent;
  margin-top: 24px;
  padding-bottom: 3px;
  font-size: 15px; }
  .settings .add-field-button:hover:not(:disabled) {
    color: #f85142;
    border-bottom: 1px solid #f85142; }
  .settings .add-field-button:disabled {
    color: #fb9990;
    cursor: not-allowed; }
    .settings .add-field-button:disabled:hover + .tooltip {
      display: block; }

.settings .tooltip {
  -webkit-transform: translate(-50%, -100%);
     -moz-transform: translate(-50%, -100%);
      -ms-transform: translate(-50%, -100%);
       -o-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  left: 50%;
  right: auto;
  min-width: 160px;
  font-size: 13px;
  padding: 12px 16px 12px 16px;
  text-align: center;
  letter-spacing: normal; }
  .settings .tooltip ul {
    display: none;
    min-width: auto; }

.settings .modal-box {
  max-width: 700px; }

.exclude-status {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: 600; }
  .exclude-status i {
    color: #878787;
    margin-right: 4px; }

@media (max-width: 1025px) {
  .settings h1 {
    font-size: 24px; }
  .settings h3 {
    font-size: 15px; }
  .settings .subheader {
    font-size: 18px; }
  .settings .help-text {
    font-size: 13px; }
  .settings .table-row.header {
    font-size: 13px;
    margin-bottom: 8px;
    padding-bottom: 8px; }
  .settings .table-container {
    font-size: 14px;
    margin-top: 24px; }
    .settings .table-container.adding {
      margin: 0px; }
  .settings .table-cell:first-of-type {
    padding-left: 32px;
    padding-right: 18px; }
    .settings .table-cell:first-of-type.editing {
      padding-left: 16px; }
  .settings .table-cell:last-of-type {
    padding-right: 32px; }
  .settings input:not([type="submit"]), .settings textarea {
    font-size: 14px !important; }
  .settings .error-text, .settings .formPage form .errorlist, .formPage form .settings .errorlist {
    font-size: 10px; }
  .settings .add-field-button {
    font-size: 14px; }
  .settings .edit-button {
    padding: 6px 14px; }
  .settings button.delete {
    padding: 6px 14px; }
  .exclude-status {
    font-size: 11px; }
    .exclude-status .input-title, .exclude-status .archive-delay-form label, .archive-delay-form .exclude-status label {
      font-size: 11px; } }

.settings-flex-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0px 48px 0px;
  position: relative; }
  .settings-flex-wrapper .input-title, .settings-flex-wrapper .archive-delay-form label, .archive-delay-form .settings-flex-wrapper label {
    margin: 0px 12px 0px 0px; }
  .settings-flex-wrapper label {
    color: #878787;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 2px;
    margin-bottom: 12px;
    margin: 0px 16px 0px 0px; }
  .settings-flex-wrapper input {
    padding: 12px 16px;
    color: #676767;
    -webkit-border-radius: 2px 2px 0 0;
       -moz-border-radius: 2px 2px 0 0;
            border-radius: 2px 2px 0 0;
    border: none;
    border-bottom: 1px solid #D1D1D1;
    font-size: 1rem;
    background-color: white; }
    .settings-flex-wrapper input:active:not(:disabled):not([readonly]), .settings-flex-wrapper input:focus:not(:disabled):not([readonly]) {
      color: #505050;
      border-color: #505050;
      outline: none; }
    .settings-flex-wrapper input::-webkit-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .settings-flex-wrapper input::-moz-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .settings-flex-wrapper input::-ms-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .settings-flex-wrapper input::placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .settings-flex-wrapper input.error {
      border-color: #f96c5f; }
    .settings-flex-wrapper input:disabled, .settings-flex-wrapper input[readonly] {
      opacity: 0.8;
      font-style: italic;
      cursor: not-allowed;
      background: #f2f2f2; }
  .settings-flex-wrapper .error-text, .settings-flex-wrapper .formPage form .errorlist, .formPage form .settings-flex-wrapper .errorlist {
    top: 100%;
    left: 0px;
    text-align: left; }

.DateInput_input {
  font-weight: normal; }

.global-datefilter .DateInput {
  width: 100%; }

.global-datefilter .DateInput_input__disabled {
  cursor: not-allowed; }

.global-datefilter .text-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  color: #878787;
  margin-left: 18px; }
  .global-datefilter .text-button:hover {
    color: #676767; }
  .global-datefilter .text-button i {
    font-size: 18px;
    margin-right: 4px; }

.global-datefilter .disabled {
  position: relative; }
  .global-datefilter .disabled:hover .tooltip {
    display: block; }

.global-datefilter .orange-button:disabled:hover + .tooltip {
  display: block; }

.global-datefilter .tooltip {
  top: -10px;
  right: auto;
  min-width: auto;
  width: 280px; }

.global-datefilter .confirmation {
  text-align: center;
  margin-top: 24px; }

.archive-delay-form {
  margin: 32px 0px 48px 0px; }
  .archive-delay-form .fom-field {
    margin-bottom: 48px; }
  .archive-delay-form label {
    display: inline-block; }
  .archive-delay-form .material-icons {
    font-size: 24px !important; }
  .archive-delay-form .searchable__control {
    background-color: white; }

.account-settings hr {
  border-color: #F0F1F1;
  background-color: #F0F1F1;
  margin: 60px 0px;
  border: none;
  height: 1px; }

.account-settings .sidebar-page-body {
  max-width: 1140px !important; }

.account-settings .text-button {
  margin-right: 24px; }

.account-settings .settings h1 {
  font-size: 24px;
  font-weight: bold; }

.account-settings .help-text p {
  color: #505050; }

.account-settings .two-factor-auth li {
  list-style: none; }

.account-settings .two-factor-auth p {
  color: #505050; }

.account-settings .two-factor-auth .orange-button {
  margin: 50px auto auto; }

.account-settings .two-factor-auth .confirm-check {
  margin-top: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase; }
  .account-settings .two-factor-auth .confirm-check input {
    width: auto;
    height: 10px;
    margin-right: 10px;
    border: none; }

.account-settings .qr-form-container {
  width: 250px;
  margin: auto; }
  .account-settings .qr-form-container svg {
    z-index: 1;
    display: block;
    position: relative;
    height: auto;
    width: auto;
    margin: auto;
    margin-bottom: 20px; }
  .account-settings .qr-form-container .button-wrapper {
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
       -moz-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly; }
  .account-settings .qr-form-container .orange-button {
    margin: 0px; }

.account-settings i {
  cursor: pointer; }

.account-settings .orange-button {
  margin: 30px auto; }
  .account-settings .orange-button:focus {
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none; }

.account-settings .button-wrapper {
  width: 100%;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .account-settings .button-wrapper .orange-button {
    margin: 0; }

.account-settings .modal-box .button-wrapper {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.account-settings .error-message {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  font-size: 13px;
  color: red;
  font-weight: 600; }
  .account-settings .error-message.generic {
    margin-bottom: 15px; }

.account-settings .input-wrapper {
  margin-bottom: 20px; }
  .account-settings .input-wrapper .error-message {
    font-size: 11px;
    margin: 0px 0px 2px 0px; }
  .account-settings .input-wrapper label {
    display: block;
    color: #878787;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 2px;
    margin-bottom: 12px; }
  .account-settings .input-wrapper.inline input, .account-settings .input-wrapper.inline label {
    display: inline;
    width: auto;
    margin-right: 50px;
    border: none; }

.account-settings input {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: 1px solid #ababab;
  font-size: 1rem;
  width: 100%; }
  .account-settings input:active, .account-settings input:focus {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .account-settings input::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .account-settings input::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .account-settings input::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .account-settings input::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .account-settings input.error {
    border-color: #f96c5f; }

.account-settings p {
  margin-top: 8px;
  font-size: 14px;
  color: #A5A5A5; }

.account-settings li {
  list-style: disc; }

.password-policy {
  margin-top: 50px; }
  .password-policy div {
    margin-bottom: 30px; }
  .password-policy input {
    width: 100px;
    margin: 10px; }
  .password-policy .password-policy-label {
    color: #878787;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 2px;
    margin-bottom: 12px;
    margin-top: 15px; }
  .password-policy button {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.5em;
    color: white;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    display: block;
    padding: 12px 24px;
    background-color: #f96c5f;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border: none !important;
    cursor: pointer;
    margin-top: 20px; }
    .password-policy button:hover {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s;
      color: white; }
    .password-policy button:hover:enabled {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
    .password-policy button:hover:disabled {
      background-color: #fb9990; }
    .password-policy button:disabled {
      background-color: #fb9990;
      cursor: not-allowed; }
    .password-policy button:focus {
      -webkit-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
      -moz-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
           box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5); }

.organization-page .add-user-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-bottom: 50px;
  font-size: 18px;
  color: #505050; }
  .organization-page .add-user-div button {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.5em;
    color: white;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    display: block;
    padding: 12px 24px;
    background-color: #f96c5f;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border: none !important;
    cursor: pointer;
    margin: 0; }
    .organization-page .add-user-div button:hover {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s;
      color: white; }
    .organization-page .add-user-div button:hover:enabled {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
    .organization-page .add-user-div button:hover:disabled {
      background-color: #fb9990; }
    .organization-page .add-user-div button:disabled {
      background-color: #fb9990;
      cursor: not-allowed; }
    .organization-page .add-user-div button:focus {
      -webkit-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
      -moz-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
           box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5); }

.organization-page hr {
  border-color: #F0F1F1;
  background-color: #F0F1F1;
  margin: 60px 0px;
  border: none;
  height: 1px; }

.organization-page h1 {
  margin: 0px 0px 15px 0px; }

.organization-page .panel h1, .organization-page .siteContent .loginPanel h1, .siteContent .organization-page .loginPanel h1 {
  font-size: 38px;
  font-weight: 600; }

.organization-page table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: 'Poppins'; }
  .organization-page table thead {
    border-bottom: #e5e5e5 solid 2px; }
  .organization-page table th {
    text-transform: uppercase;
    font-weight: normal;
    padding: 10px 0px;
    color: #878787; }
    .organization-page table th:not(.text-center) {
      text-align: left; }
  .organization-page table td {
    padding: 20px 0px;
    border-top: solid 1px #ebebeb; }
  .organization-page table tr:first-child td {
    border-top: 0px; }
  .organization-page table .icon-trash {
    color: #fa9086;
    font-size: 20px; }
    .organization-page table .icon-trash:hover, .organization-page table .icon-trash:focus {
      color: #f85142; }
  .organization-page table a:focus .icon-pencil {
    color: #202020; }
  .organization-page table a:focus .icon-trash {
    color: #f85142; }
  .organization-page table .icon-pencil {
    color: #878787;
    font-size: 20px; }
    .organization-page table .icon-pencil:hover, .organization-page table .icon-pencil:focus {
      color: #202020; }

.organization-page .subheader {
  font-weight: normal;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 50px; }

#reports .form-info {
  margin-bottom: 40px;
  line-height: 24px; }

#reports hr:last-of-type {
  margin-top: 12px; }

#reports input {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: 1px solid #ababab;
  font-size: 1rem;
  width: 100%; }
  #reports input:active, #reports input:focus {
    color: #505050;
    border-color: #505050;
    outline: none; }
  #reports input::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #reports input::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #reports input::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #reports input::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #reports input.error {
    border-color: #f96c5f; }
  #reports input[type="submit"] {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.5em;
    color: white;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    display: block;
    padding: 12px 24px;
    background-color: #f96c5f;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border: none !important;
    cursor: pointer;
    margin: auto;
    margin-top: 35px; }
    #reports input[type="submit"]:hover {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s;
      color: white; }
    #reports input[type="submit"]:hover:enabled {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
    #reports input[type="submit"]:hover:disabled {
      background-color: #fb9990; }
    #reports input[type="submit"]:disabled {
      background-color: #fb9990;
      cursor: not-allowed; }
    #reports input[type="submit"]:focus {
      -webkit-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
      -moz-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
           box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5); }

#reports .focusable {
  width: 100%; }
  #reports .focusable.error input {
    border-color: #f96c5f !important; }

.past-reports-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  border: 0px;
  background: none;
  padding: 0px;
  color: #505050;
  font-size: 16px;
  font-family: 'Poppins';
  cursor: pointer; }
  .past-reports-header .material-icons {
    margin-bottom: 48px;
    font-size: 32px; }

.no-reports {
  text-align: center;
  color: #D1D1D1;
  padding: 50px 0px;
  font-size: 18px; }

.past-reports i {
  color: #D1D1D1;
  vertical-align: middle;
  margin-right: 4px;
  font-size: 18px; }

.past-reports table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 35px;
  color: #878787; }

.past-reports tbody tr:first-child td {
  padding-top: 24px; }

.past-reports td {
  text-align: center;
  padding: 6px; }
  .past-reports td .icon-trash {
    color: #fa9086; }
  .past-reports td button:disabled {
    cursor: not-allowed; }
    .past-reports td button:disabled .icon-trash {
      opacity: 0.3; }
  .past-reports td button:hover:disabled .icon-trash {
    opacity: 0.3;
    color: #fa9086; }
  .past-reports td button:hover .icon-trash {
    color: #f85142; }

.past-reports thead {
  border-bottom: 1px solid #F0F1F1; }
  .past-reports thead th {
    font-weight: normal;
    text-transform: uppercase;
    padding-bottom: 10px; }

.past-reports a {
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #676767;
  border: none;
  padding: 12px 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: none;
  cursor: pointer;
  font-size: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }
  .past-reports a:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .past-reports a .material-icons {
    color: #D1D1D1;
    color: #D1D1D1;
    vertical-align: middle;
    margin-right: 4px;
    font-size: 18px; }
  .past-reports a:hover:not(:disabled), .past-reports a:focus, .past-reports a a:hover:not(:disabled), .past-reports a a:focus {
    color: #202020; }
    .past-reports a:hover:not(:disabled) .material-icons, .past-reports a:focus .material-icons, .past-reports a a:hover:not(:disabled) .material-icons, .past-reports a a:focus .material-icons {
      color: #878787; }

.report-progress-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .report-progress-container span {
    margin-left: 10px; }

.report-progress-bar-container {
  background-color: #D1D1D1;
  -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
          border-radius: 10px;
  width: 80px;
  height: 10px; }
  .report-progress-bar-container .report-progress-bar {
    background-color: #f96c5f;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    height: 100%;
    -webkit-transition: width 2s;
    -o-transition: width 2s;
    -moz-transition: width 2s;
    transition: width 2s; }

.generation-progress {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 64px;
  min-height: 400px; }
  .generation-progress .generation-header {
    font-size: 1.2em;
    line-height: 28px; }
  .generation-progress .generation-load-container {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 21px;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .generation-progress .generation-load-container .generation-load-bar {
      width: 85%;
      height: 15px;
      -webkit-border-radius: 15px;
         -moz-border-radius: 15px;
              border-radius: 15px;
      background-color: #D1D1D1; }
    .generation-progress .generation-load-container .generation-load-progress {
      height: 100%;
      background-color: #f96c5f;
      -webkit-border-radius: 15px;
         -moz-border-radius: 15px;
              border-radius: 15px;
      -webkit-transition: width 1.5s;
      -o-transition: width 1.5s;
      -moz-transition: width 1.5s;
      transition: width 1.5s; }
    .generation-progress .generation-load-container span {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1;
         -moz-box-flex: 1;
          -ms-flex: 1 1;
              flex: 1 1;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 1.5em; }
  .generation-progress .generation-loaded-text {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 21px;
    text-align: center; }
    .generation-progress .generation-loaded-text a {
      font-size: inherit;
      background: none;
      color: #f96c5f;
      border: none;
      padding: 0; }
  .generation-progress a {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.5em;
    color: white;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    display: block;
    padding: 12px 24px;
    background-color: #f96c5f;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border: none !important;
    cursor: pointer;
    -webkit-transition: opacity 1.5s;
    -o-transition: opacity 1.5s;
    -moz-transition: opacity 1.5s;
    transition: opacity 1.5s; }
    .generation-progress a:hover {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s;
      color: white; }
    .generation-progress a:hover:enabled {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
    .generation-progress a:hover:disabled {
      background-color: #fb9990; }
    .generation-progress a:disabled {
      background-color: #fb9990;
      cursor: not-allowed; }
    .generation-progress a:focus {
      -webkit-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
      -moz-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
           box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5); }
  .generation-progress a.disabled {
    opacity: 0.5;
    cursor: not-allowed; }
    .generation-progress a.disabled:hover {
      background-color: #f96c5f;
      opacity: 0.5; }

@media (max-width: 1025px) {
  #reports h1 {
    font-size: 24px; }
  #reports .form-info {
    font-size: 13px;
    line-height: normal;
    margin-bottom: 24px; }
  #reports .panel label, #reports .siteContent .loginPanel label, .siteContent #reports .loginPanel label {
    font-size: 11px !important; }
  #reports .panel .btn-primary, #reports .siteContent .loginPanel .btn-primary, .siteContent #reports .loginPanel .btn-primary {
    font-size: 11px !important; }
  #reports input {
    font-size: 14px; }
  #reports #autoreports .dropdownButton, #reports #autoreports .match-button.icon-text-button {
    font-size: 14px; }
  #reports #autoreports .dropdown-container .option {
    font-size: 14px; }
  #reports #autoreports p {
    font-size: 13px; }
  .past-reports-header .material-icons {
    font-size: 28px; }
  .past-reports table {
    font-size: 14px; }
  .past-reports i {
    font-size: 16px; } }

#manual-reports .wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0px -24px; }
  #manual-reports .wrapper label, #manual-reports .wrapper .error-text, #manual-reports .wrapper .formPage form .errorlist, .formPage form #manual-reports .wrapper .errorlist {
    width: 100%;
    display: inline-block;
    margin-left: 24px;
    margin-right: 24px; }

.DateRangePicker_picker {
  width: 100px; }

.DateRangePicker {
  width: 100%; }

.DateRangePickerInput {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: transparent;
  margin: 0px -24px; }

.CalendarMonth_caption strong {
  font-weight: 600; }

.CalendarDay__default:hover {
  background: #F0F1F1;
  border-color: #D1D1D1; }

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background-color: #676767;
  background: #676767;
  border: 1px solid #505050; }

.DayPickerNavigation_button__default {
  border: none;
  padding: 9px 20px;
  z-index: 0; }
  .DayPickerNavigation_button__default:hover, .DayPickerNavigation_button__default:focus {
    border: none; }
    .DayPickerNavigation_button__default:hover svg, .DayPickerNavigation_button__default:focus svg {
      fill: #202020; }

.DayPickerNavigation_button__default:first-of-type svg {
  left: 0px; }

.CalendarDay__selected_span {
  background: #BDBDBD;
  border: 1px solid #878787;
  color: white; }
  .CalendarDay__selected_span:hover {
    background: #878787;
    border: 1px solid #676767; }

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  color: inherit;
  background: #F0F1F1;
  border-color: #D1D1D1; }

.DateRangePickerInput_arrow_svg {
  display: none; }

.focusable:focus-within label {
  color: #676767; }

.reports-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 35px; }
  .reports-row .wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0px -24px; }
    .reports-row .wrapper label, .reports-row .wrapper .error-text, .reports-row .wrapper .formPage form .errorlist, .formPage form .reports-row .wrapper .errorlist {
      width: 100%;
      display: inline-block;
      margin-left: 24px;
      margin-right: 24px; }
  .reports-row .focusable {
    width: 100%; }
  .reports-row .DateInput {
    width: 100%;
    margin: 0px 24px; }

.DayPickerNavigation_svg__horizontal {
  pointer-events: none; }

.CalendarMonth_table {
  font-family: 'Poppins'; }

@media (max-width: 1025px) {
  .CalendarMonth_caption {
    font-size: 16px; }
  .DayPickerNavigation_button__horizontalDefault {
    top: 24px; } }

#autoreports .input-wrapper {
  position: relative;
  margin-bottom: 32px; }
  #autoreports .input-wrapper:last-child {
    margin-bottom: 0px; }

#autoreports .text-button {
  color: #f96c5f;
  display: block;
  margin-top: 15px;
  border-bottom: none;
  padding-bottom: 3px;
  border-bottom: 1px solid transparent;
  font-size: 13px; }
  #autoreports .text-button:hover, #autoreports .text-button:focus {
    border-bottom: 1px solid #f96c5f; }
  #autoreports .text-button:disabled {
    color: #fb9990;
    cursor: not-allowed;
    border-bottom-color: transparent; }
  #autoreports .text-button.remove {
    display: inline;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
         -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    right: -28px;
    color: #BDBDBD;
    margin: 0px;
    padding: 0px;
    border: none; }
    #autoreports .text-button.remove .material-icons {
      vertical-align: middle; }
    #autoreports .text-button.remove:hover, #autoreports .text-button.remove:focus {
      border: none;
      color: #505050; }
    #autoreports .text-button.remove .material-icons {
      font-size: 16px; }

#autoreports p {
  color: #878787;
  font-size: 15px;
  margin-left: 2px;
  padding-top: 3px;
  display: block; }

#autoreports .dropdown {
  font-size: 1rem;
  position: relative;
  width: 100%; }

#autoreports .dropdown-wrapper {
  margin-bottom: 35px; }
  #autoreports .dropdown-wrapper:focus-within {
    color: #676767; }

#autoreports .dropdownButton, #autoreports .match-button.icon-text-button {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: 1px solid #ababab;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 0px;
  background-color: white;
  width: 100%;
  text-transform: capitalize;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  #autoreports .dropdownButton:active, #autoreports .match-button.icon-text-button:active, #autoreports .dropdownButton:focus, #autoreports .match-button.icon-text-button:focus {
    color: #505050;
    border-color: #505050;
    outline: none; }
  #autoreports .dropdownButton::-webkit-input-placeholder, #autoreports .match-button.icon-text-button::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #autoreports .dropdownButton::-moz-placeholder, #autoreports .match-button.icon-text-button::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #autoreports .dropdownButton::-ms-input-placeholder, #autoreports .match-button.icon-text-button::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #autoreports .dropdownButton::placeholder, #autoreports .match-button.icon-text-button::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  #autoreports .dropdownButton.error, #autoreports .error.match-button.icon-text-button {
    border-color: #f96c5f; }
  #autoreports .dropdownButton .material-icons, #autoreports .match-button.icon-text-button .material-icons {
    vertical-align: middle;
    float: right;
    color: #BDBDBD; }

#autoreports .dropdown-container {
  background-color: white;
  font-size: 13px;
  text-align: left;
  position: absolute;
  top: -webkit-calc(100%);
  top: -moz-calc(100%);
  top: calc(100%);
  right: 0px;
  width: 100%;
  z-index: 1;
  -webkit-border-bottom-left-radius: 2px;
     -moz-border-radius-bottomleft: 2px;
          border-bottom-left-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
     -moz-border-radius-bottomright: 2px;
          border-bottom-right-radius: 2px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
     -moz-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
          box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px; }
  #autoreports .dropdown-container .option {
    color: #676767;
    display: block;
    padding: 14px 16px;
    font-size: 15px;
    text-align: left;
    border: none;
    width: 100%;
    background-color: white;
    font-size: 1rem;
    text-transform: capitalize; }
    #autoreports .dropdown-container .option:focus, #autoreports .dropdown-container .option:hover:not(:disabled) {
      background-color: #F9FAFC; }
    #autoreports .dropdown-container .option:disabled {
      opacity: 0.4;
      cursor: not-allowed; }

#autoreports .success-message {
  text-align: center;
  color: #878787;
  margin-top: 16px; }

.dashboard-wrapper {
  padding: 24px 0px 3px 0px;
  min-height: 100vh; }
  .dashboard-wrapper .ReactVirtualized__Grid__innerScrollContainer {
    min-height: 100vh; }
  .dashboard-wrapper .noResults .orange-button {
    margin-top: 32px; }

.dashboard-record-container, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper {
  background-color: white;
  border: solid 1px #e8eded;
  margin: 16px 32px 0px 32px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px; }
  .dashboard-record-container .flex-wrapper, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .flex-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .dashboard-record-container.expanded, .dashboard-wrapper + .modal-container .expanded.dashboard-styles-wrapper {
    -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15); }
  .dashboard-record-container .record-icon, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .record-icon {
    background: #FAFAFA;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 8px 4px;
    font-size: 26px; }
    .dashboard-record-container .record-icon > div, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .record-icon > div {
      position: relative; }
    .dashboard-record-container .record-icon i:before, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .record-icon i:before {
      width: auto; }
    .dashboard-record-container .record-icon i:hover + .tooltip, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .record-icon i:hover + .tooltip {
      display: block;
      background-color: white;
      top: 50%;
      left: -webkit-calc(100% + 10px);
      left: -moz-calc(100% + 10px);
      left: calc(100% + 10px);
      font-size: 11px;
      min-width: auto;
      right: auto;
      padding: 4px 8px;
      line-height: normal; }
      .dashboard-record-container .record-icon i:hover + .tooltip ul, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .record-icon i:hover + .tooltip ul {
        display: none; }
      .dashboard-record-container .record-icon i:hover + .tooltip svg.right, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .record-icon i:hover + .tooltip svg.right {
        margin-right: -4px;
        width: 12px;
        height: 12px; }
      .dashboard-record-container .record-icon i:hover + .tooltip .arrow.right, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .record-icon i:hover + .tooltip .arrow.right {
        margin-top: -5px;
        border-width: 5px; }
  .dashboard-record-container .dashboard-record, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record {
    border-bottom: none;
    padding: 0px 0px 30px 0px; }
  .dashboard-record-container.last, .dashboard-wrapper + .modal-container .last.dashboard-styles-wrapper {
    border-bottom: solid 1px #EEE;
    -webkit-border-bottom-right-radius: 2px;
       -moz-border-radius-bottomright: 2px;
            border-bottom-right-radius: 2px;
    -webkit-border-bottom-left-radius: 2px;
       -moz-border-radius-bottomleft: 2px;
            border-bottom-left-radius: 2px; }
    .dashboard-record-container.last .dashboard-record, .dashboard-wrapper + .modal-container .last.dashboard-styles-wrapper .dashboard-record {
      border-bottom: none; }

.dashboard-record-wrapper {
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  min-width: 10px; }

.dashboard-record-container .dropdownButton, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dropdownButton, .dashboard-record-container .match-button.icon-text-button, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .match-button.icon-text-button, .results-body .dropdownButton, .results-body .match-button.icon-text-button, .results-container .dropdownButton, .results-container .match-button.icon-text-button {
  background-color: #ECEDF0;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  padding: 7px 8px;
  font-weight: 600;
  text-transform: uppercase; }
  .dashboard-record-container .dropdownButton .dropdown-text, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dropdownButton .dropdown-text, .dashboard-record-container .match-button.icon-text-button .dropdown-text, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .match-button.icon-text-button .dropdown-text, .results-body .dropdownButton .dropdown-text, .results-body .match-button.icon-text-button .dropdown-text, .results-container .dropdownButton .dropdown-text, .results-container .match-button.icon-text-button .dropdown-text {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    display: inline-block;
    max-width: 90px; }
  .dashboard-record-container .dropdownButton.open, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dropdownButton.open, .dashboard-record-container .open.match-button.icon-text-button, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .open.match-button.icon-text-button, .results-body .dropdownButton.open, .results-body .open.match-button.icon-text-button, .results-container .dropdownButton.open, .results-container .open.match-button.icon-text-button {
    -webkit-border-bottom-right-radius: 0px;
       -moz-border-radius-bottomright: 0px;
            border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
       -moz-border-radius-bottomleft: 0px;
            border-bottom-left-radius: 0px; }
  .dashboard-record-container .dropdownButton .material-icons, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dropdownButton .material-icons, .dashboard-record-container .match-button.icon-text-button .material-icons, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .match-button.icon-text-button .material-icons, .results-body .dropdownButton .material-icons, .results-body .match-button.icon-text-button .material-icons, .results-container .dropdownButton .material-icons, .results-container .match-button.icon-text-button .material-icons {
    color: #BDBDBD;
    font-size: 18px; }

.dashboard-record-container .dropdown-container, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dropdown-container, .results-body .dropdown-container, .results-container .dropdown-container {
  word-break: break-all;
  -webkit-hyphens: auto;
     -moz-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  border-color: #F0F1F1;
  min-width: 100%;
  max-height: 200px; }
  .dashboard-record-container .dropdown-container .option, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dropdown-container .option, .results-body .dropdown-container .option, .results-container .dropdown-container .option {
    word-break: break-word;
    font-size: 12px;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .dashboard-record-container .dropdown-container .option:not(.selected), .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dropdown-container .option:not(.selected), .results-body .dropdown-container .option:not(.selected), .results-container .dropdown-container .option:not(.selected) {
      padding-left: 27px; }
    .dashboard-record-container .dropdown-container .option .material-icons, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dropdown-container .option .material-icons, .results-body .dropdown-container .option .material-icons, .results-container .dropdown-container .option .material-icons {
      vertical-align: middle;
      color: #f96c5f;
      font-size: 14px;
      margin-right: 4px; }

.dashboard-record {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 0px 20px 0px;
  border-bottom: solid 1px #ebebeb;
  -webkit-hyphens: auto;
     -moz-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
  font-size: 12px; }
  .dashboard-record .checkbox input[type=checkbox] + label:before {
    position: inherit;
    left: auto;
    top: auto;
    color: #BDBDBD; }
  .dashboard-record .checkbox input[type=checkbox]:hover + label:before {
    color: #878787; }
  .dashboard-record .checkbox input[type=checkbox]:focus + label:before {
    color: #878787; }
  .dashboard-record .checkbox input[type=checkbox]:checked + label:before {
    color: #676767; }
  .dashboard-record .checkbox input[type=checkbox]:checked + label {
    color: #676767; }

.dashboard-cell {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  padding: 0 10px; }
  .dashboard-cell.fixedWidth {
    min-width: 148px;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
       -moz-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0; }
    .dashboard-cell.fixedWidth.jurisdiction {
      min-width: 111px; }
    .dashboard-cell.fixedWidth button {
      font-size: 11px; }
    .dashboard-cell.fixedWidth .buttons-wrapper .text-button {
      font-size: 13px; }
  .dashboard-cell .record-cell i {
    font-size: 14px;
    color: #BDBDBD;
    margin-left: 4px;
    vertical-align: text-bottom; }
  .dashboard-cell a {
    color: #393939;
    border-bottom: 3px solid #feddda;
    text-decoration: none; }
    .dashboard-cell a:hover {
      text-decoration: none;
      border-bottom-color: #fcb0a9;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      -moz-transition: 0.3s;
      transition: 0.3s; }

.dashboard-cell, .record-checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .dashboard-cell a, .record-checkbox a {
    color: #393939;
    border-bottom: 3px solid #feddda;
    text-decoration: none;
    line-height: 1.6em; }
    .dashboard-cell a:hover, .record-checkbox a:hover {
      text-decoration: none;
      border-bottom-color: #fcb0a9;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      -moz-transition: 0.3s;
      transition: 0.3s; }

.record-checkbox {
  padding: 0 10px; }
  .record-checkbox.empty {
    width: 33px; }

.record-checkbox .checkbox {
  position: relative; }
  .record-checkbox .checkbox input[type=checkbox] + label {
    left: auto;
    margin-right: 0px; }

.dashboard-results-container {
  position: relative; }

.dashboard-fixed-container {
  position: fixed;
  z-index: 5;
  top: 0; }

@media (min-width: 1500px) {
  .dashboard-record .dashboard-cell.fixedWidth:not(.notes):not(.send) {
    min-width: 148px; }
    .dashboard-record .dashboard-cell.fixedWidth:not(.notes):not(.send) .dropdownButton .dropdown-text, .dashboard-record .dashboard-cell.fixedWidth:not(.notes):not(.send) .match-button.icon-text-button .dropdown-text {
      max-width: 115px; } }

@media (max-width: 1400px) {
  .dashboard-cell[data-sort='date'], .dashboard-cell[data-cell='date'] {
    max-width: 85px; }
  .dashboard-cell[data-sort='amended'], .dashboard-cell[data-cell='amended'] {
    max-width: 85px; }
  .dashboard-cell[data-sort='amount'], .dashboard-cell[data-cell='amount'] {
    max-width: 105px; } }

@media (max-width: 1345px) {
  .dashboard-cell.fixedWidth {
    min-width: 118px; }
  .dashboard-record .dropdownButton .dropdown-text, .dashboard-record .match-button.icon-text-button .dropdown-text {
    max-width: 62px; }
  .dashboard-wrapper .dashboard-cell.fixedWidth.jurisdiction {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
        -ms-flex-negative: 1;
            flex-shrink: 1;
    min-width: auto;
    max-width: 104px; } }

@media (max-width: 1195px) {
  .dashboard-record-container, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper {
    margin: 16px 24px 0px 24px; }
  .dashboard-wrapper .schema .dashboard-record {
    font-size: 10px; }
  .dashboard-wrapper .schema .hidden-span {
    padding: 0px 8px; }
  .dashboard-wrapper .dashboard-record {
    font-size: 12px; }
    .dashboard-wrapper .dashboard-record .dashboard-cell {
      padding: 0px 8px; }
  .dashboard-wrapper .record-checkbox {
    padding: 0 8px; }
    .dashboard-wrapper .record-checkbox.empty {
      width: 30px; }
  .dashboard-record-container.schema .dashboard-record, .dashboard-wrapper + .modal-container .schema.dashboard-styles-wrapper .dashboard-record {
    font-size: 13px; }
  .dashboard-cell.fixedWidth {
    min-width: 118px; }
    .dashboard-cell.fixedWidth .dropdownButton .dropdown-text, .dashboard-cell.fixedWidth .match-button.icon-text-button .dropdown-text {
      max-width: 62px; }
  .dashboard-cell.fixedWidth.jurisdiction {
    min-width: 75px; } }

@media (max-width: 1080px) {
  .dashboard-record-container .dashboard-record, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record {
    font-size: 11px; }
    .dashboard-record-container .dashboard-record .dropdownButton, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dropdownButton, .dashboard-record-container .dashboard-record .match-button.icon-text-button, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .match-button.icon-text-button {
      padding: 4px 8px; }
      .dashboard-record-container .dashboard-record .dropdownButton .material-icons, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dropdownButton .material-icons, .dashboard-record-container .dashboard-record .match-button.icon-text-button .material-icons, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .match-button.icon-text-button .material-icons {
        font-size: 16px; }
    .dashboard-record-container .dashboard-record .dashboard-cell.fixedWidth:not(.jurisdiction), .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dashboard-cell.fixedWidth:not(.jurisdiction) {
      min-width: 107px; }
      .dashboard-record-container .dashboard-record .dashboard-cell.fixedWidth:not(.jurisdiction) .dropdownButton .dropdown-text, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dashboard-cell.fixedWidth:not(.jurisdiction) .dropdownButton .dropdown-text, .dashboard-record-container .dashboard-record .dashboard-cell.fixedWidth:not(.jurisdiction) .match-button.icon-text-button .dropdown-text, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dashboard-cell.fixedWidth:not(.jurisdiction) .match-button.icon-text-button .dropdown-text {
        max-width: 57px;
        font-size: 10px; }
      .dashboard-record-container .dashboard-record .dashboard-cell.fixedWidth:not(.jurisdiction) .dropdown-container .option, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dashboard-cell.fixedWidth:not(.jurisdiction) .dropdown-container .option {
        font-size: 10px; } }

@media (max-width: 1025px) {
  .dashboard-record-container .record-icon, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .record-icon {
    font-size: 22px;
    padding: 4px; }
  .dashboard-record-container .schema .dashboard-cell .tooltip, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .schema .dashboard-cell .tooltip {
    padding: 4px 6px; }
    .dashboard-record-container .schema .dashboard-cell .tooltip.top, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .schema .dashboard-cell .tooltip.top {
      top: -10px; }
  .dashboard-record-container .schema .hidden-span, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .schema .hidden-span {
    padding: 0px 6px; }
  .dashboard-record-container .dashboard-record .record-checkbox.empty, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .record-checkbox.empty {
    width: 34px; }
  .dashboard-record-container .dashboard-record .dashboard-cell, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dashboard-cell {
    padding: 0px 6px; }
    .dashboard-record-container .dashboard-record .dashboard-cell.fixedWidth, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dashboard-cell.fixedWidth {
      min-width: 103px; }
      .dashboard-record-container .dashboard-record .dashboard-cell.fixedWidth .dropdownButton .dropdown-text, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dashboard-cell.fixedWidth .dropdownButton .dropdown-text, .dashboard-record-container .dashboard-record .dashboard-cell.fixedWidth .match-button.icon-text-button .dropdown-text, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dashboard-cell.fixedWidth .match-button.icon-text-button .dropdown-text {
        max-width: 57px; }
      .dashboard-record-container .dashboard-record .dashboard-cell.fixedWidth button, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dashboard-cell.fixedWidth button {
        font-size: 9px; }
  .dashboard-record-container .dashboard-record .note-button i, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .note-button i {
    font-size: 20px; }
  .dashboard-record-container .dashboard-record .dropdown-container .option, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dropdown-container .option {
    padding: 10px 8px; }
    .dashboard-record-container .dashboard-record .dropdown-container .option:not(.selected), .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dropdown-container .option:not(.selected) {
      padding-left: 25px; }
    .dashboard-record-container .dashboard-record .dropdown-container .option .material-icons, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .dashboard-record .dropdown-container .option .material-icons {
      font-size: 12px; } }

.edit-banner {
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: white;
  width: 100%;
  min-height: 84px;
  -webkit-box-shadow: 0 -3px 13px 2px rgba(0, 0, 0, 0.15);
     -moz-box-shadow: 0 -3px 13px 2px rgba(0, 0, 0, 0.15);
          box-shadow: 0 -3px 13px 2px rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 24px 48px;
  font-size: 16px;
  z-index: 100; }
  .edit-banner.confirmation {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .edit-banner.confirmation div:first-of-type {
      margin-right: 70px; }
  .edit-banner .text-button {
    font-size: 14px; }
  .edit-banner .banner-text {
    margin-right: 16px; }
  .edit-banner .checkbox {
    color: #878787;
    margin-right: 20px; }
    .edit-banner .checkbox input[type=checkbox] + label:before {
      top: 50%;
      -webkit-transform: translateY(-50%);
         -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
           -o-transform: translateY(-50%);
              transform: translateY(-50%); }
  .edit-banner .option {
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    background-color: white;
    border: 1px solid #BDBDBD;
    margin: 5px 16px 5px 0px;
    padding: 8px 16px;
    font-size: 14px;
    color: #878787; }
    .edit-banner .option:hover {
      border: 1px solid #fb9990;
      color: #676767; }
  .edit-banner button.text-button {
    margin-left: 14px; }
  .edit-banner button:hover {
    cursor: pointer; }
    .edit-banner button:hover:disabled {
      cursor: not-allowed; }
  .edit-banner .orange-button {
    margin: 2px 24px 2px 0px;
    display: inline-block; }
  .edit-banner .close {
    color: #BDBDBD; }
    .edit-banner .close:hover {
      color: #878787; }

@media (max-width: 1025px) {
  .edit-banner {
    min-height: 64px;
    padding: 20px 32px;
    font-size: 14px; }
    .edit-banner.confirmation div:first-of-type {
      margin-right: 50px; }
    .edit-banner .option {
      font-size: 12px;
      padding: 6px 14px; }
    .edit-banner .text-button {
      font-size: 12px; }
    .edit-banner .orange-button {
      font-size: 12px !important;
      padding: 9.5px 16px !important; } }

.scroll-button {
  -webkit-border-radius: 100%;
     -moz-border-radius: 100%;
          border-radius: 100%;
  width: 60px;
  height: 60px;
  background-color: #F9FAFC;
  position: fixed;
  bottom: 70px;
  right: 0px;
  margin-right: 16px;
  margin-bottom: 16px;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
     -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: none;
  color: #676767; }
  .scroll-button:hover {
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
       -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4); }
  .scroll-button:active {
    border: none; }

@media (max-width: 1025px) {
  .scroll-button {
    width: 46px;
    height: 46px; }
    .scroll-button .material-icons {
      font-size: 20px; } }

.Toastify__toast {
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  font-family: 'Poppins';
  padding: 24px;
  font-size: 14px;
  color: #676767; }
  .Toastify__toast .material-icons {
    font-size: 18px;
    color: #D1D1D1;
    vertical-align: middle; }
    .Toastify__toast .material-icons:hover {
      color: #878787; }

.file-item .transition-container {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .file-item .transition-container .transition-item {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }

.file-item {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  padding: 8px 0; }
  .file-item .file-url {
    border-bottom: none !important;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .file-item button, .file-item .progress-container {
    margin-right: 12px; }
  .file-item .material-icons {
    margin-right: 8px;
    color: #D1D1D1;
    font-weight: 100;
    font-size: 15px; }
  .file-item .file-name {
    color: #505050;
    font-size: 13px;
    font-weight: 600;
    max-width: 150px;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 12px;
    -webkit-transition: color 0.3s;
    -o-transition: color 0.3s;
    -moz-transition: color 0.3s;
    transition: color 0.3s; }
  .file-item .file-name.loading {
    color: #D1D1D1; }
  .file-item .file-item-del {
    color: #878787;
    font-size: 11px;
    font-weight: 600;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    opacity: 0;
    -webkit-transition: opacity 0.15s;
    -o-transition: opacity 0.15s;
    -moz-transition: opacity 0.15s;
    transition: opacity 0.15s;
    pointer-events: none; }
    .file-item .file-item-del i {
      color: #D1D1D1;
      margin-right: 4px; }
    .file-item .file-item-del span {
      text-transform: uppercase; }
  .file-item:hover .file-item-del {
    pointer-events: auto;
    opacity: 1; }

@media (max-width: 1025px) {
  .file-item {
    padding: 4px 0px; }
    .file-item .file-name {
      font-size: 11px; } }

.icon-political-contributions {
  color: #86b487; }

.icon-expenditures {
  color: #A59FCD; }

.icon-lobbying {
  color: #878787; }

.icon-business-regs {
  color: #A1C2CF; }

.sort-button-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 6px; }
  .sort-button-container button {
    background-color: #F1F2F4;
    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;
    color: #878787;
    border: 1px solid #D4D5D8;
    padding: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0px 12px;
    cursor: pointer; }
    .sort-button-container button:hover {
      color: #676767;
      background-color: #e3e5e9;
      border-color: #c7c8cc; }
    .sort-button-container button i {
      font-size: 22px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    .sort-button-container button.active {
      color: #202020;
      background-color: #E5E7EB;
      border: 1px solid #F9FAFC;
      -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1), inset 0 2px 3px 0 rgba(0, 0, 0, 0.3), inset 0 -1px 0 0 rgba(255, 255, 255, 0.5);
         -moz-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1), inset 0 2px 3px 0 rgba(0, 0, 0, 0.3), inset 0 -1px 0 0 rgba(255, 255, 255, 0.5);
              box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1), inset 0 2px 3px 0 rgba(0, 0, 0, 0.3), inset 0 -1px 0 0 rgba(255, 255, 255, 0.5); }
      .sort-button-container button.active i:last-of-type {
        color: #f96c5f; }

@media (max-width: 1025px) {
  .sort-button-container button {
    padding: 8px;
    margin: 0px 8px; }
    .sort-button-container button i {
      font-size: 18px; } }

.dashboard-header {
  padding: 16px; }
  .dashboard-header .flex-header {
    min-width: 0px; }
  .dashboard-header .filter-dropdown {
    margin: 0px 12px; }
    .dashboard-header .filter-dropdown .drop-wrapper {
      padding: 0px; }
    .dashboard-header .filter-dropdown .dropdownButton, .dashboard-header .filter-dropdown .match-button.icon-text-button {
      padding: 10px 16px; }
    .dashboard-header .filter-dropdown .options-wrapper.statuses {
      padding: 0px; }
    .dashboard-header .filter-dropdown li {
      padding: 10px 34px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      cursor: pointer; }
      .dashboard-header .filter-dropdown li.checked {
        padding-left: 17px; }
      .dashboard-header .filter-dropdown li:hover {
        background: #F9FAFC; }
      .dashboard-header .filter-dropdown li i {
        font-size: 14px;
        color: #f96c5f;
        margin-right: 4px; }
  .dashboard-header .icon-text-button {
    font-size: 0.8em; }
  .dashboard-header .export-button {
    font-size: 0.8em;
    color: #676767;
    padding: 8px 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    line-height: 20px; }
    .dashboard-header .export-button .material-icons {
      color: #D1D1D1;
      vertical-align: middle;
      margin-right: 8px;
      font-size: 18px; }
    .dashboard-header .export-button:hover {
      color: #202020; }
      .dashboard-header .export-button:hover .material-icons {
        color: #878787; }

@media (max-width: 900px) {
  .dashboard-header {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .dashboard-header .filter-dropdown {
      min-width: 100px; } }

@media (max-width: 1240px) {
  .dashboard-header .filter-dropdown {
    min-width: 160px; } }

@media (max-width: 1025px) {
  .dashboard-header .filter-dropdown {
    margin: 0px 8px; }
    .dashboard-header .filter-dropdown .dropdownButton, .dashboard-header .filter-dropdown .match-button.icon-text-button {
      padding: 8px 12px; }
  .dashboard-header .icon-text-button, .dashboard-header .export-button {
    font-size: 11px; } }

.recharts-wrapper {
  overflow: hidden; }
  .recharts-wrapper tspan {
    font-size: 11px;
    fill: #505050; }

.recharts-default-tooltip {
  background: white;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  padding: 12px;
  border: 1px solid #D1D1D1;
  font-size: 12px;
  color: #202020; }

.recharts-legend-wrapper {
  max-height: 100%;
  overflow: auto;
  top: 0px !important; }

.recharts-legend-item {
  margin-bottom: 12px; }

.legend-count {
  font-weight: 600;
  color: #202020;
  margin-left: 8px;
  font-size: 16px !important; }

.legend-name {
  color: #676767;
  font-size: 13px !important;
  margin-left: 26px; }

.donut-label-count {
  fill: #202020;
  font-weight: bold;
  font-size: 24px !important; }

.dashboard-card {
  background: white;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  -webkit-box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.05);
  padding: 20px 24px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  height: 100%; }
  .dashboard-card .load-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-height: 300px; }
  .dashboard-card .noResults {
    font-size: 16px;
    height: 300px; }
    .dashboard-card .noResults h2 {
      font-weight: 600;
      background-color: inherit;
      padding: 0px; }
  .dashboard-card .noResultsHeading {
    font-size: 18px;
    margin-bottom: 8px; }

.chart-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 24px; }

.chart-title {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 15px;
  margin-right: 12px; }

.chart-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .chart-buttons > div {
    margin: 0px 8px; }
    .chart-buttons > div:last-child {
      margin-right: 0px; }
  .chart-buttons .text-button, .chart-buttons .icon-text-button {
    padding: 4px 8px;
    font-size: 13.5px; }
  .chart-buttons .dropdownButton i, .chart-buttons .match-button.icon-text-button i {
    color: #BDBDBD; }
  .chart-buttons .dropdownButton:disabled, .chart-buttons .match-button.icon-text-button:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .chart-buttons .dropdownButton:hover:not(:disabled) .dropdown-text, .chart-buttons .match-button.icon-text-button:hover:not(:disabled) .dropdown-text {
    color: #202020; }
  .chart-buttons .dropdownButton:hover:not(:disabled) i, .chart-buttons .match-button.icon-text-button:hover:not(:disabled) i {
    color: #878787; }
  .chart-buttons .dropdown-text {
    text-transform: uppercase;
    font-size: 13.5px;
    font-weight: 600;
    color: #676767;
    margin-right: 8px; }
  .chart-buttons .dropdown-container {
    right: 0px;
    left: auto;
    min-width: 230px;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    -webkit-border-bottom-left-radius: 2px;
       -moz-border-radius-bottomleft: 2px;
            border-bottom-left-radius: 2px;
    -webkit-border-bottom-right-radius: 2px;
       -moz-border-radius-bottomright: 2px;
            border-bottom-right-radius: 2px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
       -moz-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px; }
  .chart-buttons .option {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 14px 34px; }
    .chart-buttons .option .material-icons {
      color: #f96c5f;
      font-size: 18px;
      margin-right: 4px; }
    .chart-buttons .option.selected {
      padding: 14px 12px; }
  .chart-buttons .custom-date-wrapper {
    margin-bottom: auto;
    padding: 14px 0px;
    margin: 0 16px;
    border-top: 1px solid #D1D1D1; }
  .chart-buttons .custom-date-submit {
    text-align: center;
    color: #f96c5f;
    padding-bottom: 14px; }
    .chart-buttons .custom-date-submit button {
      border-bottom: 1px solid #f96c5f;
      padding: 4px; }
  .chart-buttons .error-text, .chart-buttons .formPage form .errorlist, .formPage form .chart-buttons .errorlist {
    font-size: 12px;
    padding: 12px 16px 0px 16px; }

@media (max-width: 1025px) {
  .chart-title {
    font-size: 13.5px; }
  .chart-buttons .dropdown-text {
    font-size: 12px; }
  .chart-buttons .icon-text-button {
    font-size: 12px; } }

.charts-wrapper {
  margin: 12px 48px 48px 48px; }
  .charts-wrapper .dashboard-card {
    min-height: 400px; }
  .charts-wrapper .col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .charts-wrapper .button-wrapper {
    padding: 0px; }

.grid-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  width: -webkit-calc(100% + 32px);
  width: -moz-calc(100% + 32px);
  width: calc(100% + 32px);
  margin: 0px -16px; }

.grid-item {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
     -moz-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  max-width: 100%;
  -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
  padding: 16px; }

@media (min-width: 960px) {
  .grid-item {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
       -moz-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 50%;
    -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
            flex-basis: 50%; } }

.chart-list {
  margin: 0px; }

.chart-list-item {
  padding: 7px 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px; }
  .chart-list-item a {
    border-bottom: 3px solid #feddda; }
    .chart-list-item a:hover {
      text-decoration: none;
      border-bottom-color: #fcb0a9;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      -moz-transition: 0.3s;
      transition: 0.3s; }
  .chart-list-item .name {
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    font-weight: 600; }

@media (max-width: 1025px) {
  .chart-list-item {
    font-size: 12px; } }

#bulk-exports .subheader {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 12px;
  margin-top: 28px;
  color: #676767; }

#bulk-exports .help-text {
  font-size: 14px;
  color: #878787;
  margin-bottom: 32px;
  line-height: 1.5; }

#bulk-exports .flex-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 48px; }
  #bulk-exports .flex-wrapper button {
    background: none;
    border: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    cursor: pointer;
    padding: 0px; }
  #bulk-exports .flex-wrapper .error-text, #bulk-exports .flex-wrapper .formPage form .errorlist, .formPage form #bulk-exports .flex-wrapper .errorlist {
    position: absolute; }

#bulk-exports .list-wrapper {
  background-color: #F9FAFC;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: thin solid #D1D1D1; }
  #bulk-exports .list-wrapper.error {
    border-color: #f96c5f; }

#bulk-exports .header-wrapper {
  background-color: #f1f2f5;
  border-bottom: solid 1px #e1e4eb; }

#bulk-exports .list-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: 600;
  text-transform: capitalize;
  color: #676767;
  padding: 18px 24px;
  font-size: 14px; }
  #bulk-exports .list-header button {
    text-transform: uppercase;
    color: #f96c5f;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.4px; }
    #bulk-exports .list-header button.remove {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
         -moz-box-orient: horizontal;
         -moz-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; }
    #bulk-exports .list-header button .material-icons {
      font-size: 18px; }
    #bulk-exports .list-header button:hover {
      color: #f85142; }
    #bulk-exports .list-header button:disabled {
      cursor: not-allowed;
      opacity: 0.5; }
      #bulk-exports .list-header button:disabled:hover {
        color: #f96c5f; }

#bulk-exports .search-wrapper {
  padding: 0px 16px 16px 16px; }
  #bulk-exports .search-wrapper span {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
       -moz-box-orient: horizontal;
       -moz-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: auto;
    position: relative; }
  #bulk-exports .search-wrapper input {
    border: none;
    font-size: 14px;
    padding: 8px 16px 8px 42px;
    width: 100%;
    background-color: #fcfdff; }
    #bulk-exports .search-wrapper input::-webkit-input-placeholder {
      font-weight: 600; }
    #bulk-exports .search-wrapper input::-moz-placeholder {
      font-weight: 600; }
    #bulk-exports .search-wrapper input::-ms-input-placeholder {
      font-weight: 600; }
    #bulk-exports .search-wrapper input::placeholder {
      font-weight: 600; }
    #bulk-exports .search-wrapper input:focus-within + div .material-icons {
      color: #878787; }
  #bulk-exports .search-wrapper .icon-input-overlay {
    position: absolute;
    left: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%); }
  #bulk-exports .search-wrapper .material-icons {
    color: #D1D1D1;
    vertical-align: middle; }

#bulk-exports .empty {
  color: #BDBDBD;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  padding: 0px 24px; }
  #bulk-exports .empty div {
    text-align: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  #bulk-exports .empty .back-button {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #f96c5f; }
    #bulk-exports .empty .back-button:hover {
      color: #f85142; }

#bulk-exports .header-row .list-item button {
  text-transform: uppercase;
  font-weight: 600;
  color: #878787;
  font-size: 13px;
  letter-spacing: 0.5px;
  padding: 16px 24px; }
  #bulk-exports .header-row .list-item button .material-icons {
    color: #A5A5A5;
    font-size: 18px; }

#bulk-exports .alpha-selector-toggle {
  margin: 0px 8px;
  padding: 8px 16px;
  height: auto; }
  #bulk-exports .alpha-selector-toggle .material-icons {
    color: #BDBDBD;
    font-size: 20px; }

#bulk-exports .alpha-selector-letter {
  color: #BDBDBD;
  font-size: 13px; }

#bulk-exports .alpha-selector-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

#bulk-exports .emp-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  #bulk-exports .emp-row:hover {
    color: #202020;
    background-color: rgba(107, 107, 115, 0.06); }
    #bulk-exports .emp-row:hover .material-icons {
      color: #878787; }

#bulk-exports .list-item {
  width: 100%; }
  #bulk-exports .list-item button {
    font-size: 15px;
    color: #676767;
    padding: 14px 0px;
    width: 100%;
    text-transform: capitalize;
    text-align: left; }
    #bulk-exports .list-item button .material-icons {
      font-size: 16px;
      color: #BDBDBD; }
    #bulk-exports .list-item button.selected {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
         -moz-box-orient: horizontal;
         -moz-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
         -moz-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
      #bulk-exports .list-item button.selected .material-icons {
        padding-right: 16px; }

@media (max-width: 1025px) {
  #bulk-exports .flex-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  #bulk-exports .list-wrapper {
    margin-bottom: 12px; } }

.progress-toast {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: left;
  -webkit-align-items: left;
     -moz-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .progress-toast a {
    color: #f96c5f;
    text-transform: uppercase;
    font-weight: 600;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 8px;
    font-size: 13px; }
    .progress-toast a .material-icons {
      color: #f96c5f;
      margin-right: 8px; }
      .progress-toast a .material-icons:hover {
        color: #f85142; }
    .progress-toast a:hover {
      color: #f85142; }
  .progress-toast .report-progress-container {
    margin-top: 24px !important; }
  .progress-toast .transition-container {
    width: 100%;
    min-height: 24px; }
  .progress-toast .transition-container .transition-item {
    margin-top: 8px; }
  .progress-toast .report-progress-bar-container {
    width: 175px; }

.preclearance-container {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  width: 100%;
  max-width: -webkit-calc(100% - 64px);
  max-width: -moz-calc(100% - 64px);
  max-width: calc(100% - 64px);
  padding-top: 30px;
  margin: 0px auto;
  margin-bottom: 12px; }
  .preclearance-container .preclearance-inner {
    max-width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .preclearance-container .dollar {
    padding: 0px !important;
    color: #676767 !important;
    left: 8px;
    position: absolute;
    font-size: 13px !important;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%); }
  .preclearance-container .dollar + input {
    padding-left: 20px; }
  .preclearance-container > .load-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
    .preclearance-container > .load-wrapper .loader {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1;
         -moz-box-flex: 1;
          -ms-flex: 1 1;
              flex: 1 1; }

.preclearance-error {
  color: red;
  font-size: 11px !important; }

.preclearance-table-container {
  border: 1px solid #d1d1d1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  min-height: 400px; }
  .preclearance-table-container .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important; }
  .preclearance-table-container .fixed-left {
    border-right: 1px solid #d1d1d1;
    z-index: 2;
    background: #f0f2f5;
    -ms-overflow-style: none; }
    .preclearance-table-container .fixed-left .preclearance-input-cell:last-child {
      -webkit-box-pack: inherit;
      -webkit-justify-content: inherit;
         -moz-box-pack: inherit;
          -ms-flex-pack: inherit;
              justify-content: inherit;
      border-right: none; }
    .preclearance-table-container .fixed-left .preclearance-header-cell-wrapper:last-of-type .preclearance-header-cell {
      border: none; }
    .preclearance-table-container .fixed-left.shadow {
      -webkit-box-shadow: 2px 1px 4px 1px rgba(70, 71, 94, 0.15);
         -moz-box-shadow: 2px 1px 4px 1px rgba(70, 71, 94, 0.15);
              box-shadow: 2px 1px 4px 1px rgba(70, 71, 94, 0.15);
      border-right: 1px solid #a19e9e; }
      .preclearance-table-container .fixed-left.shadow .cell-outer:last-child .preclearance-cell {
        border-right-color: #a19e9e; }
      .preclearance-table-container .fixed-left.shadow .preclearance-cell:last-child.editing {
        border-right-color: #737484; }
      .preclearance-table-container .fixed-left.shadow .preclearance-input-cell:last-child {
        border-right-color: #a19e9e; }
    .preclearance-table-container .fixed-left ::-webkit-scrollbar {
      display: none; }
    .preclearance-table-container .fixed-left .preclearance-input-cell {
      width: 150px; }
  .preclearance-table-container .odd {
    background-color: #f7f8fa; }
  .preclearance-table-container .even {
    background-color: #ffffff; }
  .preclearance-table-container .main-table-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    overflow-x: auto;
    background-color: #f0f2f5;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  .preclearance-table-container i {
    cursor: pointer; }
  .preclearance-table-container .absolute-container {
    position: absolute; }
  .preclearance-table-container .after::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    border-bottom: 2px solid #f96c5f; }
  .preclearance-table-container .material-icons {
    font-size: 18px;
    color: #d1d1d1;
    cursor: default; }
  .preclearance-table-container input {
    border: 1px solid #d1d1d1;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    padding: 8px;
    font-size: 15px; }
    .preclearance-table-container input::-webkit-input-placeholder {
      color: #bdbdbd;
      font-size: 13px; }
    .preclearance-table-container input::-moz-placeholder {
      color: #bdbdbd;
      font-size: 13px; }
    .preclearance-table-container input::-ms-input-placeholder {
      color: #bdbdbd;
      font-size: 13px; }
    .preclearance-table-container input::placeholder {
      color: #bdbdbd;
      font-size: 13px; }

.preclearance-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }
  .preclearance-row.selected {
    background-color: #e1e3eb; }
    .preclearance-row.selected .preclearance-cell {
      border-color: #c1bec6; }
    .preclearance-row.selected .preclearance-cell.delete-cell i {
      color: #505050; }

.add-row-placeholder {
  height: 25px; }

.error-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 3px solid #f86c5f;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  padding-top: 6px;
  padding-bottom: 4px;
  position: relative; }
  .error-cell .dollar {
    left: 18px;
    top: 23px; }
  .error-cell .dollar + input {
    padding-left: 18px; }
  .error-cell input {
    margin: 0px 10.5px;
    padding: 8px;
    border: 1px solid #c9cbd4;
    background: #ffffff;
    width: auto !important; }
  .error-cell > span {
    padding-left: 18px;
    font-size: 10px;
    padding-top: 2px;
    color: #f96c5f; }

.preclearance-cell .preclearance-checkbox, .preclearance-input-cell .preclearance-checkbox {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.preclearance-cell.disabled, .preclearance-input-cell.disabled {
  font-style: italic;
  cursor: not-allowed;
  color: #878787; }

.preclearance-cell.match, .preclearance-input-cell.match {
  padding-left: 0px;
  padding-right: 0px; }
  .preclearance-cell.match button, .preclearance-input-cell.match button {
    font-size: 12px; }
  .preclearance-cell.match > div, .preclearance-input-cell.match > div {
    width: 100%;
    padding: 4px 18px; }

.right-align {
  text-align: right; }

.center-align {
  text-align: center; }

.checkbox-cell-icon.material-icons {
  vertical-align: middle;
  color: #878787;
  font-size: 20px; }

.preclearance-checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  font-size: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .preclearance-checkbox input {
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer; }
    .preclearance-checkbox input:checked + .checkmark:before {
      content: '\e834'; }
  .preclearance-checkbox .checkmark {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .preclearance-checkbox .checkmark:before {
    font-family: 'Material Icons';
    content: '\e835'; }

.preclearance-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-right: 1px solid #E8E7EA;
  font-size: 13px;
  color: #505050;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  height: 100%;
  background: inherit; }
  .preclearance-cell.resize {
    border-right: 1px solid #f96c5f !important; }
  .preclearance-cell.delete-cell {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .preclearance-cell.delete-cell i {
      color: #878787;
      font-size: 24px;
      cursor: pointer; }
      .preclearance-cell.delete-cell i:hover {
        color: #505050; }
  .preclearance-cell.status-cell {
    padding: 8px; }
    .preclearance-cell.status-cell > div {
      background-color: #ECEDF0;
      -webkit-border-radius: 2px;
         -moz-border-radius: 2px;
              border-radius: 2px; }
    .preclearance-cell.status-cell .preclearance-dropdown.cell.open .dropdown-value {
      border-bottom: none; }
    .preclearance-cell.status-cell.editing {
      padding: 6px 7px 6px 6px; }
    .preclearance-cell.status-cell .dropdown-value {
      padding: 8px 10.5px; }
    .preclearance-cell.status-cell i {
      color: #878787; }
  .preclearance-cell.status-cell:not(.editing) > div {
    padding: 8px 10.5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%; }
    .preclearance-cell.status-cell:not(.editing) > div span {
      display: block;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1;
         -moz-box-flex: 1;
          -ms-flex: 1 1;
              flex: 1 1; }
  .preclearance-cell.default-status > div {
    background-color: #ffecea; }
  .preclearance-cell.default-status .dropdown-value {
    background-color: #ffecea;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px; }
  .preclearance-cell.editing {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border: 2px solid #737484;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    position: relative; }
    .preclearance-cell.editing .dollar {
      left: 18px; }
    .preclearance-cell.editing .dollar + input {
      padding-left: 18px; }
  .preclearance-cell input {
    width: 100%;
    margin: 0px 10.5px;
    padding: 8px;
    border: 1px solid #c9cbd4;
    background: #ffffff;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  .preclearance-cell > span {
    padding: 4px 18px;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  .preclearance-cell.add-row-button {
    position: absolute;
    z-index: 1;
    text-transform: uppercase;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 12px;
    font-weight: 600;
    height: 25px;
    background-color: #f96c5f;
    color: #ffffff;
    cursor: pointer;
    -webkit-border-radius: 0 0 2px 2px;
       -moz-border-radius: 0 0 2px 2px;
            border-radius: 0 0 2px 2px;
    border: none !important; }
    .preclearance-cell.add-row-button.shadow {
      -webkit-box-shadow: 0 3px 3px 0 rgba(70, 71, 94, 0.2);
         -moz-box-shadow: 0 3px 3px 0 rgba(70, 71, 94, 0.2);
              box-shadow: 0 3px 3px 0 rgba(70, 71, 94, 0.2); }
    .preclearance-cell.add-row-button:hover {
      background-color: #f85142; }
  .preclearance-cell.attachment-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-height: 100%;
    -webkit-flex-wrap: unset;
        -ms-flex-wrap: unset;
            flex-wrap: unset;
    background: inherit; }
    .preclearance-cell.attachment-cell.editing, .preclearance-cell.attachment-cell.preclearance-input-cell {
      z-index: 1;
      padding: 10px 5px;
      -webkit-box-align: unset;
      -webkit-align-items: unset;
         -moz-box-align: unset;
          -ms-flex-align: unset;
              align-items: unset;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .preclearance-cell.attachment-cell.editing i, .preclearance-cell.attachment-cell.preclearance-input-cell i {
        font-size: 13px; }
    .preclearance-cell.attachment-cell.preclearance-input-cell {
      padding: 10px 10px;
      background-color: white; }
      .preclearance-cell.attachment-cell.preclearance-input-cell .file-container {
        margin-bottom: unset; }
    .preclearance-cell.attachment-cell i {
      cursor: pointer;
      color: #878787;
      font-size: 18px; }
      .preclearance-cell.attachment-cell i:hover {
        color: #505050; }
      .preclearance-cell.attachment-cell i.has-files {
        color: #f96c5f;
        font-size: 22px; }
        .preclearance-cell.attachment-cell i.has-files:hover {
          color: #f85142; }
    .preclearance-cell.attachment-cell .attach-button {
      padding-top: 4px;
      font-size: 12px; }
    .preclearance-cell.attachment-cell label {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: auto; }
    .preclearance-cell.attachment-cell .file-container {
      margin-bottom: 5px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      width: 100%;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1;
         -moz-box-flex: 1;
          -ms-flex: 1 1;
              flex: 1 1; }
    .preclearance-cell.attachment-cell .file-url {
      width: 90%; }
      .preclearance-cell.attachment-cell .file-url.loading {
        width: 60%; }
    .preclearance-cell.attachment-cell .file-item {
      padding: 0;
      width: 100%; }
      .preclearance-cell.attachment-cell .file-item .file-name {
        margin-right: 0;
        font-size: 11px; }
    .preclearance-cell.attachment-cell .progress-container {
      width: 49px;
      height: 6px; }

.fixed-left .preclearance-cell:last-child.editing {
  border: 2px solid #737484; }

.cell-outer {
  background: inherit; }
  .cell-outer:last-child .preclearance-cell {
    border-right: none; }

.column-header-tippy {
  -webkit-border-radius: 0px 0px 4px 4px;
     -moz-border-radius: 0px 0px 4px 4px;
          border-radius: 0px 0px 4px 4px; }

.preclearance-popover {
  margin: -5px -9px;
  color: #676767; }

.column-menu-item {
  cursor: pointer;
  text-align: left;
  background: none;
  border: none;
  outline: none !important;
  letter-spacing: 0.5px;
  color: #505050;
  font-size: 12px;
  font-weight: 600;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  padding: 12px 18px;
  width: 100%; }
  .column-menu-item:disabled {
    pointer-events: none;
    opacity: 0.37; }
  .column-menu-item:hover {
    background-color: #F9FAFC; }
  .column-menu-item span {
    padding-left: 12px;
    display: inline-block;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  .column-menu-item i {
    color: #D1D1D1;
    font-size: 20px; }

.preclearance-popover-body {
  color: #676767;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .preclearance-popover-body .header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 18px;
    height: 48px;
    background-color: #f3f4f7;
    font-size: 15px;
    font-weight: 600;
    color: #676767; }
  .preclearance-popover-body .body {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 18px; }
    .preclearance-popover-body .body p {
      margin: 0px;
      font-size: 13px; }
  .preclearance-popover-body .buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 16.5px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #BDBDBD; }
    .preclearance-popover-body .buttons .save-button {
      padding: 8px 16px;
      margin: 0px 0px 0px 12px;
      font-size: 12px;
      font-weight: 600; }
    .preclearance-popover-body .buttons .underline-button {
      color: #f96c5f;
      border-bottom: 1px solid #f96c5f; }
      .preclearance-popover-body .buttons .underline-button:hover {
        color: #f85142;
        border-color: #f85142; }
  .preclearance-popover-body .form-input {
    background-color: #F7F7F8;
    font-size: 15px; }
  .preclearance-popover-body .input-title, .preclearance-popover-body .archive-delay-form label, .archive-delay-form .preclearance-popover-body label {
    font-size: 12px;
    margin-bottom: 8px; }
  .preclearance-popover-body #select-wrapper {
    position: relative; }
  .preclearance-popover-body .drop-wrapper {
    padding: 8px 16px; }
  .preclearance-popover-body .dropdown-container {
    max-height: 150px; }
  .preclearance-popover-body .dropdownButton, .preclearance-popover-body .match-button.icon-text-button {
    letter-spacing: normal;
    font-size: 15px; }
    .preclearance-popover-body .dropdownButton span, .preclearance-popover-body .match-button.icon-text-button span {
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; }
  .preclearance-popover-body .option {
    padding: 8px 16px; }

.range-filter-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }
  .range-filter-wrapper span {
    margin-top: 8px;
    padding: 12px 2px; }

.add-column-form {
  min-width: 356px; }

.left-header {
  position: relative;
  z-index: 2; }

.fixed-left .header-shadow {
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none; }

.header-shadow, .left-header-shadow {
  position: relative;
  -webkit-box-shadow: 1px 3px 3px 0 rgba(70, 71, 94, 0.15);
     -moz-box-shadow: 1px 3px 3px 0 rgba(70, 71, 94, 0.15);
          box-shadow: 1px 3px 3px 0 rgba(70, 71, 94, 0.15);
  z-index: 2; }

.preclearance-header-cell {
  margin: 0px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 18px;
  height: 50px;
  background-color: #f0f2f5;
  border: none;
  border-right: 1px solid #d1d1d1;
  font-size: 12px;
  font-weight: 600;
  color: #878787;
  text-transform: uppercase;
  text-align: left; }
  .preclearance-header-cell span {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  .preclearance-header-cell:active:not(.no-select) {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.2) !important;
       -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.2) !important;
    background-color: #d7d9e0;
    color: #676767;
    border-right: none; }
    .preclearance-header-cell:active:not(.no-select) .material-icons {
      color: #676767; }
  .preclearance-header-cell .material-icons {
    cursor: pointer;
    text-align: right;
    padding: 14px 0px 14px 8px; }
    .preclearance-header-cell .material-icons:hover {
      color: #878787; }
  .preclearance-header-cell.selected {
    background-color: #d7d9e0;
    color: #676767; }
    .preclearance-header-cell.selected .material-icons {
      color: #676767; }
  .preclearance-header-cell.add-column {
    border-right: none; }
    .preclearance-header-cell.add-column .menu-caret {
      display: none; }
    .preclearance-header-cell.add-column div {
      width: auto; }
    .preclearance-header-cell.add-column span {
      cursor: pointer;
      font-size: 18px;
      font-weight: bold;
      color: #f96c5f;
      text-align: center;
      display: inline-block;
      padding: 12px 22px; }
      .preclearance-header-cell.add-column span:hover {
        color: #f85142; }
  .preclearance-header-cell span {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    width: 90%; }

.preclearance-input-cell {
  border-right: 1px solid #d1d1d1;
  border-bottom: 2px solid #f96c5f;
  width: 100%;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #ffffff;
  padding: 20px 10.5px;
  position: relative;
  font-size: 13px; }
  .preclearance-input-cell.disabled span {
    padding: 11px 0px; }
  .preclearance-input-cell.resize {
    border-right: 1px solid #f96c5f !important; }
  .preclearance-input-cell .dollar {
    left: 18px;
    top: 39px;
    width: auto !important; }
  .preclearance-input-cell:last-child {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .preclearance-input-cell:last-child .material-icons {
      padding: 10px 32px; }
  .preclearance-input-cell input {
    width: 100%; }
    .preclearance-input-cell input:focus {
      border-color: #505050; }
  .preclearance-input-cell .material-icons {
    cursor: pointer;
    color: #a5a5a5; }
    .preclearance-input-cell .material-icons:hover {
      color: #878787; }
  .preclearance-input-cell.preclearance-error {
    padding-bottom: 10px; }
    .preclearance-input-cell.preclearance-error input {
      border: 1px solid #f86c5f; }
    .preclearance-input-cell.preclearance-error .preclearance-dropdown {
      border-color: red; }
    .preclearance-input-cell.preclearance-error .error {
      color: red;
      padding-top: 5px;
      font-size: 12px;
      width: 100%; }
  .preclearance-input-cell .preclearance-dropdown {
    border: 1px solid #D1D1D1;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px; }
    .preclearance-input-cell .preclearance-dropdown.open {
      border-color: #505050; }
    .preclearance-input-cell .preclearance-dropdown .dropdown-options {
      position: absolute;
      background: white;
      top: -webkit-calc(100% + 1px);
      top: -moz-calc(100% + 1px);
      top: calc(100% + 1px);
      left: -1px;
      width: -webkit-calc(100% + 2px);
      width: -moz-calc(100% + 2px);
      width: calc(100% + 2px);
      border: 1px solid #d1d1d1;
      border-top: none;
      -webkit-border-radius: 0px 0px 2px 2px;
         -moz-border-radius: 0px 0px 2px 2px;
              border-radius: 0px 0px 2px 2px;
      -webkit-box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.15);
         -moz-box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.15);
              box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.15);
      text-transform: capitalize;
      color: #505050; }
      .preclearance-input-cell .preclearance-dropdown .dropdown-options li {
        font-weight: normal;
        padding: 8px; }
        .preclearance-input-cell .preclearance-dropdown .dropdown-options li:first-child {
          border-top: none; }
    .preclearance-input-cell .preclearance-dropdown .dropdown-value {
      height: 38px;
      padding: 8px;
      color: #505050; }
  .preclearance-input-cell .searchable-dropdown {
    border: none; }

.resize-border-div {
  width: 3px;
  background: #f96c5f;
  position: absolute;
  height: 100%;
  right: -1px;
  top: 0px; }

.input-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }
  .input-row .preclearance-dropdown.cell.open .dropdown-value {
    border-bottom: none; }

input {
  min-width: 1px; }

.preclearance-header-row {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #f0f2f5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.15);
     -moz-box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.15);
          box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #e1e3e6; }

.ReactVirtualized__Grid > .empty-placeholder {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: -webkit-calc(50% - 340px);
  left: -moz-calc(50% - 340px);
  left: calc(50% - 340px);
  width: 680px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%; }

.ReactVirtualized__Grid.no-rows[style] {
  overflow: visible !important; }

.preclearance-table-container {
  position: relative; }
  .preclearance-table-container .table-load-screen {
    width: 100%;
    height: -webkit-calc(100% - 48px);
    height: -moz-calc(100% - 48px);
    height: calc(100% - 48px);
    position: absolute;
    top: 48px;
    background-color: rgba(255, 255, 255, 0.616);
    z-index: 6;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }

.preclearance-row .loading-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.preclearance-row .loading-placeholder {
  height: 8px;
  width: 75%;
  -webkit-border-radius: 9px;
     -moz-border-radius: 9px;
          border-radius: 9px;
  background-color: #c9cbd4; }

.preclearance-row.loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.preclearance-filters {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
     -moz-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  font-size: 13px;
  margin: 0px 19px 12px 19px;
  color: #878787;
  font-weight: 600; }
  .preclearance-filters li {
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px;
    margin-left: 12px;
    background-color: #7a7a82;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    color: #fafbfb;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .preclearance-filters li span:first-child {
      font-weight: normal;
      padding-right: 4px;
      text-transform: capitalize; }
  .preclearance-filters .text-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .preclearance-filters .material-icons {
    cursor: pointer;
    color: #d1d1d1;
    padding-left: 8px;
    font-size: 16px; }
    .preclearance-filters .material-icons:hover {
      color: #BDBDBD; }

.preclearance-dropdown {
  width: 100%;
  z-index: 2;
  background: #ffffff;
  border-bottom: none;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  position: relative; }
  .preclearance-dropdown.cell.open {
    -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
            align-self: flex-start; }
    .preclearance-dropdown.cell.open .dropdown-value {
      border-bottom: 2px solid #737484; }
  .preclearance-dropdown.top.open {
    -webkit-border-radius: 0px 0px 3px 3px;
       -moz-border-radius: 0px 0px 3px 3px;
            border-radius: 0px 0px 3px 3px; }
  .preclearance-dropdown.top .dropdown-options {
    position: absolute;
    background: white;
    top: 0px;
    left: -1px;
    -webkit-transform: translateY(-100%);
       -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
         -o-transform: translateY(-100%);
            transform: translateY(-100%);
    width: -webkit-calc(100% + 2px);
    width: -moz-calc(100% + 2px);
    width: calc(100% + 2px);
    border: 1px solid #D1D1D1;
    -webkit-border-radius: 2px 2px 0px 0px;
       -moz-border-radius: 2px 2px 0px 0px;
            border-radius: 2px 2px 0px 0px; }
  .preclearance-dropdown .dropdown-value {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    padding: 13px 16px;
    cursor: pointer; }
    .preclearance-dropdown .dropdown-value span {
      padding: 0px;
      text-transform: none;
      font-size: 13px;
      font-weight: normal;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .preclearance-dropdown .dropdown-value i {
      cursor: pointer; }
  .preclearance-dropdown .dropdown-options {
    overflow: auto;
    list-style: none;
    text-transform: none;
    border: 1px solid #BDBDBD;
    border-top: none;
    -webkit-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0 0 2px 2px;
       -moz-border-radius: 0 0 2px 2px;
            border-radius: 0 0 2px 2px;
    background-color: white; }
    .preclearance-dropdown .dropdown-options li {
      padding: 8px 16px;
      font-size: 13px;
      cursor: default; }
    .preclearance-dropdown .dropdown-options li:hover {
      background: #f0f2f5; }

.preclearance-dropdown.searchable-dropdown .dropdown-value {
  padding: 0px; }

.preclearance-dropdown.searchable-dropdown .dropdown-container {
  max-height: 200px; }

.preclearance-dropdown.searchable-dropdown .extra-ccd-info {
  color: #878787;
  font-style: italic; }

.preclearance-table-container #jurisdiction-dropdown .max-height {
  max-height: 150px; }

.preclearance-table-container #jurisdiction-dropdown .jurisdiction-header {
  font-size: 11px; }

.preclearance-table-container #jurisdiction-dropdown .option {
  padding: 14px 16px; }
  .preclearance-table-container #jurisdiction-dropdown .option:hover {
    background: #f0f2f5; }

.preclearance-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 14px; }
  .preclearance-header-wrapper a {
    font-size: 0.8em;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #676767;
    border: none;
    padding: 12px 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: none;
    cursor: pointer; }
    .preclearance-header-wrapper a:disabled {
      opacity: 0.5;
      cursor: not-allowed; }
    .preclearance-header-wrapper a .material-icons {
      color: #D1D1D1;
      color: #D1D1D1;
      vertical-align: middle;
      margin-right: 4px;
      font-size: 18px; }
    .preclearance-header-wrapper a:hover:not(:disabled), .preclearance-header-wrapper a:focus, .preclearance-header-wrapper a a:hover:not(:disabled), .preclearance-header-wrapper a a:focus {
      color: #202020; }
      .preclearance-header-wrapper a:hover:not(:disabled) .material-icons, .preclearance-header-wrapper a:focus .material-icons, .preclearance-header-wrapper a a:hover:not(:disabled) .material-icons, .preclearance-header-wrapper a a:focus .material-icons {
        color: #878787; }
  .preclearance-header-wrapper .nav-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }

.action-modal .modal-close {
  display: none; }

.action-modal input, .action-modal textarea {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: 1px solid #ababab;
  font-size: 1rem;
  resize: none; }
  .action-modal input:active, .action-modal input:focus, .action-modal textarea:active, .action-modal textarea:focus {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .action-modal input::-webkit-input-placeholder, .action-modal textarea::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .action-modal input::-moz-placeholder, .action-modal textarea::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .action-modal input::-ms-input-placeholder, .action-modal textarea::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .action-modal input::placeholder, .action-modal textarea::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .action-modal input.error, .action-modal textarea.error {
    border-color: #f96c5f; }

.action-modal textarea {
  min-height: 300px; }

.action-modal label {
  color: #878787;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: 2px;
  margin-bottom: 12px; }

.action-modal .message > div {
  margin-bottom: 16px; }

.action-modal .input-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 28px; }

.action-modal .modal-button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .action-modal .modal-button-wrapper .icon.text-button {
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #676767;
    border: none;
    padding: 12px 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: none;
    cursor: pointer;
    color: #676767;
    padding: 0px;
    border-bottom: none; }
    .action-modal .modal-button-wrapper .icon.text-button:disabled {
      opacity: 0.5;
      cursor: not-allowed; }
    .action-modal .modal-button-wrapper .icon.text-button .material-icons {
      color: #D1D1D1;
      color: #D1D1D1;
      vertical-align: middle;
      margin-right: 4px;
      font-size: 18px; }
    .action-modal .modal-button-wrapper .icon.text-button:hover:not(:disabled), .action-modal .modal-button-wrapper .icon.text-button:focus, .action-modal .modal-button-wrapper .icon.text-button a:hover:not(:disabled), .action-modal .modal-button-wrapper .icon.text-button a:focus {
      color: #202020; }
      .action-modal .modal-button-wrapper .icon.text-button:hover:not(:disabled) .material-icons, .action-modal .modal-button-wrapper .icon.text-button:focus .material-icons, .action-modal .modal-button-wrapper .icon.text-button a:hover:not(:disabled) .material-icons, .action-modal .modal-button-wrapper .icon.text-button a:focus .material-icons {
        color: #878787; }

.action-modal .search-form .button-wrapper {
  padding: 0px; }

.action-modal .search-form .add-field-button {
  font-size: 13px;
  margin-top: 12px; }

.action-modal .error-text, .action-modal .formPage form .errorlist, .formPage form .action-modal .errorlist {
  position: relative; }

.action-modal .right-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.action-modal .simple-form .modal-button-wrapper {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.action-modal .simple-form .orange-button {
  margin: 0px; }

.filters-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-size: 13px;
  position: relative; }
  .filters-header span {
    display: inline-block; }
    .filters-header span:first-of-type .text-button {
      margin-left: 5px; }
  .filters-header .text-button {
    padding: 10px;
    text-align: center;
    margin: 0px 12px -2px 12px; }
    .filters-header .text-button span {
      padding: 7px 4px 4px 4px;
      display: inline-block;
      vertical-align: middle;
      border-bottom: 3px solid transparent; }
    .filters-header .text-button:last-of-type {
      margin-right: 0px; }
    .filters-header .text-button.active {
      border-bottom: none;
      -webkit-flex-shrink: 0;
          -ms-flex-negative: 0;
              flex-shrink: 0; }
      .filters-header .text-button.active span {
        border-bottom: solid 3px #f96c5f;
        max-width: none; }
    .filters-header .text-button:not(.active) {
      color: #A5A5A5; }
      .filters-header .text-button:not(.active):hover, .filters-header .text-button:not(.active):focus {
        color: #676767; }
        .filters-header .text-button:not(.active):hover span, .filters-header .text-button:not(.active):focus span {
          border-bottom: solid 3px #fb9990; }

@media (max-width: 1025px) {
  .filters-header {
    font-size: 11px; }
    .filters-header .text-button {
      padding: 8px;
      margin: 0px 8px -1px 8px; }
      .filters-header .text-button span {
        padding: 6px 6px 3px 6px;
        max-width: 100px; }
      .filters-header .text-button.active {
        width: auto; } }

@media (max-width: 900px) {
  .filters-header .text-button {
    min-width: auto; }
    .filters-header .text-button span {
      max-width: 85px; } }

.certifications-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  width: 100%;
  padding: 32px;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
     -moz-box-flex: 1;
      -ms-flex: auto;
          flex: auto; }
  .certifications-container h2 {
    font-weight: 600;
    font-size: 20px;
    color: #676767; }
  .certifications-container .dashboard-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 16px 0px;
    margin-top: 24px;
    height: auto;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
       -moz-box-flex: 1;
        -ms-flex: auto;
            flex: auto; }
  .certifications-container .back-button {
    margin: 0px -16px;
    font-size: 11px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; }
    .certifications-container .back-button i {
      font-size: 18px; }

.certification-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.8rem; }
  .certification-actions .icon-text-button {
    font-size: 0.8rem; }
  .certification-actions .orange-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 0.8rem;
    margin-left: 24px; }
    .certification-actions .orange-button .material-icons {
      margin-right: 4px;
      font-size: 18px; }

.certification-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.cert-detail-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 13px;
  margin-right: 36px; }
  .cert-detail-item .name-link {
    font-weight: normal; }

.cert-details-label {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #878787;
  margin-right: 8px; }

@media (max-width: 1025px) {
  .certifications-container {
    padding: 24px; } }

.certification-wrapper-row {
  padding: 0px 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .certification-wrapper-row.selected {
    background-color: #F9FAFC;
    -webkit-transition: background-color 300ms;
    -o-transition: background-color 300ms;
    -moz-transition: background-color 300ms;
    transition: background-color 300ms; }
  .certification-wrapper-row:last-of-type .certification-row {
    border-bottom: none; }

.certification-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #e8eded;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1; }
  .certification-row .submission-link {
    padding: 16px 24px;
    color: #f96c5f;
    font-size: 0.8rem; }

.deleted {
  opacity: 0.6; }

.name-link {
  font-weight: 600;
  color: #505050;
  border-bottom: 3px solid #feddda;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%; }
  .name-link:hover {
    text-decoration: none;
    border-bottom-color: #fcb0a9;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s; }

.certification-header-row {
  border-bottom: none;
  color: #878787;
  font-size: 12px;
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
  .certification-header-row .certification-cell {
    -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
            align-self: stretch;
    padding-bottom: 16px;
    border-bottom: 2px solid #e8eded; }
    .certification-header-row .certification-cell.sorted {
      border-color: #f96c5f; }

.certification-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  padding: 0 12px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  min-width: 1px; }
  .certification-cell .icon-text-button {
    font-size: 0.8rem; }

.horizontal-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.certification-header-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%; }
  .certification-header-cell i {
    color: #f96c5f;
    padding-left: 4px;
    font-size: 20px; }

.certification-status {
  color: #878787;
  font-size: 11px;
  margin-bottom: 2px; }

.submission-status {
  font-size: 12px;
  padding: 4px 12px;
  -webkit-border-radius: 20px;
     -moz-border-radius: 20px;
          border-radius: 20px; }
  .submission-status.gray {
    background-color: #F0F1F1;
    color: #676767; }
  .submission-status.red {
    background-color: #FEDDDA;
    color: #723A35; }

.certification-schedule .missing-data i {
  font-size: inherit; }

.missing-data {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #676767; }
  .missing-data i {
    color: #BDBDBD;
    font-size: 20px;
    margin-right: 4px; }

@media (max-width: 1025px) {
  .certification-row {
    font-size: 13px; }
  .certification-header-row {
    font-size: 11px; }
  .certification-status {
    font-size: 10px; } }

.submission-header {
  background-color: #F7F7F8;
  border-bottom: 1px solid #EBEBEB;
  padding: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 13px;
  color: #505050; }

.submission-header-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .submission-header-name .close {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 12px; }
    .submission-header-name .close .material-icons {
      color: #878787;
      font-size: 24px; }
    .submission-header-name .close:hover .material-icons {
      color: #505050; }

.submission-header-status {
  color: #878787;
  font-size: 12px;
  margin-bottom: 4px; }

.submission-body {
  padding: 32px 24px; }

.response-item {
  margin: 48px 0px; }
  .response-item:first-of-type {
    margin-top: 0px; }
    .response-item:first-of-type .md-question.answered {
      margin-top: 0px; }
  .response-item .checkbox input[type=checkbox] + label {
    cursor: auto; }

.no-response {
  color: #BDBDBD; }

.certification-template-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #676767;
  padding: 48px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1; }

.certification-template-icon {
  color: #F0F1F1; }
  .certification-template-icon .material-icons {
    font-size: 120px; }

.certification-template-heading {
  font-weight: 600;
  font-size: 20px;
  margin: 12px 0px 8px; }

.certification-template-message {
  font-size: 14px; }

.certification-template-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 36px; }
  .certification-template-actions .icon-text-button {
    background: #ECEDF0;
    margin: 0px 12px;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    font-size: 0.8rem; }
    .certification-template-actions .icon-text-button:hover {
      background-color: #dee0e5; }

.create-cert-wrapper {
  width: 50vw;
  min-width: 600px;
  padding: 36px 48px;
  background-color: white;
  -webkit-box-shadow: 0 8px 28px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0 8px 28px rgba(0, 0, 0, 0.05);
          box-shadow: 0 8px 28px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px; }

.create-cert-form h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0px 0px 32px;
  color: #676767; }

.create-cert-form .multi-checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.create-cert-form .checkbox {
  margin-bottom: 8px; }
  .create-cert-form .checkbox input[type=checkbox] {
    display: block;
    position: relative;
    overflow: hidden;
    height: 0;
    margin: 0; }

.create-cert-form .multiselect .dropdown-container {
  -webkit-transform: translateY(-100%);
     -moz-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
       -o-transform: translateY(-100%);
          transform: translateY(-100%);
  top: 0px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px -2px 4px, rgba(0, 0, 0, 0.06) 0px -2px 12px;
     -moz-box-shadow: rgba(0, 0, 0, 0.08) 0px -2px 4px, rgba(0, 0, 0, 0.06) 0px -2px 12px;
          box-shadow: rgba(0, 0, 0, 0.08) 0px -2px 4px, rgba(0, 0, 0, 0.06) 0px -2px 12px; }

.create-cert-form .horizontal-radio-buttons label {
  color: #878787;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: 2px;
  margin-bottom: 12px; }

.create-cert-form .horizontal-radio-buttons input {
  width: 100%; }

.create-cert-form .horizontal-radio-buttons ul.radio-ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  border: none !important;
  margin-bottom: 24px; }
  .create-cert-form .horizontal-radio-buttons ul.radio-ul li {
    margin-right: 25px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #878787; }
  .create-cert-form .horizontal-radio-buttons ul.radio-ul label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .create-cert-form .horizontal-radio-buttons ul.radio-ul label input {
      margin-right: 8px;
      margin-top: 0px;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1;
         -moz-box-flex: 1;
          -ms-flex: 1 1;
              flex: 1 1;
      min-width: 20px;
      -ms-flex-preferred-size: 28px; }
    .create-cert-form .horizontal-radio-buttons ul.radio-ul label input[type=radio]:after {
      font-family: 'Material Icons';
      content: '\E836';
      color: #A5A5A5;
      width: 20px;
      height: 20px;
      top: -2px;
      left: -1px;
      position: relative;
      display: inline-block;
      visibility: visible;
      background: white; }
    .create-cert-form .horizontal-radio-buttons ul.radio-ul label input[type=radio]:checked:after {
      font-family: 'Material Icons';
      content: '\E837';
      color: #505050; }
  .create-cert-form .horizontal-radio-buttons ul.radio-ul label {
    cursor: pointer; }

.create-cert-form .horizontal-radio-buttons li {
  margin-right: 25px; }

.create-cert-form .horizontal-radio-buttons #radio-button {
  margin-left: 0; }
  .create-cert-form .horizontal-radio-buttons #radio-button:after {
    background: white !important;
    margin-bottom: 0;
    font-size: 22px !important;
    top: -5px; }

.create-cert-form .description {
  line-height: 24px;
  margin-bottom: 36px; }

.create-cert-form .sidebar-search-container {
  margin: 0px 0px 20px 0px;
  padding: 0px; }

.create-cert-form .individual-form .selected-header {
  color: #878787;
  font-size: 14px; }

.create-cert-form .individual-form .badge {
  color: #505050;
  background: #ECEDF0; }

.create-cert-form .individual-form .selected-section .selection-container {
  max-height: 150px;
  overflow-y: auto;
  margin-top: 12px; }

.create-cert-form .individual-form .no-results-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .create-cert-form .individual-form .no-results-container span {
    color: #949494; }

.create-cert-form .individual-form #separator {
  margin: 24px 0; }

.create-cert-form .form-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 36px; }
  .create-cert-form .form-field:last-child {
    margin-bottom: 0; }
  .create-cert-form .form-field label {
    color: #878787;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 2px;
    margin-bottom: 12px; }
  .create-cert-form .form-field input, .create-cert-form .form-field textarea {
    padding: 12px 16px;
    color: #676767;
    -webkit-border-radius: 2px 2px 0 0;
       -moz-border-radius: 2px 2px 0 0;
            border-radius: 2px 2px 0 0;
    border: none;
    border-bottom: 1px solid #D1D1D1;
    font-size: 1rem;
    background-color: #F7F7F8;
    resize: vertical; }
    .create-cert-form .form-field input:active:not(:disabled):not([readonly]), .create-cert-form .form-field input:focus:not(:disabled):not([readonly]), .create-cert-form .form-field textarea:active:not(:disabled):not([readonly]), .create-cert-form .form-field textarea:focus:not(:disabled):not([readonly]) {
      color: #505050;
      border-color: #505050;
      outline: none; }
    .create-cert-form .form-field input::-webkit-input-placeholder, .create-cert-form .form-field textarea::-webkit-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .create-cert-form .form-field input::-moz-placeholder, .create-cert-form .form-field textarea::-moz-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .create-cert-form .form-field input::-ms-input-placeholder, .create-cert-form .form-field textarea::-ms-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .create-cert-form .form-field input::placeholder, .create-cert-form .form-field textarea::placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .create-cert-form .form-field input.error, .create-cert-form .form-field textarea.error {
      border-color: #f96c5f; }
    .create-cert-form .form-field input:disabled, .create-cert-form .form-field input[readonly], .create-cert-form .form-field textarea:disabled, .create-cert-form .form-field textarea[readonly] {
      opacity: 0.8;
      font-style: italic;
      cursor: not-allowed;
      background: #f2f2f2; }
  .create-cert-form .form-field.sub-field {
    margin-bottom: 24px; }
  .create-cert-form .form-field.template-wrapper textarea {
    padding: 12px 16px;
    color: #676767;
    -webkit-border-radius: 2px 2px 0 0;
       -moz-border-radius: 2px 2px 0 0;
            border-radius: 2px 2px 0 0;
    border: none;
    border-bottom: 1px solid #D1D1D1;
    font-size: 1rem;
    background-color: #F7F7F8;
    border: none;
    resize: none; }
    .create-cert-form .form-field.template-wrapper textarea:active:not(:disabled):not([readonly]), .create-cert-form .form-field.template-wrapper textarea:focus:not(:disabled):not([readonly]) {
      color: #505050;
      border-color: #505050;
      outline: none; }
    .create-cert-form .form-field.template-wrapper textarea::-webkit-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .create-cert-form .form-field.template-wrapper textarea::-moz-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .create-cert-form .form-field.template-wrapper textarea::-ms-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .create-cert-form .form-field.template-wrapper textarea::placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .create-cert-form .form-field.template-wrapper textarea.error {
      border-color: #f96c5f; }
    .create-cert-form .form-field.template-wrapper textarea:disabled, .create-cert-form .form-field.template-wrapper textarea[readonly] {
      opacity: 0.8;
      font-style: italic;
      cursor: not-allowed;
      background: #f2f2f2; }
  .create-cert-form .form-field.template-wrapper .help-text {
    margin-bottom: 12px;
    padding-top: 0px; }

.create-cert-form .help-text {
  font-size: 13px;
  color: #878787;
  padding-top: 4px;
  margin-left: 2px; }

.create-cert-form #date-range #range-label {
  color: #878787;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: 2px;
  margin-bottom: 12px;
  display: inline-block;
  margin-bottom: 16px; }

.create-cert-form #date-range .reports-row {
  margin-bottom: 0px; }
  .create-cert-form #date-range .reports-row label {
    margin-left: 26px; }

.create-cert-form #date-range .wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1; }

.create-cert-form .drop-wrapper {
  background-color: #F7F7F8;
  padding: 12px 16px; }

.create-cert-form .dropdownButton, .create-cert-form .match-button.icon-text-button {
  font-size: 1rem;
  letter-spacing: normal; }

.create-cert-form .flex-load-wrapper {
  min-height: 100px; }

.create-cert-form .flex-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .create-cert-form .flex-wrapper .form-field {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }

.template-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 36px;
  margin-left: 48px; }
  .template-toggle button {
    background-color: #F7F7F8;
    color: #A5A5A5;
    padding: 16px 12px;
    font-size: 13px; }
    .template-toggle button:first-of-type {
      -webkit-border-radius: 2px 0px 0px 2px;
         -moz-border-radius: 2px 0px 0px 2px;
              border-radius: 2px 0px 0px 2px; }
    .template-toggle button:last-of-type {
      -webkit-border-radius: 0px 2px 2px 0px;
         -moz-border-radius: 0px 2px 2px 0px;
              border-radius: 0px 2px 2px 0px; }
    .template-toggle button.highlighted {
      background: #f96c5f;
      color: white; }
    .template-toggle button:hover:not(.highlighted) {
      background-color: #F0F1F1;
      color: #878787; }

.preclearance-modal .survey-panel {
  width: auto;
  max-width: none;
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;
  margin: 0px; }

.preclearance-modal .modal-box {
  background-color: white;
  font-size: inherit; }
  .preclearance-modal .modal-box h2 {
    padding: 0px;
    background-color: transparent; }

.form-submitted-header {
  font-style: italic;
  margin: 12px 0px 48px 0px;
  color: #878787; }

.view-certification-forms {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
     -moz-box-flex: 1;
      -ms-flex: auto;
          flex: auto; }
  .view-certification-forms .dashboard-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 16px 0px;
    margin-top: 20px;
    height: auto;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
       -moz-box-flex: 1;
        -ms-flex: auto;
            flex: auto; }
  .view-certification-forms .certification-row:not(.certification-header-row) {
    min-height: 60px; }
  .view-certification-forms .certification-cell:first-child {
    -webkit-box-flex: 4;
    -webkit-flex-grow: 4;
       -moz-box-flex: 4;
        -ms-flex-positive: 4;
            flex-grow: 4; }
  .view-certification-forms .icon-text-button {
    margin: 0px -16px; }

.view-certification-forms-header h1 {
  margin-bottom: 0px !important; }

.view-certification-forms-header .orange-button {
  margin: 0px; }

.view-certification-forms-header .help-text {
  margin-bottom: 28px;
  margin-top: 48px; }

.view-certification-forms-header .flex-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.sidebar-nav {
  border-right: 1px solid #D1D1D1;
  height: 100vh;
  width: 250px;
  position: fixed;
  left: 0px;
  top: 78px;
  overflow: auto; }
  .sidebar-nav h2 {
    font-weight: 600;
    padding: 48px 32px 0px 32px;
    font-size: 20px;
    margin: 0px 0px 28px 0px; }
  .sidebar-nav .nav-link {
    padding: 16px 32px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #A5A5A5;
    text-align: left;
    width: 100%; }
    .sidebar-nav .nav-link:hover, .sidebar-nav .nav-link:focus {
      color: #676767; }
    .sidebar-nav .nav-link.selected {
      background-color: #ECEDF0;
      border-right: 3px solid #f96c5f;
      color: #505050; }
      .sidebar-nav .nav-link.selected:hover {
        color: #505050; }
    .sidebar-nav .nav-link:hover {
      color: #676767; }

@media (max-width: 1025px) {
  .sidebar-nav h2 {
    font-size: 18px; }
  .sidebar-nav .nav-link {
    font-size: 11px; } }

#research-page .sidebar-page-body {
  width: -webkit-calc(100% - 250px);
  width: -moz-calc(100% - 250px);
  width: calc(100% - 250px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

#research-page .row.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

#research-page .range-input-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  #research-page .range-input-container div {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  #research-page .range-input-container .error-text, #research-page .range-input-container .formPage form .errorlist, .formPage form #research-page .range-input-container .errorlist {
    position: absolute; }
  #research-page .range-input-container span {
    margin: 4px 4px 0px 4px;
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center; }
  #research-page .range-input-container .input-title, #research-page .range-input-container .archive-delay-form label, .archive-delay-form #research-page .range-input-container label {
    display: none; }

#research-page .checkbox input[type=checkbox] {
  width: auto !important; }

#research-page .expandable-section {
  margin-top: 48px; }

#research-page .expandable-content {
  padding: 18px 32px 48px; }

#research-page .form-wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: none;
     -moz-box-flex: 0;
      -ms-flex: none;
          flex: none;
  font-size: 13px;
  padding: 48px 64px; }
  #research-page .form-wrapper.header {
    max-width: none;
    padding: 24px 8px 24px 8px;
    margin: 0px 16px;
    border-bottom: 1px solid #D1D1D1; }
    #research-page .form-wrapper.header .range-input-container span {
      margin: 4px 4px 0px 4px; }
  #research-page .form-wrapper .filter-dropdown .dropdown-container {
    max-height: none;
    overflow: visible;
    padding: 0px !important; }
  #research-page .form-wrapper .filter-dropdown .custom-date-wrapper input {
    max-width: none;
    padding: 12px 16px;
    font-size: 15px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
    #research-page .form-wrapper .filter-dropdown .custom-date-wrapper input + span {
      margin: 0px 4px; }
  #research-page .form-wrapper #jurisdiction-dropdown .dropdown-container {
    max-height: none;
    overflow: visible; }
  #research-page .form-wrapper .dropdown {
    width: 100%;
    height: 100%; }
  #research-page .form-wrapper .dropdownButton, #research-page .form-wrapper .match-button.icon-text-button {
    text-transform: uppercase;
    color: #878787;
    background-color: #ECEDF0;
    font-weight: 600;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px; }
    #research-page .form-wrapper .dropdownButton.open, #research-page .form-wrapper .open.match-button.icon-text-button {
      -webkit-border-radius: 2px 2px 0 0;
         -moz-border-radius: 2px 2px 0 0;
              border-radius: 2px 2px 0 0; }
    #research-page .form-wrapper .dropdownButton.text-button, #research-page .form-wrapper .text-button.match-button.icon-text-button {
      margin: 0px;
      border: none; }
      #research-page .form-wrapper .dropdownButton.text-button:hover, #research-page .form-wrapper .text-button.match-button.icon-text-button:hover, #research-page .form-wrapper .dropdownButton.text-button:focus, #research-page .form-wrapper .text-button.match-button.icon-text-button:focus {
        border: none; }
  #research-page .form-wrapper .dropdown-container {
    max-height: 300px;
    overflow: auto;
    width: 100%;
    padding-bottom: 12px; }
    #research-page .form-wrapper .dropdown-container .form-input {
      background-color: #F7F7F8; }
    #research-page .form-wrapper .dropdown-container .dropdown-inner-wrapper {
      padding: 20px 16px 0px 16px; }
    #research-page .form-wrapper .dropdown-container .error-text, #research-page .form-wrapper .dropdown-container .formPage form .errorlist, .formPage form #research-page .form-wrapper .dropdown-container .errorlist {
      position: relative; }
    #research-page .form-wrapper .dropdown-container .input-title, #research-page .form-wrapper .dropdown-container .archive-delay-form label, .archive-delay-form #research-page .form-wrapper .dropdown-container label {
      display: none; }
    #research-page .form-wrapper .dropdown-container .input-wrapper {
      margin-top: 20px; }
    #research-page .form-wrapper .dropdown-container .vertical-line {
      display: none; }
    #research-page .form-wrapper .dropdown-container #allSourcesCheckbox {
      margin-top: 20px; }
    #research-page .form-wrapper .dropdown-container .categorySelection {
      display: none; }
    #research-page .form-wrapper .dropdown-container .form-control {
      display: block; }
    #research-page .form-wrapper .dropdown-container .sourceColumn {
      padding: 0px; }
    #research-page .form-wrapper .dropdown-container .category {
      padding: 12px 0px; }
    #research-page .form-wrapper .dropdown-container .sourceColumnContainer {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
    #research-page .form-wrapper .dropdown-container .source-wrapper {
      padding: 0px; }

#research-page .form-heading {
  font-size: 20px;
  margin-bottom: 24px; }

#research-page .expandable-header .header-right {
  overflow: visible; }

#research-page .count {
  -webkit-border-radius: 15px;
     -moz-border-radius: 15px;
          border-radius: 15px;
  background-color: #F9FAFC;
  font-size: 10px;
  padding: 4px 8px;
  margin-left: 24px;
  color: #505050; }

#research-page .clear {
  margin-top: 24px; }

#research-page .sourceSelectionContainer .text-button {
  color: inherit;
  display: inline-block;
  border: none; }
  #research-page .sourceSelectionContainer .text-button:hover, #research-page .sourceSelectionContainer .text-button:focus {
    border: none; }

#research-page .row.search-header {
  border-bottom: none; }
  #research-page .row.search-header .error-text, #research-page .row.search-header .formPage form .errorlist, .formPage form #research-page .row.search-header .errorlist {
    position: absolute; }
  #research-page .row.search-header .col {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
    margin: 16px; }
    #research-page .row.search-header .col.no-flex {
      -webkit-box-flex: 0;
      -webkit-flex: 0 1 auto;
         -moz-box-flex: 0;
          -ms-flex: 0 1 auto;
              flex: 0 1 auto; }
  #research-page .row.search-header .focusable {
    margin: 0px; }
  #research-page .row.search-header .input-title, #research-page .row.search-header .archive-delay-form label, .archive-delay-form #research-page .row.search-header label {
    margin-top: 0px; }
  #research-page .row.search-header .search-button-container {
    margin-top: 0px; }

#research-page .filter-item {
  width: 30%;
  display: inline-block;
  margin: 0px 16px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto; }
  #research-page .filter-item .error-text, #research-page .filter-item .formPage form .errorlist, .formPage form #research-page .filter-item .errorlist {
    position: absolute; }
  #research-page .filter-item ul {
    border-color: #ECEDF0;
    width: 100%;
    margin: 0px; }

#research-page .search-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

#research-page .search-button-container {
  position: relative;
  margin-top: 48px; }

#research-page .orange-button {
  margin-bottom: 2px; }

@media (max-width: 1025px) {
  #research-page .input-title, #research-page .archive-delay-form label, .archive-delay-form #research-page label {
    height: auto; }
  #research-page .row.search-header .col {
    margin: 10px; }
  #research-page .sourceSelectionContainer {
    margin-bottom: 0px; }
    #research-page .sourceSelectionContainer .checkbox input[type=checkbox] + label {
      margin-bottom: 0px;
      margin-top: 0px; }
    #research-page .sourceSelectionContainer .sourceColumnContainer {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
    #research-page .sourceSelectionContainer .sourceColumn {
      width: 100%;
      padding: 0px; }
  #research-page .header .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  #research-page .dropdown-container .range-input-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 175px; }
  #research-page .dropdown-container .text-button {
    font-size: 12px; }
  #research-page .form-wrapper .dropdownButton, #research-page .form-wrapper .match-button.icon-text-button {
    font-size: 11px;
    padding: 0px; }
  #research-page .form-wrapper .filter-dropdown .dropdownButton, #research-page .form-wrapper .filter-dropdown .match-button.icon-text-button, #research-page .form-wrapper #jurisdiction-dropdown .dropdownButton, #research-page .form-wrapper #jurisdiction-dropdown .match-button.icon-text-button {
    padding: 0px; }
  #research-page .form-wrapper .filter-dropdown .custom-date-wrapper input, #research-page .form-wrapper #jurisdiction-dropdown .custom-date-wrapper input {
    font-size: 13px;
    padding: 10px 6px; }
  #research-page .filter-item {
    margin: 0px 10px 16px 10px; }
    #research-page .filter-item .material-icons {
      font-size: 18px; }
    #research-page .filter-item .search-states {
      min-height: 34px; }
    #research-page .filter-item #state-input::-webkit-input-placeholder {
      font-size: 11px; }
    #research-page .filter-item #state-input::-moz-placeholder {
      font-size: 11px; }
    #research-page .filter-item #state-input::-ms-input-placeholder {
      font-size: 11px; }
    #research-page .filter-item #state-input::placeholder {
      font-size: 11px; } }

#research-page .results-body {
  padding: 20px;
  margin-bottom: 24px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

#research-page .results-error {
  color: #BDBDBD;
  font-size: 20px;
  height: -webkit-calc(100vh - 200px);
  height: -moz-calc(100vh - 200px);
  height: calc(100vh - 200px);
  margin: 0px auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 600px;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center; }
  #research-page .results-error.source {
    height: auto;
    font-size: 20px;
    max-width: 100%;
    margin: 20px auto; }
  #research-page .results-error h2 {
    font-weight: 600;
    font-size: 24px; }
  #research-page .results-error a {
    color: #f96c5f; }
    #research-page .results-error a:hover {
      color: #f85142; }

#research-page .source-container .load-wrapper {
  height: 150px; }
  #research-page .source-container .load-wrapper > div {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  #research-page .source-container .load-wrapper .loader {
    position: relative; }

#research-page .load-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100vh; }
  #research-page .load-wrapper .loader {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }

#research-page .results-name-row .button-wrapper {
  position: relative; }
  #research-page .results-name-row .button-wrapper .tooltip {
    top: 0px;
    -webkit-transform: translateY(-100%);
       -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
         -o-transform: translateY(-100%);
            transform: translateY(-100%);
    min-width: 200px;
    right: auto;
    font-size: 13px;
    left: -50%; }
    #research-page .results-name-row .button-wrapper .tooltip a {
      color: #f96c5f; }
      #research-page .results-name-row .button-wrapper .tooltip a:hover {
        color: #f85142; }
    #research-page .results-name-row .button-wrapper .tooltip:hover {
      display: block; }

#research-page .results-name-row .text-button:disabled, #research-page .results-name-row .text-button.disabled {
  cursor: not-allowed;
  opacity: 0.7; }
  #research-page .results-name-row .text-button:disabled:hover, #research-page .results-name-row .text-button.disabled:hover {
    opacity: 0.7;
    color: #676767; }
    #research-page .results-name-row .text-button:disabled:hover + .tooltip, #research-page .results-name-row .text-button.disabled:hover + .tooltip {
      display: block; }
    #research-page .results-name-row .text-button:disabled:hover .material-icons, #research-page .results-name-row .text-button.disabled:hover .material-icons {
      color: #D1D1D1; }

@media (max-width: 1025px) {
  #research-page .results-header {
    padding: 0px 14px; } }

.preclearance-settings .sidebar-page-body {
  padding: 48px 80px; }

.preclearance-settings h1 {
  font-weight: 600;
  font-size: 2em;
  margin-bottom: 48px;
  margin-top: 0px; }

.preclearance-settings hr {
  border-color: #F0F1F1;
  background-color: #F0F1F1;
  margin: 60px 0px;
  border: none;
  height: 1px; }

.preclearance-change-confirmation .expandable-section {
  margin: 24px 28px !important;
  font-size: 16px; }

.preclearance-change-confirmation .expandable-header {
  background-color: #F0F1F1; }
  .preclearance-change-confirmation .expandable-header .material-icons:not(.orange-icon) {
    color: #A5A5A5;
    font-size: 20px; }

.preclearance-change-confirmation .expandable-content {
  padding: 16px 24px !important; }
  .preclearance-change-confirmation .expandable-content p {
    margin: 0px; }
  .preclearance-change-confirmation .expandable-content ul {
    padding-left: 24px;
    margin: 0px; }

.preclearance-change-confirmation .help-text {
  color: #505050;
  font-size: 15px; }

.preclearance-change-confirmation li {
  list-style-type: disc;
  margin-bottom: 12px; }
  .preclearance-change-confirmation li:last-of-type {
    margin-bottom: 0px; }

.preclearance-change-confirmation .orange-icon {
  color: #f96c5f;
  margin-right: 8px;
  font-size: 24px !important; }

.notifications .table-cell {
  padding: 5px 0px;
  margin: 0px 48px;
  min-width: 106px; }
  .notifications .table-cell:first-of-type {
    margin-left: 0px;
    margin-right: 24px; }
  .notifications .table-cell:last-of-type {
    margin-right: 0px; }

.notifications .inactive {
  color: #A5A5A5; }

.notifications .toggle-button {
  margin: 0px 32px; }

.settings .auto-actions-wrapper {
  margin: 22px -24px 0px -24px; }
  .settings .auto-actions-wrapper .add-field-button {
    margin: 24px 24px 0px 24px; }

.settings .action-item {
  padding: 10px 0px;
  border-bottom: 1px solid #F0F1F1;
  position: relative;
  color: #676767; }
  .settings .action-item .tooltip-wrapper {
    position: relative; }
    .settings .action-item .tooltip-wrapper button:disabled:hover + .tooltip {
      display: block;
      width: 200px;
      top: -50%;
      bottom: auto; }
  .settings .action-item.editing {
    background: #FFFFFF;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
       -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border-bottom: 0px; }
  .settings .action-item .action-sentence {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .settings .action-item .action-sentence strong {
      display: inline-block;
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
      margin: 0px 8px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    .settings .action-item .action-sentence span {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
  .settings .action-item.inactive {
    color: #A5A5A5; }
  .settings .action-item .error-text, .settings .action-item .formPage form .errorlist, .formPage form .settings .action-item .errorlist {
    padding-left: 24px;
    position: relative; }

.settings .action-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  padding: 0px 24px; }

.settings .action-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .settings .action-buttons .toggle-wrapper {
    margin: 0px 12px;
    position: relative; }
  .settings .action-buttons .text-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0px 2px;
    padding: 10px;
    color: #878787; }
    .settings .action-buttons .text-button:hover {
      color: #505050; }
    .settings .action-buttons .text-button:last-of-type {
      margin-right: 0px; }
  .settings .action-buttons .orange-button {
    font-size: 10px !important;
    padding: 8px 12px !important;
    display: inline-block;
    margin: 0px 12px; }

.settings .toggle-button:disabled:hover + .tooltip {
  display: block;
  top: -100%;
  min-width: 185px; }

.settings .material-icons {
  font-size: 18px; }

.settings .error-wrapper {
  position: absolute;
  top: 50%;
  left: 0px;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .settings .error-wrapper .error-icon {
    color: #878787; }

.settings .dropdown {
  margin: 2px 0px;
  min-width: 200px;
  width: 100%; }

.settings .dropdownButton, .settings .match-button.icon-text-button {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  letter-spacing: normal;
  border: 1px solid #D1D1D1;
  padding: 8px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  font-size: 16px; }
  .settings .dropdownButton i, .settings .match-button.icon-text-button i {
    color: #BDBDBD; }

.settings .dropdown-text {
  margin-right: 8px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }

.settings .dropdown-container {
  background-color: white;
  font-size: 13px;
  text-align: left;
  position: absolute;
  top: -webkit-calc(100%);
  top: -moz-calc(100%);
  top: calc(100%);
  right: 0px;
  width: 100%;
  z-index: 1;
  -webkit-border-bottom-left-radius: 2px;
     -moz-border-radius-bottomleft: 2px;
          border-bottom-left-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
     -moz-border-radius-bottomright: 2px;
          border-bottom-right-radius: 2px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
     -moz-box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
          box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  max-height: 400px;
  overflow: auto;
  left: 0px; }

.settings .option {
  color: #676767;
  display: block;
  padding: 14px 16px;
  font-size: 15px;
  text-align: left;
  border: none;
  width: 100%;
  background-color: white;
  word-break: break-word;
  -webkit-hyphens: auto;
     -moz-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  font-size: 16px;
  padding: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px;
  cursor: pointer; }
  .settings .option:focus, .settings .option:hover:not(:disabled) {
    background-color: #F9FAFC; }
  .settings .option:disabled {
    opacity: 0.4;
    cursor: not-allowed; }

@media (max-width: 1025px) {
  .settings .dropdown {
    min-width: 120px; }
  .settings .action-wrapper {
    font-size: 14px; }
  .settings .option {
    font-size: 14px; }
  #research-page .dropdownButton, #research-page .match-button.icon-text-button {
    font-size: 14px;
    padding: 6px; } }

.questionnaire-page h1 {
  margin-bottom: 0px; }

.questionnaire-page .orange-button {
  margin: 0px; }

.questionnaire-page .help-text {
  margin-bottom: 28px;
  margin-top: 48px;
  font-size: 14px;
  color: #878787;
  line-height: 1.5; }

.questionnaire-page .flex-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative; }

.questionnaire-page .expandable-header {
  background-color: #EDF0F5;
  height: 60px; }
  .questionnaire-page .expandable-header .material-icons {
    font-size: 24px;
    color: #A5A5A5; }
  .questionnaire-page .expandable-header span {
    font-size: 18px; }

.questionnaire-page .expandable-content {
  padding: 0px !important; }

.questionnaire-page .tooltip {
  font-size: 13px;
  display: block;
  text-align: left;
  top: -webkit-calc(100% + 10px);
  top: -moz-calc(100% + 10px);
  top: calc(100% + 10px);
  -webkit-transform: translate(0%, 0%);
     -moz-transform: translate(0%, 0%);
      -ms-transform: translate(0%, 0%);
       -o-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  left: auto;
  right: 0px;
  min-width: 400px;
  padding: 0px; }
  .questionnaire-page .tooltip .arrow.bottom {
    left: -webkit-calc(100% - 67px);
    left: -moz-calc(100% - 67px);
    left: calc(100% - 67px); }
  .questionnaire-page .tooltip svg.bottom {
    left: -webkit-calc(100% - 75px);
    left: -moz-calc(100% - 75px);
    left: calc(100% - 75px); }
  .questionnaire-page .tooltip h2 {
    font-weight: 600;
    background-color: #f3f4f7;
    padding: 24px 32px;
    margin: 0px; }
  .questionnaire-page .tooltip h3 {
    font-weight: 600;
    font-size: 18px;
    margin: 24px 0px 20px 0px;
    padding-bottom: 0px; }
  .questionnaire-page .tooltip .tooltip-body {
    padding: 24px 32px; }
  .questionnaire-page .tooltip .share-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .questionnaire-page .tooltip .share-header span {
      text-transform: uppercase;
      font-weight: 600;
      color: #878787;
      letter-spacing: 0.5px;
      margin-right: 16px; }
    .questionnaire-page .tooltip .share-header .material-icons {
      font-size: 24px; }
    .questionnaire-page .tooltip .share-header button {
      padding: 2px 4px 2px 4px;
      margin: 0px 8px;
      color: #BDBDBD;
      border-bottom: solid 2px transparent;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .questionnaire-page .tooltip .share-header button:hover, .questionnaire-page .tooltip .share-header button:focus {
        color: #676767; }
      .questionnaire-page .tooltip .share-header button.selected {
        color: #202020;
        border-bottom: solid 2px #f96c5f; }
  .questionnaire-page .tooltip label {
    color: #878787;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 2px;
    margin-bottom: 12px;
    margin: 0px;
    padding: 16px 4px 16px 8px; }
  .questionnaire-page .tooltip .input-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    border-bottom: 1px solid #D1D1D1;
    margin: 0px -8px; }
    .questionnaire-page .tooltip .input-wrapper textarea, .questionnaire-page .tooltip .input-wrapper input {
      border: none;
      width: auto;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1;
         -moz-box-flex: 1;
          -ms-flex: 1 1;
              flex: 1 1; }
    .questionnaire-page .tooltip .input-wrapper textarea {
      -webkit-box-flex: 1;
      -webkit-flex: 1 0 100%;
         -moz-box-flex: 1;
          -ms-flex: 1 0 100%;
              flex: 1 0 100%;
      padding-top: 0px;
      min-height: 180px; }
  .questionnaire-page .tooltip textarea, .questionnaire-page .tooltip input {
    resize: none;
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: 1px solid #D1D1D1;
    border-bottom: 1px solid #D1D1D1;
    padding: 16px 8px;
    font-size: 15px; }
  .questionnaire-page .tooltip .code-snippet {
    font-family: 'Roboto Mono', monospace;
    height: 160px; }
  .questionnaire-page .tooltip .button-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-top: 24px; }
    .questionnaire-page .tooltip .button-wrapper .text-button {
      color: #878787;
      margin-right: 24px;
      border-bottom: 1px solid transparent; }
      .questionnaire-page .tooltip .button-wrapper .text-button:hover {
        color: #202020;
        border-color: #202020; }

.preclearance-preview {
  position: relative; }

.email-templates .error {
  color: red;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto; }

.email-templates input {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: 1px solid #ababab;
  font-size: 1rem;
  width: 100%; }
  .email-templates input:active, .email-templates input:focus {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .email-templates input::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .email-templates input::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .email-templates input::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .email-templates input::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .email-templates input.error {
    border-color: #f96c5f; }

.email-templates .edit-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  margin: 0px; }
  .email-templates .edit-button i {
    padding-right: 10px; }
  .email-templates .edit-button span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600; }

.email-templates .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 16.5px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 13px; }
  .email-templates .buttons div {
    color: #bdbdbd;
    cursor: pointer; }
  .email-templates .buttons .save-button {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.5em;
    color: white;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    display: block;
    padding: 12px 24px;
    background-color: #f96c5f;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border: none !important;
    cursor: pointer;
    margin-left: 12px; }
    .email-templates .buttons .save-button:hover {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s;
      color: white; }
    .email-templates .buttons .save-button:hover:enabled {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
    .email-templates .buttons .save-button:hover:disabled {
      background-color: #fb9990; }
    .email-templates .buttons .save-button:disabled {
      background-color: #fb9990;
      cursor: not-allowed; }
    .email-templates .buttons .save-button:focus {
      -webkit-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
      -moz-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
           box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5); }

.email-templates .template-form-container {
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin: 20px 0;
  padding: 20px 32px; }
  .email-templates .template-form-container textarea {
    width: 100%;
    height: 183px;
    padding: 10px 14px;
    font-size: 1rem;
    resize: none;
    color: #676767;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border: 1px solid #ababab; }
  .email-templates .template-form-container .input-wrapper {
    margin-top: 28px; }
  .email-templates .template-form-container .error-text, .email-templates .template-form-container .formPage form .errorlist, .formPage form .email-templates .template-form-container .errorlist {
    padding-left: 0px; }

.macro-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.macro-item {
  font-size: 13px;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  background-color: #edf1f5;
  height: 34px;
  padding: 8px;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  border: none;
  color: #505050; }
  .macro-item:disabled {
    font-style: italic;
    opacity: 0.8;
    cursor: not-allowed; }

.email-template-file-upload .file-container {
  margin-top: 28px; }

.email-template-file-upload .file-item {
  cursor: default; }
  .email-template-file-upload .file-item .file-name {
    max-width: 100%; }

@media (max-width: 1345px) {
  .source-table .dashboard-record .dropdown + .tooltip {
    right: -55px; }
  .source-table .dashboard-record .dashboard-cell.fixedWidth {
    min-width: 108px; }
    .source-table .dashboard-record .dashboard-cell.fixedWidth .dropdownButton .dropdown-text, .source-table .dashboard-record .dashboard-cell.fixedWidth .match-button.icon-text-button .dropdown-text {
      max-width: 62px; } }

@media (max-width: 1195px) {
  .source-table .dashboard-record .dropdown + .tooltip {
    right: -55px; }
  .source-table .dashboard-record .dropdownButton, .source-table .dashboard-record .match-button.icon-text-button {
    padding: 4px 8px; }
  .source-table .dashboard-record .dashboard-cell.fixedWidth {
    min-width: 100px; }
    .source-table .dashboard-record .dashboard-cell.fixedWidth .dropdownButton .dropdown-text, .source-table .dashboard-record .dashboard-cell.fixedWidth .match-button.icon-text-button .dropdown-text {
      max-width: 54px; }
    .source-table .dashboard-record .dashboard-cell.fixedWidth button {
      font-size: 9px; }
    .source-table .dashboard-record .dashboard-cell.fixedWidth:not(.schema-attr) {
      font-size: 10px; }
  .source-table .dashboard-record .dropdown-container .option {
    font-size: 11px;
    padding: 8px; }
    .source-table .dashboard-record .dropdown-container .option i {
      font-size: 10px; }
  .source-table .dashboard-record .dropdown-container .option:not(.selected) {
    padding-left: 22px; } }

@media (max-width: 1080px) {
  .results-header {
    margin-bottom: 36px; }
  .source-row .source-details *:not(:last-child) {
    margin-right: 45px !important; }
  .subcategory-row .subcategory-details span:not(:last-child) {
    margin-right: 45px; }
  .source-table .dashboard-record {
    margin: 0px 18px 0px 4px;
    font-size: 10px; }
    .source-table .dashboard-record .dropdown .tooltip {
      right: -55px; }
    .source-table .dashboard-record .dashboard-cell.fixedWidth:not(.schema-attr) {
      font-size: 9px; }
    .source-table .dashboard-record .dashboard-cell.fixedWidth i.note-button {
      font-size: 18px; } }

@media (max-width: 1025px) {
  .tooltip {
    padding: 12px 16px 12px 16px;
    line-height: normal; }
    .tooltip.note-wrapper {
      padding: 16px 24px 16px 24px; }
  .foot {
    font-size: 10px; }
  .subcategory-container.open {
    padding: 0px 0px 4px 18px; }
    .subcategory-container.open .subcategory-row {
      padding: 28px 18px 16px 0px; }
    .subcategory-container.open .fixed-header {
      top: -webkit-calc(78px + 3px);
      top: -moz-calc(78px + 3px);
      top: calc(78px + 3px); }
  .sidebar-empty p {
    font-size: 12px; }
  .container {
    max-width: 720px !important; }
  .results-details-row {
    font-size: 12px; }
  .noResults {
    font-size: 16px; }
    .noResults .noResultsHeading {
      font-size: 18px;
      margin-bottom: 18px; }
    .noResults i {
      font-size: 24px; }
  .schema-attr .sort-icon, .sort-icon {
    font-size: 16px; }
  .subcategory-row {
    padding: 28px 18px; }
  .alpha-selector-toggle {
    height: 38px; }
  .body {
    font-size: 12px; }
  .orange-button {
    font-size: 12px; }
  .results-container {
    margin-right: 4px; }
  .sidebar {
    margin-right: 4px; }
  .default {
    font-size: 16px !important; }
  .sidebar-search-container input {
    font-size: 11px; }
  .sidebar-search-container .material-icons {
    font-size: 18px; }
  .error-text, .formPage form .errorlist {
    font-size: 10px; }
  .modal-box {
    font-size: 11px;
    width: 100%;
    max-width: 100%;
    min-width: auto !important; }
    .modal-box .modal-close .material-icons {
      font-size: 18px; }
  .form-wrapper .form-heading {
    font-size: 18px; }
  .form-wrapper .helptext {
    font-size: 11px; }
  .results-details-content {
    font-size: 11px; }
  .results-name-row .text-button {
    font-size: 11px; }
  .results-name-row h4 {
    font-size: 20px; }
  .subcategory-row {
    font-size: 14px; }
    .subcategory-row i {
      font-size: 20px; }
    .subcategory-row h2 {
      font-size: 16px; }
  .source-table {
    font-size: 11px; }
    .source-table .dashboard-record .dropdown + .tooltip {
      right: -67px; }
    .source-table .dashboard-record .dashboard-cell.fixedWidth {
      min-width: 78px; }
      .source-table .dashboard-record .dashboard-cell.fixedWidth .dropdownButton .dropdown-text, .source-table .dashboard-record .dashboard-cell.fixedWidth .match-button.icon-text-button .dropdown-text {
        max-width: 32px; }
  .source-row {
    padding: 20px 18px 20px 0px; }
    .source-row.fixed-header {
      padding: 20px 18px 20px 0px; }
    .source-row h3 {
      font-size: 13px; }
    .source-row .source-details {
      font-size: 10px; }
    .source-row i {
      font-size: 20px; }
  .schema-attr {
    font-size: 11px; }
  .result-row .result-cell {
    font-size: 10px;
    padding: 14px 4px 14px 4px; }
  .results-details-row .filters-span {
    margin-top: 16px; }
  .source-table .dashboard-record .tooltip {
    font-size: 10px; }
  .schema-attr {
    font-size: 10px; }
    .schema-attr .tooltip.top {
      top: -24px; }
  .sidebar {
    width: 220px;
    font-size: 12px; }
    .sidebar .new-button-container {
      width: 220px;
      font-size: 12px; }
      .sidebar .new-button-container .tooltip {
        top: -webkit-calc(-100% + 5px);
        top: -moz-calc(-100% + 5px);
        top: calc(-100% + 5px);
        width: 220px;
        font-size: 11px; }
    .sidebar .tabs {
      font-size: 12px; }
    .sidebar .sidebar-search-container {
      padding: 14px;
      margin: 0px 14px; }
      .sidebar .sidebar-search-container .material-icons {
        font-size: 18px; }
      .sidebar .sidebar-search-container input {
        font-size: 11px; }
    .sidebar .alpha-selector-toggle {
      padding: 10px 14px;
      margin: 0px 14px; }
    .sidebar .alpha-selector-icon .material-icons {
      font-size: 16px; }
    .sidebar .new-button-wrapper .text-button {
      font-size: 11px;
      padding: 12px 0px; }
    .sidebar .new-button-wrapper .icon-add-person, .sidebar .new-button-wrapper {
      font-size: 16px; }
    .sidebar .new-button-wrapper .icon-add-company {
      font-size: 12px;
      margin-right: 16px; }
  .dashboard-record .checkbox input[type=checkbox] + label:before {
    font-size: 11px; }
  .dashboard-record .dashboard-cell.fixedWidth button {
    font-size: 10px; }
  .monitoring-sidebar-body {
    width: -webkit-calc(100% - 220px) !important;
    width: -moz-calc(100% - 220px) !important;
    width: calc(100% - 220px) !important;
    margin-left: 220px !important; } }

.match-button.icon-text-button {
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
     -moz-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
  padding: 7px 8px;
  color: #505050; }
  .match-button.icon-text-button span {
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block; }
  .match-button.icon-text-button i {
    margin-right: 4px; }
  .match-button.icon-text-button:hover:disabled + .tooltip {
    min-width: 190px;
    top: -10px;
    left: 50%;
    display: block;
    -webkit-transform: translate(-50%, -100%);
       -moz-transform: translate(-50%, -100%);
        -ms-transform: translate(-50%, -100%);
         -o-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    padding: 8px 12px;
    font-size: 12px;
    text-align: left; }
    .match-button.icon-text-button:hover:disabled + .tooltip .message {
      display: none; }
  .match-button.icon-text-button.default-match-button {
    background-color: #ECEDF0;
    border: 1px solid #505050;
    padding: 6px 8px; }
    .match-button.icon-text-button.default-match-button .material-icons, .match-button.icon-text-button.default-match-button:before {
      color: #BDBDBD; }
    .match-button.icon-text-button.default-match-button:hover:not(:disabled), .match-button.icon-text-button.default-match-button:focus {
      color: #202020;
      background-color: #dee0e5; }
      .match-button.icon-text-button.default-match-button:hover:not(:disabled) .material-icons, .match-button.icon-text-button.default-match-button:hover:not(:disabled):before, .match-button.icon-text-button.default-match-button:focus .material-icons, .match-button.icon-text-button.default-match-button:focus:before {
        color: #878787; }
  .match-button.icon-text-button.has-match {
    background-color: #ECEDF0; }
    .match-button.icon-text-button.has-match .material-icons, .match-button.icon-text-button.has-match:before {
      color: #BDBDBD; }
    .match-button.icon-text-button.has-match:hover:not(:disabled), .match-button.icon-text-button.has-match:focus {
      color: #202020;
      background-color: #dee0e5; }
      .match-button.icon-text-button.has-match:hover:not(:disabled) .material-icons, .match-button.icon-text-button.has-match:hover:not(:disabled):before, .match-button.icon-text-button.has-match:focus .material-icons, .match-button.icon-text-button.has-match:focus:before {
        color: #878787; }
    .match-button.icon-text-button.has-match:disabled {
      opacity: 1;
      cursor: auto; }
      .match-button.icon-text-button.has-match:disabled:hover + .tooltip {
        display: none; }
  .match-button.icon-text-button.no-match {
    background-color: transparent;
    padding: 6px 8px;
    color: #f96c5f;
    border: 1px solid #f96c5f; }
    .match-button.icon-text-button.no-match .material-icons, .match-button.icon-text-button.no-match:before {
      color: #f96c5f;
      opacity: 0.6; }
    .match-button.icon-text-button.no-match:hover:not(:disabled), .match-button.icon-text-button.no-match:focus {
      color: #f85142;
      border-color: #f85142; }
      .match-button.icon-text-button.no-match:hover:not(:disabled) .material-icons, .match-button.icon-text-button.no-match:hover:not(:disabled):before, .match-button.icon-text-button.no-match:focus .material-icons, .match-button.icon-text-button.no-match:focus:before {
        color: #f96c5f;
        opacity: 0.8; }
    .match-button.icon-text-button.no-match:disabled {
      opacity: 1;
      cursor: auto; }
      .match-button.icon-text-button.no-match:disabled:hover + .tooltip {
        display: none; }
  .match-button.icon-text-button.automatch {
    background-color: #f96c5f;
    color: white; }
    .match-button.icon-text-button.automatch .material-icons {
      color: white;
      opacity: 0.8; }
    .match-button.icon-text-button.automatch:hover:not(:disabled), .match-button.icon-text-button.automatch:focus {
      color: white;
      background-color: #f85142; }
      .match-button.icon-text-button.automatch:hover:not(:disabled) .material-icons, .match-button.icon-text-button.automatch:focus .material-icons {
        color: white;
        opacity: 0.8; }

.button-wrapper {
  position: relative; }

.matching-container {
  padding: 32px 48px;
  position: relative; }
  .matching-container h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0px 0px 24px 0px; }

.match-buttons.buttons-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 13px;
  padding-top: 24px;
  margin: 0px -12px; }
  .match-buttons.buttons-wrapper .button-wrapper {
    padding: 0; }
  .match-buttons.buttons-wrapper button {
    margin: 0px 12px; }
  .match-buttons.buttons-wrapper .text-button {
    color: #878787; }
    .match-buttons.buttons-wrapper .text-button:hover:not(:disabled) {
      color: #676767; }
  .match-buttons.buttons-wrapper .no-match-button {
    border: 1px solid #BDBDBD !important;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    color: #878787; }
    .match-buttons.buttons-wrapper .no-match-button i, .match-buttons.buttons-wrapper .no-match-button:before {
      color: #BDBDBD; }
    .match-buttons.buttons-wrapper .no-match-button:hover:not(:disabled) {
      border-color: #878787 !important;
      color: #676767; }
      .match-buttons.buttons-wrapper .no-match-button:hover:not(:disabled) i, .match-buttons.buttons-wrapper .no-match-button:hover:not(:disabled):before {
        color: #878787; }
    .match-buttons.buttons-wrapper .no-match-button:hover:disabled + .tooltip {
      text-align: left;
      display: block;
      top: -10px;
      left: 50%;
      -webkit-transform: translate(-50%, -100%);
         -moz-transform: translate(-50%, -100%);
          -ms-transform: translate(-50%, -100%);
           -o-transform: translate(-50%, -100%);
              transform: translate(-50%, -100%);
      min-width: 100%;
      padding: 8px 12px; }
      .match-buttons.buttons-wrapper .no-match-button:hover:disabled + .tooltip .message {
        display: none; }
  .match-buttons.buttons-wrapper .orange-match-button {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.5em;
    color: white;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    display: block;
    padding: 12px 24px;
    background-color: #f96c5f;
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    border: none !important;
    cursor: pointer;
    margin: 0px 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
    .match-buttons.buttons-wrapper .orange-match-button:hover {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s;
      color: white; }
    .match-buttons.buttons-wrapper .orange-match-button:hover:enabled {
      background-color: #f85142;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
    .match-buttons.buttons-wrapper .orange-match-button:hover:disabled {
      background-color: #fb9990; }
    .match-buttons.buttons-wrapper .orange-match-button:disabled {
      background-color: #fb9990;
      cursor: not-allowed; }
    .match-buttons.buttons-wrapper .orange-match-button:focus {
      -webkit-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
      -moz-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
           box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5); }
    .match-buttons.buttons-wrapper .orange-match-button i {
      color: white;
      opacity: 0.8; }
    .match-buttons.buttons-wrapper .orange-match-button:hover:not(:disabled) {
      color: white; }
      .match-buttons.buttons-wrapper .orange-match-button:hover:not(:disabled) i {
        color: white;
        opacity: 0.8; }

@media (max-width: 1025px) {
  .dashboard-record-container .matching-container .buttons-wrapper button, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .matching-container .buttons-wrapper button, .result-section-container .matching-container .buttons-wrapper button {
    font-size: 12px;
    padding: 8px 16px; } }

.matching-container .ReactVirtualized__Grid, .matching-container .ReactVirtualized__Grid__innerScrollContainer {
  overflow: hidden auto !important; }

.matching-container .ReactVirtualized__Grid__innerScrollContainer {
  min-height: auto; }

.matching-container .loading-wrapper {
  border-top: 1px solid #E8E7EA; }

.matching-container .table-load-screen {
  height: -webkit-calc(100% - 33px);
  height: -moz-calc(100% - 33px);
  height: calc(100% - 33px);
  top: 33px; }

.matching-container .preclearance-row {
  overflow: visible !important; }

.matching-container .preclearance-header-cell-wrapper.last .preclearance-header-cell {
  border-right: 0px; }

.matching-container .preclearance-header-cell {
  height: 100%;
  border-bottom: 1px solid #D1D1D1;
  padding: 8px 18px; }
  .matching-container .preclearance-header-cell:first-of-type {
    margin-left: 0px; }
  .matching-container .preclearance-header-cell:active {
    cursor: auto;
    background-color: #f0f2f5;
    border-right: 1px solid #D1D1D1;
    -webkit-box-shadow: none !important;
       -moz-box-shadow: none !important;
            box-shadow: none !important;
    color: #878787; }
  .matching-container .preclearance-header-cell.sort {
    border-bottom: 3px solid #f96c5f;
    padding-bottom: 7px; }
  .matching-container .preclearance-header-cell .material-icons {
    color: #f96c5f;
    padding: 0px; }

.matching-container .preclearance-cell input {
  width: auto; }

.matching-container .preclearance-cell .button-wrapper {
  padding: 0px; }

.matching-container .preclearance-cell:last-child {
  border-right: none; }

.matching-container .radio-button {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .matching-container .radio-button label {
    width: auto;
    padding: 6px; }
  .matching-container .radio-button .tooltip {
    left: 48px;
    min-width: 180px;
    font-size: 12px;
    padding: 8px;
    white-space: normal; }
    .matching-container .radio-button .tooltip ul {
      display: none; }

.matching-container input[type=radio] {
  position: absolute; }

.matching-container input[type=radio] + label:before {
  position: relative;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
       -o-transform: none;
          transform: none;
  left: auto; }

.matching-container input[type=radio]:checked + label:before {
  color: #505050; }

.matching-container input[type=radio]:focus + label:before {
  background-color: rgba(80, 80, 80, 0.3); }

.matching-container input[type=radio]:disabled:hover + label + .tooltip {
  display: block; }

.matching-container .noResults {
  height: 100%;
  font-size: 16px; }
  .matching-container .noResults .noResultsHeading {
    font-size: 18px; }
  .matching-container .noResults i {
    font-size: 24px; }

.matching-container .has-match {
  font-size: 12px; }

@media (max-width: 1080px) {
  .matching-container .preclearance-header-cell {
    font-size: 11px; }
  .matching-container .preclearance-cell {
    font-size: 12px; } }

.match-cell {
  position: relative; }

.view-match__container {
  display: block;
  -webkit-transform: none;
     -moz-transform: none;
      -ms-transform: none;
       -o-transform: none;
          transform: none;
  z-index: auto; }
  .view-match__container .arrow, .view-match__container svg.bottom {
    left: auto;
    z-index: 1; }
  .view-match__container .heading {
    color: #878787;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 2px;
    margin-bottom: 12px;
    margin-left: 0px;
    font-size: 12px; }
  .view-match__container .dashboard-cell {
    padding: 0 10px; }

.view-match__container__records {
  max-height: 200px;
  overflow: auto; }

.view-match__container__flex-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin: 0px -16px -12px; }
  .view-match__container__flex-row button.icon-text-button {
    font-size: 13px; }
    .view-match__container__flex-row button.icon-text-button i {
      font-size: 24px; }

.row-card {
  background-color: #F9FAFC;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: 2px solid #F0F1F1;
  padding: 16px 6px;
  color: #464a4c;
  font-size: 12px;
  margin-bottom: 24px; }
  .row-card:last-of-type {
    margin-bottom: 4px; }
  .row-card .note {
    color: #878787;
    font-size: 11px;
    margin-top: 12px; }

.row-card__heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0px 10px;
  margin-bottom: 16px; }
  .row-card__heading div:first-child {
    font-size: 16px;
    font-weight: 600;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .row-card__heading .status {
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    background-color: #d7d9e0;
    padding: 2px 8px;
    font-size: 11px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    margin-left: 24px; }
  .row-card__heading .orange-button {
    background: #f96c5f;
    color: white;
    padding: 4px 10px;
    font-size: 11px; }
    .row-card__heading .orange-button i {
      color: white; }
    .row-card__heading .orange-button:focus, .row-card__heading .orange-button:hover:not(:disabled) {
      color: white; }
      .row-card__heading .orange-button:focus i, .row-card__heading .orange-button:hover:not(:disabled) i {
        color: white; }

.row-card__schema {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #A5A5A5;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 8px;
  -webkit-hyphens: auto;
     -moz-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  word-break: break-word; }

.row-card__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-hyphens: auto;
     -moz-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  word-break: break-word; }

@media (max-width: 1025px) {
  .view-match__container .heading {
    font-size: 11px; }
  .row-card {
    padding: 12px 4px;
    margin-bottom: 16px; }
  .row-card__heading {
    padding: 0px 6px;
    margin-bottom: 12px; }
    .row-card__heading div:first-child {
      font-size: 14px; }
    .row-card__heading .status {
      font-size: 10px;
      margin-left: 16px; }
  .row-card__schema {
    font-size: 10px; } }

.matching-container .expandable-section {
  margin-bottom: 32px;
  height: auto; }
  .matching-container .expandable-section .expandable-header {
    background-color: #ECEDF0;
    padding: 16px 24px; }
    .matching-container .expandable-section .expandable-header span {
      color: #505050; }
  .matching-container .expandable-section .expandable-content {
    padding: 24px;
    background-color: white; }

@media (max-width: 1025px) {
  .matching-container .expandable-section .expandable-header {
    padding: 10px 16px;
    height: auto; }
  .matching-container .expandable-section .expandable-content {
    padding: 16px; } }

.preclearance-match-filters {
  margin-bottom: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .preclearance-match-filters .input-wrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-right: 48px; }
  .preclearance-match-filters label {
    color: #878787;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 2px;
    margin-bottom: 12px;
    font-size: 12px;
    margin-bottom: 8px; }
  .preclearance-match-filters input, .preclearance-match-filters .dropdownButton, .preclearance-match-filters .match-button.icon-text-button {
    padding: 12px 16px;
    color: #676767;
    -webkit-border-radius: 2px 2px 0 0;
       -moz-border-radius: 2px 2px 0 0;
            border-radius: 2px 2px 0 0;
    border: none;
    border-bottom: 1px solid #D1D1D1;
    font-size: 1rem;
    background-color: white;
    font-weight: normal;
    text-transform: none; }
    .preclearance-match-filters input:active:not(:disabled):not([readonly]), .preclearance-match-filters input:focus:not(:disabled):not([readonly]), .preclearance-match-filters .dropdownButton:active:not(:disabled):not([readonly]), .preclearance-match-filters .match-button.icon-text-button:active:not(:disabled):not([readonly]), .preclearance-match-filters .dropdownButton:focus:not(:disabled):not([readonly]), .preclearance-match-filters .match-button.icon-text-button:focus:not(:disabled):not([readonly]) {
      color: #505050;
      border-color: #505050;
      outline: none; }
    .preclearance-match-filters input::-webkit-input-placeholder, .preclearance-match-filters .dropdownButton::-webkit-input-placeholder, .preclearance-match-filters .match-button.icon-text-button::-webkit-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .preclearance-match-filters input::-moz-placeholder, .preclearance-match-filters .dropdownButton::-moz-placeholder, .preclearance-match-filters .match-button.icon-text-button::-moz-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .preclearance-match-filters input::-ms-input-placeholder, .preclearance-match-filters .dropdownButton::-ms-input-placeholder, .preclearance-match-filters .match-button.icon-text-button::-ms-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .preclearance-match-filters input::placeholder, .preclearance-match-filters .dropdownButton::placeholder, .preclearance-match-filters .match-button.icon-text-button::placeholder {
      color: rgba(32, 32, 32, 0.3); }
    .preclearance-match-filters input.error, .preclearance-match-filters .dropdownButton.error, .preclearance-match-filters .error.match-button.icon-text-button {
      border-color: #f96c5f; }
    .preclearance-match-filters input:disabled, .preclearance-match-filters input[readonly], .preclearance-match-filters .dropdownButton:disabled, .preclearance-match-filters .match-button.icon-text-button:disabled, .preclearance-match-filters .dropdownButton[readonly], .preclearance-match-filters .match-button.icon-text-button[readonly] {
      opacity: 0.8;
      font-style: italic;
      cursor: not-allowed;
      background: #f2f2f2; }
    .preclearance-match-filters input .material-icons, .preclearance-match-filters .dropdownButton .material-icons, .preclearance-match-filters .match-button.icon-text-button .material-icons {
      font-size: 22px; }
  .preclearance-match-filters .dropdown-container {
    max-height: 300px; }
    .preclearance-match-filters .dropdown-container .option {
      font-size: 15px; }
      .preclearance-match-filters .dropdown-container .option:not(.selected) {
        padding-left: 27px; }
      .preclearance-match-filters .dropdown-container .option .material-icons {
        vertical-align: middle;
        color: #f96c5f;
        font-size: 14px;
        margin-right: 4px; }
  .preclearance-match-filters .orange-button {
    margin: 2px 0px; }

.dashboard-record-container .preclearance-match-filters input, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .preclearance-match-filters input, .dashboard-record-container .preclearance-match-filters .dropdownButton, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .preclearance-match-filters .dropdownButton, .dashboard-record-container .preclearance-match-filters .match-button.icon-text-button, .dashboard-wrapper + .modal-container .dashboard-styles-wrapper .preclearance-match-filters .match-button.icon-text-button {
  background-color: #F9FAFC; }

@media (max-width: 1080px) {
  .preclearance-match-filters .input-wrapper {
    margin-right: 24px; }
  .preclearance-match-filters label {
    font-size: 11px;
    margin-bottom: 6px; }
  .preclearance-match-filters input, .preclearance-match-filters .dropdownButton, .preclearance-match-filters .match-button.icon-text-button {
    padding: 10px 14px;
    font-size: 14px; }
    .preclearance-match-filters input .material-icons, .preclearance-match-filters .dropdownButton .material-icons, .preclearance-match-filters .match-button.icon-text-button .material-icons {
      font-size: 18px; }
  .preclearance-match-filters .orange-button {
    padding: 8px 16px; } }

.matching-toggle-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px; }
  .matching-toggle-wrapper .labeled-toggle {
    margin-right: 32px; }
  .matching-toggle-wrapper .input-title, .matching-toggle-wrapper .archive-delay-form label, .archive-delay-form .matching-toggle-wrapper label {
    font-size: 12px; }
  .matching-toggle-wrapper .toggle-button {
    width: 34px;
    height: 16px; }
    .matching-toggle-wrapper .toggle-button .circle {
      top: -2px;
      height: 10px;
      width: 10px; }
    .matching-toggle-wrapper .toggle-button span:not(.circle) {
      display: none; }

.matching-modal {
  padding: 36px 48px;
  background: #F9FAFC; }
  .matching-modal hr {
    margin: 32px 0px;
    border: none;
    height: 2px;
    background-color: #ECEDF0; }
  .matching-modal h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0px 0px 24px 0px; }
  .matching-modal .preclearance-table-container {
    min-height: auto; }
  .matching-modal.on-monitoring .ReactVirtualized__Grid.ReactVirtualized__List {
    height: auto !important; }
  .matching-modal.on-monitoring .source-table .schema-attr {
    cursor: auto; }
  .matching-modal.on-preclearance .preclearance-table-container .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
    height: 50px !important; }
  .matching-modal.on-preclearance .preclearance-table-container .ReactVirtualized__Table__headerColumn:first-of-type {
    margin-left: 0px; }
  .matching-modal.on-preclearance .preclearance-header-cell-wrapper.last .preclearance-header-cell {
    border-right: none; }
  .matching-modal.on-preclearance .preclearance-header-cell {
    padding: 8px 18px;
    height: auto;
    cursor: auto; }
    .matching-modal.on-preclearance .preclearance-header-cell:active {
      cursor: auto;
      background-color: #f0f2f5;
      border-right: 1px solid #D1D1D1;
      -webkit-box-shadow: none !important;
         -moz-box-shadow: none !important;
              box-shadow: none !important;
      color: #878787; }

.dashboard-wrapper + .modal-container .dashboard-styles-wrapper {
  margin: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

.dashboard-wrapper + .modal-container .record-matching-container.source-table {
  border: none;
  margin-bottom: 0px; }
  .dashboard-wrapper + .modal-container .record-matching-container.source-table .dashboard-record {
    padding-bottom: 0px; }
  .dashboard-wrapper + .modal-container .record-matching-container.source-table .schema {
    padding-bottom: 0px; }
  .dashboard-wrapper + .modal-container .record-matching-container.source-table .schema-attr {
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 0px;
    height: auto; }

.dashboard-wrapper + .modal-container .jurisdiction, .dashboard-wrapper + .modal-container .Jurisdiction {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

.dashboard-styles-wrapper .record-icon {
  display: none; }

.dashboard-styles-wrapper .jurisdiction, .dashboard-styles-wrapper .Jurisdiction {
  display: none; }

.record-matching-container.source-table {
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: solid 1px #e8eded;
  background-color: white;
  padding-left: 0px; }
  .record-matching-container.source-table .load-screen {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute; }
  .record-matching-container.source-table .noResults {
    height: 100%; }
  .record-matching-container.source-table .ReactVirtualized__Table__rowColumn {
    white-space: initial; }
  .record-matching-container.source-table .ReactVirtualized__Grid__innerScrollContainer div:last-child .dashboard-record {
    border-bottom: 0px; }
  .record-matching-container.source-table .dashboard-record {
    margin: 0px 24px; }
    .record-matching-container.source-table .dashboard-record.schema {
      border-bottom: 0px; }
  .record-matching-container.source-table .dashboard-cell.selection_col, .record-matching-container.source-table .dashboard-cell[data-sort='selection_col'] {
    max-width: 56px; }
  .record-matching-container.source-table .dashboard-cell .button-wrapper {
    padding: 0px; }
  .record-matching-container.source-table .dashboard-cell.fixedWidth {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    min-width: auto !important; }
  .record-matching-container.source-table .dashboard-cell:not(.schema-attr).status span, .record-matching-container.source-table .dashboard-cell:not(.schema-attr).contribution_status span {
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    background: #ECEDF0;
    padding: 6px 8px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px; }
  .record-matching-container.source-table .has-match {
    padding: 6px 8px; }
    .record-matching-container.source-table .has-match .material-icons {
      font-size: 15px; }

.simple-table-wrapper {
  padding: 0 12px;
  border: 1px solid #e8eded;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  background: white;
  overflow: auto;
  max-width: 100%; }
  .simple-table-wrapper table {
    border-collapse: collapse;
    position: relative;
    display: inline-table; }
    .simple-table-wrapper table thead th {
      padding: 12px;
      border-bottom: 2px solid #F0F1F1;
      font-weight: 600;
      font-size: 11px;
      text-transform: uppercase;
      white-space: nowrap;
      text-align: left;
      color: #949494;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: white;
      letter-spacing: 0.5px; }
    .simple-table-wrapper table tbody tr {
      border-bottom: 1px solid #e8eded; }
      .simple-table-wrapper table tbody tr:last-of-type {
        border-bottom: none; }
    .simple-table-wrapper table tbody td {
      color: #464a4c;
      font-size: 13px;
      padding: 24px 12px; }
  .simple-table-wrapper.empty {
    padding: 24px;
    color: #A5A5A5;
    text-align: center; }

.stepper-wrapper {
  width: 100%; }

.stepper-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.stepper-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background-color: white;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1; }

.stepper-nav-selector {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  .stepper-nav-selector .stepper-nav-cell {
    cursor: pointer;
    min-height: 50px;
    padding: 15px 50px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-right: 1px solid #F0F1F1;
    min-width: 200px; }
    .stepper-nav-selector .stepper-nav-cell .step-label {
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 600;
      color: #BDBDBD;
      margin-bottom: 5px;
      cursor: inherit; }
    .stepper-nav-selector .stepper-nav-cell span {
      font-size: 14px;
      text-transform: uppercase;
      color: #878787;
      font-weight: 600;
      cursor: inherit; }
    .stepper-nav-selector .stepper-nav-cell.active {
      border-bottom: solid 3px #f96c5f; }
      .stepper-nav-selector .stepper-nav-cell.active span {
        color: #202020; }
      .stepper-nav-selector .stepper-nav-cell.active .step-label {
        color: #878787; }
    .stepper-nav-selector .stepper-nav-cell:disabled {
      cursor: not-allowed; }
    .stepper-nav-selector .stepper-nav-cell:not(.active) {
      border-bottom: solid 3px transparent; }
    .stepper-nav-selector .stepper-nav-cell:hover:not(.active):not(:disabled) {
      border-bottom: solid 3px #F88B82; }
      .stepper-nav-selector .stepper-nav-cell:hover:not(.active):not(:disabled) span {
        color: #676767; }

.stepper-nav-toolbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 22px 28px; }
  .stepper-nav-toolbar button {
    margin: 0px 12px;
    text-transform: uppercase;
    font-size: 0.8rem; }

.form-builder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

.form-builder-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  background: white;
  padding: 48px 24px;
  height: -webkit-calc(100vh - 78px - 40px - 73px);
  height: -moz-calc(100vh - 78px - 40px - 73px);
  height: calc(100vh - 78px - 40px - 73px);
  overflow: auto; }
  .form-builder-body .survey-title-header {
    margin: 0px 37px 24px; }
    .form-builder-body .survey-title-header .form-input-hidden-wrapper {
      margin: 0px -37px; }
  .form-builder-body .error-text, .form-builder-body .formPage form .errorlist, .formPage form .form-builder-body .errorlist {
    font-size: 12px; }

.form-body-droppable {
  min-height: 100px;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  padding: 12px; }
  .form-body-droppable.active {
    background-color: #F7F7F8; }

.form-body-empty {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #BDBDBD;
  border: 1px dashed #D1D1D1;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  min-height: 200px;
  margin: 0px 25px;
  height: 1px; }

.form-preview {
  height: -webkit-calc(100vh - 78px - 40px - 73px);
  height: -moz-calc(100vh - 78px - 40px - 73px);
  height: calc(100vh - 78px - 40px - 73px);
  overflow: auto;
  background-color: #F9FAFC; }

.form-builder-sidebar {
  padding: 24px 32px;
  width: 275px;
  overflow: auto;
  height: -webkit-calc(100vh - 78px - 40px - 73px);
  height: -moz-calc(100vh - 78px - 40px - 73px);
  height: calc(100vh - 78px - 40px - 73px); }

.sidebar-heading {
  color: #878787;
  font-size: 13px;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .sidebar-heading i {
    color: #D1D1D1;
    margin-left: 8px;
    font-size: 18px; }

.form-builder-sidebar-section {
  margin-bottom: 48px; }

.question-type-item {
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  border: 1px solid #e1e3e8;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px;
  margin-bottom: 16px;
  font-size: 14px; }
  .question-type-item div:first-of-type {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  .question-type-item.dragging {
    background-color: white;
    border-color: white;
    -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing; }
  .question-type-item:hover:not(.clone):not(.disabled), .question-type-item:focus:not(.disabled) {
    background-color: white;
    border-color: white;
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s; }
  .question-type-item.disabled {
    opacity: 0.5; }

.question-type-label {
  font-weight: 600;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1; }

.question-type-icon {
  color: #A5A5A5;
  margin-right: 12px; }

.drag-indicator {
  color: #D1D1D1; }

.dragging {
  background-color: white;
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
     -moz-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing; }

.body-question-item {
  cursor: default !important;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  border: 1px solid transparent;
  margin: 24px 0px; }
  .body-question-item:hover:not(.editing), .body-question-item:focus:not(.editing) {
    border: 1px solid #e1e3e8; }
    .body-question-item:hover:not(.editing) .horizontal-drag-icon, .body-question-item:focus:not(.editing) .horizontal-drag-icon {
      display: block;
      cursor: pointer; }
  .body-question-item.editing {
    -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
       -moz-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); }
    .body-question-item.editing .horizontal-drag-icon {
      display: block;
      cursor: pointer; }

.question-inner {
  padding: 24px; }
  .question-inner .md-question {
    margin-top: 0px; }
  .question-inner .searchable__menu {
    z-index: 2; }

.drag-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative; }

.horizontal-drag-icon {
  -webkit-transform: rotate(90deg);
     -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
       -o-transform: rotate(90deg);
          transform: rotate(90deg);
  color: #D1D1D1;
  position: absolute;
  display: none;
  top: -20px; }

.form-builder-body .radio-ul input[type=radio]:after {
  background: #f9fafc !important;
  margin-bottom: 0px;
  font-size: 15px !important; }

.form-builder-body .radio-ul input[type=radio]:checked:after {
  background: #f9fafc !important; }

.checkbox-question-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .checkbox-question-wrapper input {
    padding: 8px 2px; }

.question-option {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 16px 0px; }

.conditional-notice {
  font-size: 11px;
  color: #BDBDBD;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .conditional-notice i {
    font-size: 14px;
    margin-right: 4px; }

.conditional-field-tooltip {
  font-size: 13px; }

.form-builder-header {
  background-color: white;
  border-bottom: 1px solid #D1D1D1;
  padding: 12px 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .form-builder-header .icon-text-button {
    font-size: 13px; }

.form-header-name {
  color: #505050;
  font-size: 0.8rem;
  margin-left: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .form-header-name .edit-button {
    opacity: 0;
    padding: 8px; }
    .form-header-name .edit-button:focus {
      opacity: 1; }
  .form-header-name:hover .edit-button {
    opacity: 1; }

.form-header-publish-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .form-header-publish-group *:not(:last-child) {
    margin-right: 15px; }
  .form-header-publish-group > :last-child {
    margin-left: 15px; }

.publish-status-icon {
  color: #D1D1D1;
  margin-right: 4px !important;
  font-size: 18px; }

.publish-status {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 16px;
  font-size: 13px; }

.bottom-toolbar-wrapper {
  border-top: 1px solid #F0F1F1;
  margin-top: 36px; }
  .bottom-toolbar-wrapper .edit-question-toolbar {
    margin-top: 8px;
    margin-bottom: -12px; }

.conditional-sentence-wrapper {
  margin: 0px 8px 12px; }
  .conditional-sentence-wrapper .error-text, .conditional-sentence-wrapper .formPage form .errorlist, .formPage form .conditional-sentence-wrapper .errorlist {
    margin-left: 8px; }

.conditional-sentence {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px; }
  .conditional-sentence .select-wrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1; }
  .conditional-sentence > span {
    margin: 8px; }

.editor-wrapper {
  margin-bottom: 24px; }

.hide-placeholder .public-DraftEditorPlaceholder-root {
  display: none; }

.style-buttons {
  display: inline-block;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
     -moz-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); }

.style-button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none !important;
  padding: 6px 8px;
  margin: 0px;
  text-decoration: none;
  color: #878787;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .style-button:hover {
    color: #676767;
    background-color: #e8eded; }
  .style-button.active {
    color: #202020;
    background-color: #e8eded; }
  .style-button i {
    font-size: 18px; }

.editable-option-wrapper {
  margin: 12px 0px; }
  .editable-option-wrapper .error-text, .editable-option-wrapper .formPage form .errorlist, .formPage form .editable-option-wrapper .errorlist {
    margin: 0px 0px 0px 55px; }

.editable-option {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .editable-option input {
    border-bottom: 1px solid transparent;
    padding: 8px 2px; }
    .editable-option input:hover {
      border-bottom: 1px solid #D1D1D1; }
    .editable-option input:focus {
      border-bottom: 1px solid #505050; }
  .editable-option .drag-indicator {
    color: #D1D1D1; }

.option-icon {
  margin-right: 8px;
  color: #BDBDBD; }

.remove-option {
  color: #878787; }

.edit-question-toolbar-wrapper {
  margin-bottom: 24px; }

.edit-question-toolbar {
  margin: 0px -16px; }
  .edit-question-toolbar .icon-text-button {
    font-size: 13px; }
    .edit-question-toolbar .icon-text-button i {
      font-size: 24px; }

.toolbar-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.question-dropdown {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  margin: 0px 16px;
  max-width: 250px;
  cursor: pointer; }
  .question-dropdown .question-type-label {
    font-size: 13px; }

.icon-text-button.toggle-active i {
  color: #f96c5f; }

.icon-text-button.toggle-active:hover:not(:disabled) i, .icon-text-button.toggle-active:focus i {
  color: #f85142; }

.form-label-input {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px 2px 0 0;
     -moz-border-radius: 2px 2px 0 0;
          border-radius: 2px 2px 0 0;
  border: none;
  border-bottom: 1px solid #D1D1D1;
  font-size: 1rem;
  background-color: white;
  width: 100%; }
  .form-label-input:active:not(:disabled):not([readonly]), .form-label-input:focus:not(:disabled):not([readonly]) {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .form-label-input::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-label-input::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-label-input::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-label-input::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-label-input.error {
    border-color: #f96c5f; }
  .form-label-input:disabled, .form-label-input[readonly] {
    opacity: 0.8;
    font-style: italic;
    cursor: not-allowed;
    background: #f2f2f2; }
  .form-label-input:focus-within {
    color: #505050;
    border-color: #505050; }

.form-input-hidden-wrapper {
  padding: 0px 21px; }
  .form-input-hidden-wrapper label {
    display: none; }
  .form-input-hidden-wrapper .error-text, .form-input-hidden-wrapper .formPage form .errorlist, .formPage form .form-input-hidden-wrapper .errorlist {
    margin: 0px; }

.form-input-hidden {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px 2px 0 0;
     -moz-border-radius: 2px 2px 0 0;
          border-radius: 2px 2px 0 0;
  border: none;
  border-bottom: 1px solid #D1D1D1;
  font-size: 1rem;
  background-color: white;
  border: none;
  resize: none;
  width: 100%; }
  .form-input-hidden:active:not(:disabled):not([readonly]), .form-input-hidden:focus:not(:disabled):not([readonly]) {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .form-input-hidden::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-input-hidden::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-input-hidden::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-input-hidden::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .form-input-hidden.error {
    border-color: #f96c5f; }
  .form-input-hidden:disabled, .form-input-hidden[readonly] {
    opacity: 0.8;
    font-style: italic;
    cursor: not-allowed;
    background: #f2f2f2; }

.preview-input {
  padding: 12px 16px;
  color: #676767;
  -webkit-border-radius: 2px 2px 0 0;
     -moz-border-radius: 2px 2px 0 0;
          border-radius: 2px 2px 0 0;
  border: none;
  border-bottom: 1px solid #D1D1D1;
  font-size: 1rem;
  background-color: white;
  border-bottom-style: dotted;
  width: 100%;
  opacity: 0.8;
  background: #f2f2f2; }
  .preview-input:active:not(:disabled):not([readonly]), .preview-input:focus:not(:disabled):not([readonly]) {
    color: #505050;
    border-color: #505050;
    outline: none; }
  .preview-input::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .preview-input::-moz-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .preview-input::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .preview-input::placeholder {
    color: rgba(32, 32, 32, 0.3); }
  .preview-input.error {
    border-color: #f96c5f; }
  .preview-input:disabled, .preview-input[readonly] {
    opacity: 0.8;
    font-style: italic;
    cursor: not-allowed;
    background: #f2f2f2; }
  .preview-input:disabled {
    cursor: default; }
  .preview-input::-webkit-input-placeholder {
    color: #676767; }
  .preview-input::-moz-placeholder {
    color: #676767; }
  .preview-input::-ms-input-placeholder {
    color: #676767; }
  .preview-input::placeholder {
    color: #676767; }

ul, li {
  list-style: none;
  padding: 0; }

.post-form-submission-page {
  height: 100vh;
  background-color: #f9fafc;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  position: relative; }
  .post-form-submission-page .text-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: auto;
    height: 151px;
    font-size: 16px; }
    .post-form-submission-page .text-group h1 {
      font-size: 28px;
      font-weight: 600;
      color: #878787; }
    .post-form-submission-page .text-group p {
      color: #676767; }
    .post-form-submission-page .text-group a {
      color: #f96c5f;
      text-decoration: underline; }

.survey-banner {
  width: 100%;
  height: 250px;
  position: absolute;
  top: auto;
  left: 0px;
  margin-top: -48px;
  z-index: -1;
  -moz-background-size: cover;
    -o-background-size: cover;
       background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.survey-panel {
  background: white;
  width: -webkit-calc(100% - 64px);
  width: -moz-calc(100% - 64px);
  width: calc(100% - 64px);
  max-width: -webkit-calc(min(996px, 80vw));
  max-width: -moz-calc(min(996px, 80vw));
  max-width: calc(min(996px, 80vw));
  padding: 48px;
  margin: 48px auto;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  -webkit-box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.05); }

.survey-form-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 500px;
  margin: auto; }
  .survey-form-section > div:first-child:not(.survey-form-header) .md-question {
    margin-top: 0px; }
  .survey-form-section .survey-form-header + div .md-question {
    margin-top: 0px; }

.survey-title-header {
  margin-bottom: 24px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e8eded; }

.survey-form-logo {
  height: 60px;
  max-width: 230px;
  background-position: left;
  background-repeat: no-repeat;
  -moz-background-size: contain;
    -o-background-size: contain;
       background-size: contain;
  margin-bottom: 24px; }

#title {
  font-size: 28px;
  font-weight: 600;
  color: #505050;
  margin: 0px; }

#subtitle {
  font-size: 20px;
  font-weight: normal;
  color: #676767;
  margin: 8px 0px 8px 0px; }

#required-detail {
  margin-top: 10px; }

#required-detail span {
  font-size: 13px;
  color: #f86c5f;
  position: absolute;
  right: 0px; }

.required-asterisk {
  color: #f86c5f; }

.survey-form-header {
  margin-bottom: 56px;
  white-space: pre-wrap; }

.survey-form-footer {
  margin-top: 48px;
  white-space: pre-wrap; }

#details-form {
  margin-top: 36px; }
  #details-form textarea, #details-form input, #details-form .bordered-dropdown {
    padding: 12px 16px;
    color: #676767;
    -webkit-border-radius: 2px 2px 0 0;
       -moz-border-radius: 2px 2px 0 0;
            border-radius: 2px 2px 0 0;
    border: none;
    border-bottom: 1px solid #D1D1D1;
    font-size: 1rem;
    background-color: #F9FAFC; }
    #details-form textarea:active:not(:disabled):not([readonly]), #details-form textarea:focus:not(:disabled):not([readonly]), #details-form input:active:not(:disabled):not([readonly]), #details-form input:focus:not(:disabled):not([readonly]), #details-form .bordered-dropdown:active:not(:disabled):not([readonly]), #details-form .bordered-dropdown:focus:not(:disabled):not([readonly]) {
      color: #505050;
      border-color: #505050;
      outline: none; }
    #details-form textarea::-webkit-input-placeholder, #details-form input::-webkit-input-placeholder, #details-form .bordered-dropdown::-webkit-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    #details-form textarea::-moz-placeholder, #details-form input::-moz-placeholder, #details-form .bordered-dropdown::-moz-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    #details-form textarea::-ms-input-placeholder, #details-form input::-ms-input-placeholder, #details-form .bordered-dropdown::-ms-input-placeholder {
      color: rgba(32, 32, 32, 0.3); }
    #details-form textarea::placeholder, #details-form input::placeholder, #details-form .bordered-dropdown::placeholder {
      color: rgba(32, 32, 32, 0.3); }
    #details-form textarea.error, #details-form input.error, #details-form .bordered-dropdown.error {
      border-color: #f96c5f; }
    #details-form textarea:disabled, #details-form textarea[readonly], #details-form input:disabled, #details-form input[readonly], #details-form .bordered-dropdown:disabled, #details-form .bordered-dropdown[readonly] {
      opacity: 0.8;
      font-style: italic;
      cursor: not-allowed;
      background: #f2f2f2; }
  #details-form textarea {
    resize: vertical; }
  #details-form input:not([type="checkbox"]), #details-form textarea {
    width: 100%; }
  #details-form .dropdownButton, #details-form .match-button.icon-text-button {
    letter-spacing: normal;
    font-size: 1rem;
    color: #676767; }

.md-question {
  margin-top: 56px;
  margin-bottom: 12px;
  line-height: 24px;
  font-size: 16px; }
  .md-question p {
    margin: 2px 0px; }
    .md-question p:last-of-type {
      display: inline; }
  .md-question ol, .md-question ul {
    padding-left: 24px;
    margin-bottom: 0px;
    margin-top: 8px; }
  .md-question ul {
    list-style-type: disc; }
    .md-question ul li {
      list-style-type: disc; }
  .md-question ol {
    list-style-type: decimal; }
    .md-question ol li {
      list-style-type: decimal; }
  .md-question li {
    margin-bottom: 8px; }
  .md-question br {
    display: block;
    margin: 8px 0px;
    content: " "; }
  .md-question.answered {
    color: #676767;
    font-size: 15px;
    line-height: 22px;
    margin-top: 48px; }

.clear-selection {
  margin: 8px 1px; }
  .clear-selection button {
    color: #878787;
    font-size: 12px;
    padding: 8px 4px; }
    .clear-selection button:hover {
      color: #676767; }

.survey-form-body .simple-table-wrapper {
  margin-top: 12px; }

.survey-form-body .radio-ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  border: none !important; }
  .survey-form-body .radio-ul li {
    margin-right: 25px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #878787; }
  .survey-form-body .radio-ul label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .survey-form-body .radio-ul label input {
      margin-right: 8px;
      margin-top: 0px;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1;
         -moz-box-flex: 1;
          -ms-flex: 1 1;
              flex: 1 1;
      min-width: 20px;
      -ms-flex-preferred-size: 28px; }
    .survey-form-body .radio-ul label input[type=radio]:after {
      font-family: 'Material Icons';
      content: '\E836';
      color: #A5A5A5;
      width: 20px;
      height: 20px;
      top: -2px;
      left: -1px;
      position: relative;
      display: inline-block;
      visibility: visible;
      background: white; }
    .survey-form-body .radio-ul label input[type=radio]:checked:after {
      font-family: 'Material Icons';
      content: '\E837';
      color: #505050; }

.survey-form-body ul.radio-ul {
  margin: 12px 0px 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }

.survey-form-body .radio-ul {
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .survey-form-body .radio-ul li {
    margin: 4px 0px; }
  .survey-form-body .radio-ul label {
    font-size: 16px;
    cursor: pointer;
    letter-spacing: normal;
    font-weight: normal;
    color: #505050;
    text-transform: none;
    margin: 4px 0px; }
  .survey-form-body .radio-ul label input[type=radio]:after {
    font-size: 24px !important;
    cursor: pointer;
    color: #878787;
    background: white !important; }
  .survey-form-body .radio-ul label input[type=radio]:checked + span {
    color: #505050; }

.survey-form-body .checkbox input[type=checkbox] + label:before {
  font-size: 20px;
  color: #878787;
  left: -40px; }

.survey-form-body .checkbox input[type=checkbox]:checked + label:before {
  color: #505050; }

.survey-form-body .checkbox input[type=checkbox] + label {
  left: 40px;
  line-height: 24px; }

.survey-form-body .file-container {
  margin-top: 56px; }

.survey-form-body .file-item {
  cursor: default; }
  .survey-form-body .file-item .file-name {
    max-width: 100%; }

.survey-form-body .bordered-dropdown {
  padding: 0px !important; }
  .survey-form-body .bordered-dropdown .dropdownButton, .survey-form-body .bordered-dropdown .match-button.icon-text-button {
    padding: 12px 16px; }

.survey-form-body .dropdown-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  top: auto; }

.survey-form-body .dropdown-options {
  max-height: 250px;
  overflow: auto; }

.survey-form-body .extra-data {
  color: #878787;
  font-style: italic; }

#file-label.disabled .attach-button {
  color: #fb9990;
  cursor: not-allowed; }

.file-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 8px; }

.attach-button {
  text-transform: uppercase;
  cursor: pointer;
  color: #f96c5f;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px; }
  .attach-button.disabled {
    color: #fb9990;
    cursor: not-allowed; }

.remove-file:hover {
  color: #505050;
  cursor: pointer; }

#survey-submit {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  color: white;
  text-transform: uppercase;
  text-align: center;
  width: auto;
  display: block;
  padding: 12px 24px;
  background-color: #f96c5f;
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px;
  border: none !important;
  cursor: pointer;
  color: #ffffff !important;
  width: 100px !important;
  margin: 72px auto 48px auto; }
  #survey-submit:hover {
    background-color: #f85142;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    color: white; }
  #survey-submit:hover:enabled {
    background-color: #f85142;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
  #survey-submit:hover:disabled {
    background-color: #fb9990; }
  #survey-submit:disabled {
    background-color: #fb9990;
    cursor: not-allowed; }
  #survey-submit:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
    -moz-box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5);
         box-shadow: 0 0 0 2px rgba(249, 108, 95, 0.5); }

@media (max-width: 1025px) {
  .survey-panel {
    margin: 32px;
    width: -webkit-calc(100% - 64px);
    width: -moz-calc(100% - 64px);
    width: calc(100% - 64px);
    max-width: none; }
  .survey-form-section {
    width: 100%;
    min-width: auto; } }

* {
  outline: none !important;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box; }

html {
  min-height: 100vh; }
  html.ie-ten {
    width: 100%;
    height: 100%;
    overflow: hidden; }
    html.ie-ten body {
      overflow: auto;
      height: 100vh; }

div[role=button] {
  cursor: pointer; }

svg.triangle-shadow {
  position: absolute;
  top: 0px;
  z-index: -1;
  width: 20px;
  height: 20px; }

.container {
  padding: 16px;
  max-width: 950px;
  margin: auto;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center; }

.panel, .siteContent .loginPanel {
  margin: 48px auto;
  background-color: #fff;
  padding: 64px 80px !important;
  -webkit-box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
          border-radius: 2px; }

.error {
  border-color: #f96c5f !important; }

.error-text, .formPage form .errorlist {
  color: #f96c5f;
  padding-top: 3px;
  font-size: 14px;
  margin-left: 2px; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505050;
  background-color: #F9FAFC;
  position: relative;
  width: 100%; }

.plain-link {
  font-weight: 600;
  border-bottom: 1px solid; }
  .plain-link:hover {
    color: #f96c5f; }

.orange-link {
  color: #f96c5f; }
  .orange-link:hover {
    color: #f85142;
    text-decoration: underline; }

input, textarea {
  font-family: 'Poppins';
  color: #505050; }

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0; }

button {
  font-family: 'Poppins'; }

fieldset {
  border: none;
  padding: 0px;
  margin: 0px; }

.uppercase, header .text-button {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px; }

.text-button {
  font-family: 'Poppins';
  background: none;
  border: none;
  outline: none !important;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  text-align: left;
  padding: 0;
  -webkit-transition: 0s;
  -o-transition: 0s;
  -moz-transition: 0s;
  transition: 0s;
  letter-spacing: 0.5px;
  text-decoration: none; }
  .text-button.uppercase, header .text-button {
    text-transform: uppercase;
    font-weight: 600; }
  .text-button.underline {
    padding-bottom: 4px;
    border-bottom: 3px solid rgba(0, 0, 0, 0); }
  .text-button.selected {
    border-bottom: solid 3px #f96c5f;
    color: #202020; }
  .text-button.active {
    border-bottom: solid 3px #f96c5f;
    color: #202020; }

.back-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.4px;
  padding: 12px 16px;
  font-size: 0.8rem;
  color: #878787; }
  .back-button i {
    margin-right: 8px; }

.underline:focus {
  border-bottom: solid 1px currentColor; }

.app-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.app-body {
  min-height: -webkit-calc(100vh - 78px - 40px);
  min-height: -moz-calc(100vh - 78px - 40px);
  min-height: calc(100vh - 78px - 40px);
  margin-top: 78px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.load-screen {
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }

.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.alert {
  text-align: center;
  margin-bottom: 30px;
  font-size: 18px;
  color: #f96c5f; }

.site {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.monitoring-body-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%; }

.monitoring-sidebar-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: -webkit-calc(100% - 280px);
  width: -moz-calc(100% - 280px);
  width: calc(100% - 280px);
  margin-left: 280px;
  margin-top: 6px;
  min-height: -webkit-calc(100vh - 78px);
  min-height: -moz-calc(100vh - 78px);
  min-height: calc(100vh - 78px); }

.sidebar-page-container {
  min-height: -webkit-calc(100vh - 78px - 40px);
  min-height: -moz-calc(100vh - 78px - 40px);
  min-height: calc(100vh - 78px - 40px); }

.sidebar-page-body {
  margin-left: 250px;
  min-height: -webkit-calc(100vh - 78px);
  min-height: -moz-calc(100vh - 78px);
  min-height: calc(100vh - 78px); }

.default {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  font-size: 20px;
  color: #878787;
  height: 100px;
  min-height: -webkit-calc(100vh - 78px - 40px);
  min-height: -moz-calc(100vh - 78px - 40px);
  min-height: calc(100vh - 78px - 40px); }
  .default .material-icons {
    vertical-align: middle;
    padding-bottom: 4px;
    padding-right: 4px;
    margin-left: -6px; }
  .default .text-button {
    color: #f96c5f;
    border-bottom: 1.5px solid #f96c5f;
    margin-top: 15px;
    font-size: 16px; }
    .default .text-button:hover {
      color: #f85142;
      border-bottom: 1.5px solid #f85142; }

.flex-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.flex-shrink-0 {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0; }

.badge {
  background-color: #f96c5f;
  display: inline-block;
  font-size: 11px;
  -webkit-border-radius: 20px;
     -moz-border-radius: 20px;
          border-radius: 20px;
  padding: 3px 7px 4px;
  color: white;
  margin-left: 8px;
  font-weight: 600; }

.icon-text-button {
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #676767;
  border: none;
  padding: 12px 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: none;
  cursor: pointer; }
  .icon-text-button:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .icon-text-button .material-icons {
    color: #D1D1D1;
    color: #D1D1D1;
    vertical-align: middle;
    margin-right: 4px;
    font-size: 18px; }
  .icon-text-button:hover:not(:disabled), .icon-text-button:focus, .icon-text-button a:hover:not(:disabled), .icon-text-button a:focus {
    color: #202020; }
    .icon-text-button:hover:not(:disabled) .material-icons, .icon-text-button:focus .material-icons, .icon-text-button a:hover:not(:disabled) .material-icons, .icon-text-button a:focus .material-icons {
      color: #878787; }

footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.foot {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.5;
  color: #878787;
  padding: 12px 32px; }
  .foot a {
    color: #878787;
    text-transform: uppercase; }
  .foot a:hover, .foot a:focus {
    color: #202020; }
  .foot .pipe {
    padding: 4px; }

.transition-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }
  .transition-container .transition-item {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    transition: opacity 0.5s; }
    .transition-container .transition-item.fading {
      opacity: 0; }
    .transition-container .transition-item:not(.active) {
      opacity: 0;
      z-index: -1; }

.nav-list > * {
  margin-right: 30px; }

.\_pendo-guide\_ {
  z-index: 100 !important; }

.\_pendo-backdrop\_ {
  z-index: 100 !important; }

iframe {
  z-index: 1 !important; }

.Toastify__toast-container--top-center {
  margin-left: 0px;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%);
  width: auto; }

.connection-error {
  background-color: #FEE0DD;
  color: #6B6868;
  font-weight: 600;
  padding: 16px 24px;
  width: 442px;
  min-height: 1px; }
  .connection-error .material-icons {
    color: #BAAAA9; }

#hubspot-messages-iframe-container {
  z-index: 90 !important; }
